import uuid from 'uuid';
import { SET_ALERT, REMOVE_ALERT } from './types';

export const setAlert = ({
	msg,
	alertType,
	postUrl,
	timeout = 4000,
}) => dispatch => {
	const id = uuid.v4();
	dispatch({
		type: SET_ALERT,
		payload: { msg, alertType, postUrl, id },
	});

	setTimeout(() => {
		// console.log('dispatch remove');
		dispatch({ type: REMOVE_ALERT, payload: id });
	}, timeout);
	const currAlert = document.getElementById(id);
	if (currAlert) {
		setTimeout(() => {
			// console.log('removing fade-in');
			currAlert.classList.remove('fade-in');
		}, 300);

		setTimeout(() => {
			// console.log('adding fade-out');
			currAlert.classList.add('fade-out');
		}, timeout - 180);
	}
};
