import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../../layout/Spinner';
import { parsePicsUser } from '../../common/_utilities';
import {
	deactivateNotifications,
	getNotifications,
	setLoadMoreNotifications,
} from '../../../actions/notifications';
import Moment from 'react-moment';
import useDetectBottom from '../hooks/useDetectBottom';

const Notifications = ({
	// auth: { user, loading: authLoading },
	notifications,
	deactivateNotifications,
	getNotifications,
	history,
}) => {
	const { notifications: theNotifications, loading, loadMore } = notifications;

	useDetectBottom({
		thingLoading: notifications,
		getAction: getNotifications,
	});

	useEffect(() => {
		return () => {
			deactivateNotifications();
		};
	}, []);

	const LiNotification = ({
		notification: {
			child: { text = '', user, _id: child_id },
			childModel,
			date,
		},
	}) => (
		<li
			className='li-notification'
			onClick={() =>
				// works with highlighting to child's parent, but I think it's better just to go straight to notification post
				// history.push(
				//   parent
				//     ? `/posts/${parentModel}/${parent._id}/${child_id}`
				//     : `/posts/${childModel}/${child_id}`
				// )
				childModel !== 'ActiveAcct'
					? history.push(
							`/posts/${childModel === 'BasePost' ? 'Post' : childModel}/${child_id}`
					  )
					: // TODO: set to id intead of active-accounts:
					  // : history.push(`/profile/${user.username}/active-accounts`)
					  history.push(`/profile/${user.username}/${child_id}`)
			}>
			<div
				className='notif-img-cont'
				onClick={e =>
					!e.stopPropagation() && history.push(`/profile/${user.username}`)
				}>
				<img src={parsePicsUser(user, 'thumb')} alt='' className='br-50 ' />
			</div>
			<div>
				<b
					className='text-link'
					onClick={e =>
						!e.stopPropagation() && history.push(`/profile/${user.username}`)
					}>
					{user.name}:
				</b>{' '}
				{text.substring(0, 70)}
				{text.length > 70 && '...'}
				<small className='form-text'>
					<Moment fromNow>{date}</Moment>
				</small>
			</div>
		</li>
	);

	// return authLoading || user === null ? (
	return loading ? (
		<Spinner />
	) : (
		<div id='notifications-cont'>
			<ul>
				{theNotifications.length ? (
					theNotifications.map(n => <LiNotification notification={n} />)
				) : (
					<small className='form-text empty'>No notifications yet</small>
				)}
				{loadMore && (
					<div className='posts-spinner'>
						<Spinner />
					</div>
				)}
				{/* {noMore && <small className='form-text end'> The end </small>} */}
			</ul>
		</div>
	);
};

Notifications.propTypes = {
	auth: PropTypes.object.isRequired,
	notifications: PropTypes.object.isRequired,
	deactivateNotifications: PropTypes.func.isRequired,
	getNotifications: PropTypes.func.isRequired,
	setLoadMoreNotifications: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	auth: state.auth,
	posts: state.posts,
	notifications: state.notifications,
});

export default connect(mapStateToProps, {
	deactivateNotifications,
	getNotifications,
	setLoadMoreNotifications,
})(withRouter(Notifications));
