import React from 'react';

const PrependAlert = ({ msg = '***', clear }) => {
	return (
		<div
			id='prepend-alert'
			className='text-link text-center-x'
			onClick={() => {
				window.scrollTo({
					top: 0,
					left: 0,
					behavior: 'smooth',
				});
			}}>
			<span>{msg}</span>
			<span onClick={e => !e.stopPropagation() && clear()}>
				<i className='fas fa-times' />
			</span>
		</div>
	);
};

export default PrependAlert;
