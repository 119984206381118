import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import { verifyUser, resendVerification } from '../../actions/auth';
import PropTypes from 'prop-types';
import { labelTransNotEmpty } from '../profile-forms/_profileFormUtilities';
import Spinner from '../layout/Spinner';
import { EMAIL_REGEX } from '../common/_constants';

const VerifyUser = ({
	auth: { isAuthenticated },
	match,
	verifyUser,
	resendVerification,
	history,
}) => {
	const [validForm, setValidForm] = useState(false);

	const [formData, setFormData] = useState({
		email: '',
	});
	const { email } = formData;

	const [invalidToken, setInvalidToken] = useState(null);
	useEffect(() => {
		!invalidToken &&
			!isAuthenticated &&
			(async () => {
				// const { token } = { ...match.params };
				const { token } = match.params;
				console.log('OUTPUT: token', token);
				const { invalidToken } = await verifyUser(token, history);
				console.log('LINE2: 34  invalidToken ', invalidToken);
				setInvalidToken(invalidToken);
			})();
	}, []);

	const onChange = e =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = async e => {
		e.preventDefault();
		console.log('LINE2: 39  formData', formData);
		await resendVerification(formData, history);
	};

	if (isAuthenticated) {
		return <Redirect to='/home' />;
	}

	return !invalidToken ? (
		<Spinner />
	) : (
		<div className='main-component guest-main-component'>
			<h1 className='large text-primary'>Resend Email Verification</h1>
			{/* <p className='lead mx-1'> */}
			{/* 	<i className='fas fa-lock-open' /> Recovery */}
			{/* </p> */}

			<form
				className='form'
				onSubmit={e => onSubmit(e)}
				onKeyUp={e =>
					setValidForm(
						![...e.currentTarget.querySelectorAll('input')].filter(f =>
							f.type === 'email'
								? !EMAIL_REGEX.test(f.value)
								: !f.checkValidity()
						).length
					)
				}>
				<div className='form-group'>
					<div className='use-trans-label'>
						<input
							type='email'
							name='email'
							value={email}
							onChange={e => onChange(e)}
							onBlur={labelTransNotEmpty}
							maxLength='254'
							required
						/>
						<label htmlFor='email'>Email Address</label>
					</div>
				</div>
				<input
					type='submit'
					id='resend-verification'
					style={{ display: 'none' }}
				/>
				<button
					htmlFor='resend-verification'
					className={`btn m-1 btn-big btn-${validForm ? 'primary' : 'white'}`}>
					<span>Resend verification</span>
				</button>
			</form>
		</div>
	);
};

VerifyUser.propTypes = {
	setAlert: PropTypes.func.isRequired,
	verifyUser: PropTypes.func.isRequired,
	resendVerification: PropTypes.func.isRequired,
	isAuthenticated: PropTypes.bool,
};

const mapStateToProps = ({ auth }) => ({
	auth,
});

export default connect(mapStateToProps, {
	setAlert,
	verifyUser,
	resendVerification,
})(withRouter(VerifyUser));
