import React, { useEffect, useLayoutEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import Spinner from '../layout/Spinner';
import PostItem from '../post/PostItem';
import { PostImg, ImgModal } from '../post/PostImg';
import {
	hashtagify,
	ShowThread,
	queryStringToObject,
	hasExistence,
} from '../common/_utilities';
import UserItem from '../users/UserItem';
import { LOAD_LIMIT } from '../common/_shared_constants';
import useDetectBottom from '../common/hooks/useDetectBottom';
import {
	upvotePost,
	downvotePost,
	deletePost,
	recyclePost,
	unrecyclePost,
} from '../../actions/posts';

const Posts = ({
	posts,
	history,
	getPostsAction,
	upvotePost,
	downvotePost,
	deletePost,
	recyclePost,
	unrecyclePost,
	match: {
		params: { hashtag },
	},
	emptyMessage = 'No posts.',
	setScrollY = false,
	setLoadMore,
}) => {
	const { search } = useLocation();
	const { posts: thePosts, loading, scrollY, loadMore } = posts;

	useEffect(() => {
		!loading &&
			window.scrollTo({
				top: setScrollY ? scrollY : 0,
				left: 0,
			});
	}, [loading]);

	useDetectBottom({
		thingLoading: posts,
		setScrollY,
		setLoadMore,
		getAction: (prevQuery => query => getPostsAction({ ...prevQuery, ...query }))(
			{
				...queryStringToObject(search),
				...(hashtag ? { hashtags: hashtag } : {}),
			}
		),
	});

	const [modalData, setModalData] = useState({ _id: '', src: {}, text: '' });
	const postImg = post => <PostImg {...{ post, setModalData }} />;

	return loading ? (
		<Spinner />
	) : (
		<Fragment>
			{modalData._id && <ImgModal {...{ modalData, setModalData }} />}

			<div id='posts-cont'>
				{thePosts.length ? (
					thePosts.map((post, i) => {
						return post.username ? (
							<UserItem key={post._id} user={post} index={i} />
						) : (
							<PostItem
								i={i}
								key={post._id}
								threadItem={false}
								post={
									hasExistence(post, 'recycle')
										? {
												...post.recycle,
												isThread: false,

												recycledBy: post.user.username,
										  }
										: {
												...post,
												isThread: false,
												text: hashtagify(post.text, history),
										  }
								}
								ShowThread={
									!post.depth &&
									post.threadDescendant &&
									(() => ShowThread(post, history))
								}
								postImg={postImg(hasExistence(post, 'recycle') ? post.recycle : post)}
								postActionCreators={{
									upvote: upvotePost,
									downvote: downvotePost,
									recycle: recyclePost,
									unrecycle: unrecyclePost,
									delete: deletePost,
								}}
							/>
						);
					})
				) : (
					<small className='form-text empty'>{emptyMessage}</small>
				)}
			</div>
			{loadMore && (
				<div className='posts-spinner'>
					<Spinner />
				</div>
			)}
			{/* {noMore && <small className='form-text end'> The end </small>} */}
		</Fragment>
	);
};

Posts.propTypes = {
	post: PropTypes.object.isRequired,
	common: PropTypes.object.isRequired,
	upvotePost: PropTypes.func.isRequired,
	downvotePost: PropTypes.func.isRequired,
	deletePost: PropTypes.func.isRequired,
	recyclePost: PropTypes.func.isRequired,
	unrecyclePost: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	auth: state.auth,
	common: state.common,
});

export default connect(mapStateToProps, {
	upvotePost,
	downvotePost,
	deletePost,
	recyclePost,
	unrecyclePost,
})(withRouter(Posts));
