import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { _handleReminder, _rmReminder } from '../common/_reminder';
import { deleteAcct } from '../../actions/auth';
import CheckboxGroup from '../common/CheckboxGroup';

const DashDeleteAccount = ({ deleteAcct }) => {
	const history = useHistory();

	const [formData, setFormData] = useState(
		[
			{ value: 'Censorship Events', postType: 'CensorEvent' },
			{ value: 'Posts', postType: 'Post' },
			{ value: 'Comments', postType: 'Comment' },
		].map(o => {
			o.checked = false;
			return o;
		})
	);

	const onChange = ({ target: { value, checked } }) => {
		const indexOldElement = formData.findIndex(
			({ value: pKVal }) => pKVal === value
		);
		const postsToKeep = [
			...formData.slice(0, indexOldElement),
			{ ...formData[indexOldElement], checked: checked },
			...formData.slice(indexOldElement + 1),
		];

		console.log('LINE2: 38  postsToKeep ', postsToKeep);
		setFormData(postsToKeep);
	};

	return (
		<form
			key='edit-email-form'
			className='form'
			onSubmit={async e => {
				e.preventDefault();
				deleteAcct(formData, history);
			}}>
			<div className='form-group '>
				<small className='form-text m-0-5'>
					Check to keep the content of your posts with the personal info
					redacted.
				</small>
				<CheckboxGroup
					name='postsToKeep'
					onChange={onChange}
					options={formData}
				/>
			</div>

			<input
				type='submit'
				id='submit-edit-email-1'
				style={{ display: 'none' }}
			/>

			<button
				htmlFor='submit-edit-email-1'
				className={`submit-btn btn m-1 btn-danger`}>
				<span>Permanently Delete Account</span>
			</button>
		</form>
	);
};

DashDeleteAccount.prototypes = {
	deleteAcct: PropTypes.func.isRequired,
};

export default connect(null, { deleteAcct })(DashDeleteAccount);
