const _handleReminder = ({ e, _class, msg }) => {
	//console.log('LINE2: 2  msg ', msg);
	//console.log('LINE2: 2  msg ', msg.length);
	if (msg.length) {
		const r = document.createElement('div');
		r.setAttribute('id', 'reminder');
		r.classList.add('reminder');
		// may add this back
		// r.classList.add('hide-sm'); // 440px
		_class && r.classList.add(_class);
		r.appendChild(document.createTextNode(msg));

		e.currentTarget.style.position = 'relative'; // should this be removed in _rmReminder?
		e.currentTarget.appendChild(r);
	}
};

const _rmReminder = e => {
	const remToRm = document.getElementById('reminder');
	if (remToRm) {
		remToRm.parentNode.removeChild(remToRm);
	}
};

export { _handleReminder, _rmReminder };
