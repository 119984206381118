import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { dropdownHandler } from './_utilities';
import { _handleReminder, _rmReminder } from './_reminder.js';

const DropdownOptions = ({ columns }) => {
	return (
		<div id='multi-filter'>
			<div
				className='drop-parent text-block text-center-x'
				style={{
					padding: '0.2rem 0.5rem',
					margin: '0 0.5rem',
				}}
				onClick={e => dropdownHandler(e, true)}>
				<span className='lead'>
					<i className='fas fa-sort-amount-down' />
					Filter
				</span>

				<div id='filter-options-cont'>
					{columns.map(filter => {
						if (!filter) return;
						const { options, label, selectionVal, onClick } = filter;
						return (
							<dl>
								<dt>{label}</dt>
								{options.map(({ method, value }) => (
									<dd
										className='drop-option'
										onMouseEnter={e =>
											_handleReminder({
												e,
												_class: 'mx-2',
												msg: `Search by ${method.toLocaleLowerCase()}`,
											})
										}
										onMouseLeave={_rmReminder}
										onClick={e => onClick({ e, value })}>
										{value === selectionVal ? (
											<b className='drop-selection'>{method}</b>
										) : (
											method
										)}
									</dd>
								))}
							</dl>
						);
					})}
				</div>
			</div>
		</div>
	);
};

DropdownOptions.propTypes = {
	options: PropTypes.array.isRequired,
	label: PropTypes.string,
	selectionVal: PropTypes.string,
	onClick: PropTypes.func.isRequired,
};

export default DropdownOptions;
