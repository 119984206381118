import React, { Fragment as Frag, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { _handleReminder, _rmReminder } from '../common/_reminder.js';
import abbreviate from 'number-abbreviate';

const MainInfo = ({ auth, post: { loading, post }, history }) => {
	const {
		id,
		user,
		votes,
		date,
		recyclers,
		v_quoted_count,
		v_recyclers_count,
	} = post;

	// remove if country is required
	const { country } = user.location || { country: 'undisclosed' };

	const { upvotes, downvotes } = votes;

	// for development
	// useEffect(() => {
	// 	setTimeout(() => {
	// 		let dev = document.getElementById('itemized-info');
	// 		if (dev) dev.scrollIntoView();
	// 	}, 500);
	// }, [loading]);

	return (
		<div className='form-text p-1 m-1' id='main-info'>
			<div id='submission-info'>
				<small
					onMouseEnter={e =>
						_handleReminder({ e, _class: 'myt-2', msg: 'Date submitted' })
					}
					onMouseLeave={_rmReminder}>
					<Moment format='h:mm a · MMM Do, YYYY ' {...{ date }} />
				</small>
				<b
					onMouseEnter={e =>
						_handleReminder({ e, _class: 'myt-2', msg: 'Location' })
					}
					onMouseLeave={_rmReminder}>
					· {country}{' '}
				</b>
			</div>

			<div id='itemized-info'>
				{!!upvotes.length && (
					<span>
						<b>{!!upvotes.length && abbreviate(upvotes.length)}</b>
						<span> Upvotes</span>
					</span>
				)}

				{!!upvotes.length &&
					(!!downvotes.length || !!recyclers.length || !!v_quoted_count) && (
						<span className='mx-1'>·</span>
					)}

				{!!downvotes.length && (
					<span>
						<b>{!!downvotes.length && abbreviate(downvotes.length)}</b>
						<span> Downvotes </span>
					</span>
				)}

				{!!downvotes.length && (!!recyclers.length || !!v_quoted_count) && (
					<span className='mx-1'>·</span>
				)}

				{!!recyclers.length && (
					<span>
						<b>{!!recyclers.length && abbreviate(recyclers.length)}</b>
						<span> Recyclers </span>
					</span>
				)}

				{!!recyclers.length && !!v_quoted_count && (
					<span className='mx-1'>·</span>
				)}

				{!!v_quoted_count && (
					<span
						className='text-link'
						onClick={() => history.push(`/quote-posts/${id}`)}>
						<b>{abbreviate(v_quoted_count)}</b>
						<span> Quoted </span>
					</span>
				)}

				{/* 				{!!v_quoted_count && !!v_recyclers_count && ( */}
				{/* 					<span className='mx-1'>·</span> */}
				{/* 				)} */}

				{/* 				{!!v_recyclers_count && ( */}
				{/* 					<span */}
				{/* 						className='text-link' */}
				{/* 						onClick={() => history.push(`/quote-posts/${id}`)}> */}
				{/* 						<b>{abbreviate(v_recyclers_count)}</b> */}
				{/* 						<span> Quoted </span> */}
				{/* 					</span> */}
				{/* 				)} */}
			</div>
		</div>
	);
};

MainInfo.prototypes = {
	post: PropTypes.object.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = ({ auth, post }) => ({
	auth,
	post,
});

export default connect(mapStateToProps, {})(withRouter(MainInfo));
