import React from 'react';
// import spinner from './spinner.gif';

export default () => (
	<div className='spinner'>
		<span>
			<i className='fas fa-meh-blank ' />
		</span>
	</div>
	// <Fragment>
	//   <img
	//     src={spinner}
	//     style={{ width: '200px', margin: 'auto', display: 'block' }}
	//     alt='Loading...'
	//   />
	// </Fragment>
);
