import React, { Fragment, useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NavLinks from './NavLinks';
import { logout } from '../../actions/auth';
import {
	dropdownHandler,
	toggleClass,
} from '../../components/common/_utilities';
import {
	getNotifications,
	pollNotifications,
} from '../../actions/notifications';
import { io } from 'socket.io-client';
import { CNSR_VERSION } from '../common/_constants';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { setCommonData } from '../../actions/common';

const Navbar = ({
	auth: { user, loading },
	logout,
	notifications: { notifications, loading: notification_loading },
	getNotifications,
	pollNotifications,
	history,
}) => {
	const [navScrollStyle, setNavScrollStyle] = useState('');
	useScrollPosition(
		({ prevPos, currPos }) => {
			const scrollDown = currPos.y < prevPos.y;
			setNavScrollStyle(scrollDown ? 'auth-scroll-down' : '');
		},
		[navScrollStyle],
		false,
		false,
		300
	);

	const [darkMode, setDarkMode] = useState(
		localStorage.getItem('theme') != 'light'
	);
	const [socketData, setSocketData] = useState(false);

	useEffect(() => {
		localStorage.setItem('theme', darkMode ? 'dark' : 'light');

		const root = document.getElementById('root');

		if (darkMode) root.classList.add('dark-mode');
		else root.classList.remove('dark-mode');
	}, [darkMode]);

	useEffect(() => {
		getNotifications({ skipIndex: 0 });
	}, []);

	useEffect(() => {
		socketData &&
			pollNotifications(
				{
					firstIndexDate: notifications[0] ? notifications[0].date : 0,
				},
				socketData
			);
		setSocketData(false);
	}, [socketData]);

	useEffect(() => {
		// bug was here
		// OUTPUT: error TypeError: Cannot read property 'id' of null
		// adding user check, but needs testing
		// if (user && !notification_loading) {
		// const socket = io({ transports: ['websocket'] });

		const socket = io();
		const privateRoom = localStorage.getItem('privateRoom');
		// console.log('LINE2: 68  privateRoom ', privateRoom);

		socket.on('connect', () => {
			// console.log('LINE2: 67 _connect ', 'connect');
			// console.log('LINE2: 65  socket.id', socket.id);
			// console.log('LINE2: 68  socket', socket);
		});

		socket.on(privateRoom, function (data) {
			// console.log('LINE2: 72 _data ', data);
			setSocketData(data);
		});

		return () => {
			// TODO: add disconnect socket here if possible
			// removeSocketId(localSocketId);
		};
		// }, [notification_loading, user]);
	}, []);

	// }
	return (
		<div id='auth-nav-cont' className={`side-container ${navScrollStyle}`}>
			<nav>
				<div>
					<div id='brand-cont'>
						<Link to='/' className>
							<i className='far fa-meh-blank' />
							<span id='brand-name' className='hide-lg'>
								CNSRSHP{' '}
								<span
									style={{ fontSize: '.6rem' }}
									className='light-color hide-md'>
									{CNSR_VERSION}{' '}
								</span>
							</span>
						</Link>
					</div>
					{!loading && (
						<Fragment>
							<NavLinks />
							<div id='nav-settings-cont'>
								<div
									className='drop-parent'
									style={{
										padding: '0.2rem 0.5rem',
										margin: '0 0.5rem',
									}}
									onClick={e => {
										e.stopPropagation();
										const parent = e.currentTarget;
										const cog = parent.querySelector('.fa-cog');
										toggleClass(cog, 'twist');
										toggleClass(cog, 'untwist');

										dropdownHandler(e);
									}}>
									{/* <i className='fas fa-chevron-right' /> */}
									<i className='fas fa-cog untwist' />
									<ul id='nav-settings' className='selection'>
										<li
											className='drop-option toggle-switch-cont'
											onClick={e => {
												e.stopPropagation();
												setDarkMode(!darkMode);
											}}>
											<span>
												<span>{darkMode ? 'Light' : 'Dark'}</span>
												<span className='switch'>
													<input type='checkbox' checked={darkMode} />
													<span className='slider round' />
												</span>
											</span>
										</li>

										<li
											className='drop-option'
											onClick={e => {
												e.preventDefault();

												if (
													window.confirm('Are you sure you want to logout?')
												) {
													// history.push('/login');
													history.push('/');
													logout();
												}
											}}>
											<span>
												<span>Logout</span>
												<i className='fas fa-sign-out-alt' />
											</span>
										</li>
									</ul>
								</div>
							</div>
						</Fragment>
					)}
				</div>
			</nav>
		</div>
	);
	// ));
};

Navbar.propTypes = {
	auth: PropTypes.object.isRequired,
	logout: PropTypes.func.isRequired,
	getNotifications: PropTypes.func.isRequired,
	pollNotifications: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	auth: state.auth,
	notifications: state.notifications,
});

export default connect(mapStateToProps, {
	logout,
	getNotifications,
	pollNotifications,
	setCommonData,
})(withRouter(Navbar));
