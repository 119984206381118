import axios from 'axios';
import { setAlert } from './alert';
import {
	GET_POSTS,
	GET_TOP_PLATFORMS,
	GET_PROFILE_POSTS_COUNT,
	ADD_EXPLORE_POST,
	ADD_CENSOR_POST,
	ADD_HOME_POST,
	APPEND_POSTS,
	SET_LOAD_MORE_POSTS,
	REMOVE_POST,
	DELETE_AUTH_CENSOR,
	CLEAR_POSTS,
	POSTS_ERROR,
	UPDATE_POSTS,
	UPDATE_POSTS_USER_FOLLOW,
	UPDATE_MAIN,
	DELETE_MAIN,
} from './types';
import { hasExistence } from '../components/common/_utilities';

export const getQuotePosts = (id, query) => async dispatch => {
	console.log('LINE2: 106  id', id);
	try {
		const res = await axios.put(`/api/posts/quote-posts/${id}`, query);

		dispatch({
			type: GET_POSTS,
			payload: res.data,
		});
	} catch (error) {
		posts_error(error, dispatch);
	}
};

export const appendQuotePosts = (id, query) => async dispatch => {
	console.log('LINE2: 106  id', id);
	try {
		const res = await axios.put(`/api/posts/quote-posts/${id}`, query);

		dispatch({
			type: APPEND_POSTS,
			payload: res.data,
		});
	} catch (error) {
		posts_error(error, dispatch);
	}
};

export const recyclePost = postData => async dispatch => {
	try {
		console.log('LINE2: 158  postData ', postData);
		const res = await axios.post(`/api/posts/recycle-post`, postData);
		console.log('LINE2: 277  res.data', res.data);

		postData.postType === 'CensorEvent' &&
			dispatch({
				type: ADD_CENSOR_POST,
				payload: res.data,
			});

		dispatch({
			type: ADD_EXPLORE_POST,
			payload: res.data,
		});

		// !important
		// home is creating dublicates, I'm not sure if this is from prepend or duplicate reducers
		dispatch({
			type: ADD_HOME_POST,
			payload: res.data,
		});

		dispatch({
			type: UPDATE_POSTS,
			payload: res.data.recycle,
		});

		dispatch({
			type: UPDATE_MAIN,
			payload: res.data.recycle,
		});

		dispatch(
			setAlert({
				msg: 'Recycled',
				alertType: 'success',
			})
		);
	} catch (error) {
		posts_error(error, dispatch);
	}
};

export const unrecyclePost = postData => async dispatch => {
	try {
		console.log('LINE2: 158  postData ', postData);
		const res = await axios.post(`/api/posts/unrecycle-post`, postData);
		console.log('LINE2: 277  res.data', res.data);

		dispatch({
			type: REMOVE_POST,
			payload: res.data.removedRecycle,
		});

		dispatch({
			type: UPDATE_POSTS,
			payload: res.data.unrecycled,
		});

		dispatch({
			type: UPDATE_MAIN,
			payload: res.data.unrecycled,
		});

		dispatch(
			setAlert({
				msg: 'Unrecycled',
				alertType: 'success',
			})
		);
	} catch (error) {
		posts_error(error, dispatch);
	}
};

export const addPost = formDataJSON => async dispatch => {
	try {
		const formData = new FormData();
		for (let key in formDataJSON) {
			formData.append(key, formDataJSON[key]);
		}

		const res = await axios.post(`/api/posts/`, formData);
		console.log('LINE2: 277  res.data', res.data);

		dispatch({
			type: ADD_EXPLORE_POST,
			payload: res.data,
		});

		dispatch({
			type: ADD_HOME_POST,
			payload: res.data,
		});

		// it would be good to add to profile if on own profile too

		const { postType, _id } = res.data;

		dispatch(
			setAlert({
				msg: 'Posted.',
				alertType: 'success',
				postUrl: `/posts/Post/${_id}`,
			})
		);
	} catch (error) {
		posts_error(error, dispatch);
	}
};

export const findByUsername = username => async dispatch => {
	try {
		const res = await axios.put('/api/posts/find-by-username/', username);
		return res.data;
	} catch (error) {
		const errors = error.response.data.errors;
		if (errors) {
			errors.forEach(error =>
				dispatch(setAlert({ msg: error.msg, alertType: 'danger' }))
			);
			return 400;
		}
	}
};

export const upvotePost = ({ _id, postType }) => async dispatch => {
	try {
		const res = await axios.put(`/api/posts/upvotes/${_id}`, {
			postType,
		});
		console.log('actions/posts.js: 236  res ', res);

		dispatch({
			type: UPDATE_POSTS,
			payload: res.data,
		});

		dispatch({
			type: UPDATE_MAIN,
			payload: res.data,
		});
	} catch (error) {
		posts_error(error, dispatch);
	}
};

export const downvotePost = ({ _id, postType }) => async dispatch => {
	try {
		const res = await axios.put(`/api/posts/downvotes/${_id}`, {
			postType,
		});

		dispatch({
			type: UPDATE_POSTS,
			payload: res.data,
		});

		dispatch({
			type: UPDATE_MAIN,
			payload: res.data,
		});
	} catch (error) {
		posts_error(error, dispatch);
	}
};

export const deletePost = ({ _id }) => async dispatch => {
	try {
		const res = await axios.delete(`/api/posts/${_id}`);

		dispatch({
			type: DELETE_AUTH_CENSOR,
			payload: res.data.redactedPost._id,
		});

		dispatch({
			type: UPDATE_POSTS,
			payload: res.data.redactedPost,
		});

		dispatch({
			type: DELETE_MAIN,
			payload: res.data.redactedPost,
		});

		dispatch(setAlert({ msg: res.data.msg, alertType: 'success' }));
	} catch (error) {
		posts_error(error, dispatch);
	}
};

export const getTopPlatforms = filterQuery => async dispatch => {
	try {
		const { hashtags, text } = filterQuery;
		console.log('LINE: 17 filterQuery', filterQuery);

		if (hashtags || text) {
			const res = await axios.put(`/api/posts/top-platforms`, filterQuery);
			console.log('LINE: 18 res ', res);
			dispatch({
				type: GET_TOP_PLATFORMS,
				payload: res.data,
			});
		} else
			dispatch({
				type: GET_TOP_PLATFORMS,
				payload: [],
			});
	} catch (error) {
		posts_error(error, dispatch);
	}
};

export const getSearchPosts = query => async dispatch => {
	console.log('LINE2: 362  query ', query);
	try {
		if (hasExistence(query, 'text')) {
			const res = await axios.put(`/api/posts/search`, query);

			dispatch({
				type: GET_POSTS,
				payload: res.data,
			});
		} else
			dispatch({
				type: CLEAR_POSTS,
			});
	} catch (error) {
		posts_error(error, dispatch);
	}
};

export const appendSearchPosts = query => async dispatch => {
	try {
		const res = await axios.put(`/api/posts/search`, query);

		dispatch({
			type: APPEND_POSTS,
			payload: res.data,
		});
	} catch (error) {
		posts_error(error, dispatch);
	}
};

export const getHashtagPosts = query => async dispatch => {
	try {
		const res = await axios.put('/api/posts/hashtag', query);

		dispatch({
			type: GET_POSTS,
			payload: res.data,
		});
	} catch (error) {
		posts_error(error, dispatch);
	}
};

export const appendHashtagPosts = query => async dispatch => {
	try {
		const res = await axios.put('/api/posts/hashtag', query);

		dispatch({
			type: APPEND_POSTS,
			payload: res.data,
		});
	} catch (error) {
		posts_error(error, dispatch);
	}
};

export const setLoadMorePosts = index => dispatch => {
	dispatch({ type: SET_LOAD_MORE_POSTS, payload: index });
};

export const getProfilePosts = (profile_id, query = {}) => async dispatch => {
	try {
		const res = await axios.put(`/api/posts/profile/${profile_id}`, query);

		const { skipIndex } = query;

		!skipIndex && dispatch(getProfilePostsCount(profile_id));

		dispatch({
			type: GET_POSTS,
			payload: res.data,
		});
	} catch (error) {
		posts_error(error, dispatch);
	}
};

export const getProfilePostsCount = profile_id => async dispatch => {
	try {
		const res = await axios.get(`/api/posts/profile-posts-count/${profile_id}`);
		dispatch({
			type: GET_PROFILE_POSTS_COUNT,
			payload: res.data,
		});
	} catch (error) {
		posts_error(error, dispatch);
	}
};

export const clearProfilePostsCount = () => dispatch => {
	dispatch({
		type: GET_PROFILE_POSTS_COUNT,
		payload: 0,
	});
};

export const clearPosts = () => async dispatch =>
	dispatch({ type: CLEAR_POSTS });

export const clearTopPlatforms = () => dispatch =>
	dispatch({
		type: GET_TOP_PLATFORMS,
		payload: [],
	});

export const getUserSummaryFollowData = _id => async dispatch => {
	try {
		const res = await axios.get(`/api/posts/get-user-summary-follow-data/${_id}`);
		console.log('actions/posts.js: 477  res ', res);
		dispatch({ type: UPDATE_POSTS_USER_FOLLOW, payload: res.data });
	} catch (error) {
		posts_error(error, dispatch);
	}
};

const posts_error = (error, dispatch) => {
	console.log('OUTPUT: posts_error -> error', error);
	const errors = error.response.data.errors;
	dispatch({
		type: POSTS_ERROR,
		payload: {
			msg: error.response.statusText,
			status: error.response.status,
		},
	});
	if (errors) {
		errors.forEach(({ msg }) => dispatch(setAlert({ msg, alertType: 'danger' })));
		return 400;
	}
};
