import React, { useEffect, Fragment } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import Register from '../auth/Register';
import Home from '../home/Home';
import Notifications from '../common/notifications/Notifications';
import Login from '../auth/Login';
import VerifyUser from '../auth/VerifyUser';
import CreateProfile from '../profile-forms/CreateProfile';
import EditProfile from '../profile-forms/EditProfile';
import AddCensorEvent from '../profile-forms/AddCensorEvent';
import AddActive from '../profile-forms/AddActive';
import CensorEvents from '../posts/CensorEvents';
import UsernameProfile from '../profile/UsernameProfile';
import IdProfile from '../profile/IdProfile';
import ExplorePosts from '../posts/ExplorePosts';
import QuotePosts from '../posts/QuotePosts';
import MainPost from '../post/MainPost';
import PrivateRoute from '../routing/PrivateRoute';
import NotFound from '../layout/NotFound';
import MainWrapper from '../layout/MainWrapper';
import DemographicInfo from '../profile-forms/DemographicInfo';
import ResetPassword from '../auth/ResetPassword';
import PostModal from '../post/PostModal';
import Hashtag from '../posts/Hashtag';
import Search from '../posts/Search';
import RightSide from '../layout/RightSide';
import TrendingHashtags from '../layout/TrendingHashtags';
import Navbar from '../layout/Navbar';
import Spinner from '../layout/Spinner';
import GuestNav from '../layout/GuestNav';
import Landing from '../layout/Landing';
import ForgotPassword from '../auth/ForgotPassword';
import Dashboard from '../dashboard/Dashboard';
import { loadUser } from '../../actions/auth';
import About from '../general/About';
import SearchBox from '../common/SearchBox';
// import TestSide from '../layout/TestSide';

const Routes = ({ auth: { isAuthenticated, loading }, loadUser, history }) => {
	useEffect(() => {
		loadUser();
	}, []);

	const routes = [
		{
			path: '/hashtag/:hashtag?/:query?',
			exact: true,
			isPrivate: false,
			get main() {
				return () => <MainWrapper header={this.header} Main={Hashtag} />;
			},
			sidebar: () => <RightSide />,
		},

		{
			path: '/home',
			exact: true,
			isPrivate: true,
			header: (
				<h1 class='text-primary'>
					<i className='fas fa-home' />
					&nbsp; Home
				</h1>
			),
			get main() {
				return () => <MainWrapper header={this.header} Main={Home} />;
			},
			sidebar: () => <RightSide />,
		},

		{
			path: '/dashboard',
			// exact: true,
			isPrivate: true,
			header: <h1 class='text-primary'>Dashboard</h1>,
			get main() {
				return () => (
					<MainWrapper header={this.header} Main={Dashboard} path={this.path} />
				);
			},
			sidebar: () => <RightSide />,
		},

		{
			path: '/censor-events/:query?',
			exact: true,
			isPrivate: false,
			header: (
				<h1 class='text-primary'>
					<i className='fas fa-th-list' /> Censorship Index
				</h1>
			),
			get main() {
				return () => <MainWrapper header={this.header} Main={CensorEvents} />;
			},
			sidebar: () => <RightSide />,
		},

		{
			path: '/explore/:query?',
			exact: true,
			isPrivate: true,
			header: (
				<h1 class='text-primary'>
					<i className='fas fa-globe mx-0-5' />
					Explore
				</h1>
			),
			get main() {
				return () => <MainWrapper header={this.header} Main={ExplorePosts} />;
			},
			sidebar: () => <RightSide />,
		},

		{
			path: '/quote-posts/:id',
			exact: true,
			isPrivate: true,
			header: (
				<h1 class='text-primary'>
					<i className='fas fa-quote-left mx-0-5' />
					Quote Posts
				</h1>
			),
			get main() {
				return () => <MainWrapper header={this.header} Main={QuotePosts} />;
			},
			sidebar: () => <RightSide />,
		},

		{
			path: '/notifications',
			exact: true,
			isPrivate: true,
			header: (
				<h1 class='text-primary'>
					<i className='fas fa-bell' /> Notifications
				</h1>
			),
			get main() {
				return () => <MainWrapper header={this.header} Main={Notifications} />;
			},
			sidebar: () => <RightSide />,
		},

		{
			path: '/profile/:username/:anchor?',
			exact: true,
			isPrivate: false,
			// noPostModal: true, // I do not know why but PostModal does not work on this route..
			header: (
				<h1 class='text-primary'>
					<i className='fas fa-user' /> Profile
				</h1>
			),
			get main() {
				return () => <MainWrapper header={this.header} Main={UsernameProfile} />;
			},
			sidebar: () => <RightSide />,
		},
		{
			path: '/profile-id/:userProfileId/:anchor?',
			exact: true,
			isPrivate: false,
			header: (
				<h1 class='text-primary'>
					<i className='fas fa-user' /> Profile
				</h1>
			),
			get main() {
				return () => <MainWrapper header={this.header} Main={IdProfile} />;
			},
			sidebar: () => <RightSide />,
		},

		{
			path: '/edit-profile',
			exact: true,
			isPrivate: true,
			noPostModal: true,
			header: (
				<h1 class='text-primary'>
					<i className='fas fa-user' />
					&nbsp; Edit Profile
					{/* {hasExistence(user, 'profile') ? 'Edit' : 'Create'} your profile */}
					{/* // double check that this works properly ^ */}
				</h1>
			),
			get main() {
				return () => <MainWrapper header={this.header} Main={EditProfile} />;
			},
			sidebar: () => <div id='right-side' className='side-container'></div>,
		},

		{
			path: '/create-profile',
			exact: true,
			isPrivate: true,
			noPostModal: true,
			header: (
				<h1 class='text-primary'>
					<i className='fas fa-user' />
					&nbsp; Create profile
				</h1>
			),
			get main() {
				return () => <MainWrapper header={this.header} Main={CreateProfile} />;
			},
		},
		{
			path: '/demography',
			exact: true,
			isPrivate: true,
			noPostModal: true,
			header: (
				<h1 class='text-primary'>
					{/* <i className='fas fa-globe-americas' /> */}
					<i className='fas fa-chart-pie ' />
					&nbsp; Demography
				</h1>
			),
			get main() {
				return () => <MainWrapper header={this.header} Main={DemographicInfo} />;
			},
			sidebar: () => <div id='right-side' className='side-container'></div>,
		},

		{
			path: '/add-censorship',
			exact: true,
			isPrivate: true,
			noPostModal: true,
			header: <h1 class='text-primary'>Submit Censorship Event</h1>,
			get main() {
				return () => <MainWrapper header={this.header} Main={AddCensorEvent} />;
			},
			// sidebar: () => <RightSide />,
			sidebar: () => <div id='right-side' className='side-container'></div>,
		},
		{
			path: '/add-active-acct',
			exact: true,
			isPrivate: true,
			noPostModal: true,
			header: <h1 class='text-primary'>Add Active Account</h1>,
			get main() {
				return () => <MainWrapper header={this.header} Main={AddActive} />;
			},
			sidebar: () => <div id='right-side' className='side-container'></div>,
		},
		{
			path: '/posts/:postType/:id/:anchor?',
			exact: true,
			isPrivate: false,
			header: <h1 class='text-primary'>Post</h1>,
			get main() {
				return () => <MainWrapper header={this.header} Main={MainPost} />;
			},
			sidebar: () => <RightSide />,
		},
		{
			path: '/about',
			exact: true,
			isPrivate: false,
			header: <h1 class='text-primary'>About</h1>,
			noPostModal: true,
			get main() {
				return () => <MainWrapper header={this.header} Main={About} />;
			},
			sidebar: () => <RightSide />,
		},

		{
			path: '/search/:query?',
			exact: true,
			isPrivate: false,
			header: <SearchBox />,
			get main() {
				return () => <MainWrapper header={this.header} Main={Search} />;
			},
			sidebar: () => <div id='right-side' className='side-container'></div>,
		},

		// {
		//   path: '/profiles',
		//   exact: true,
		//   isPrivate: false,
		//   main: () => <Profiles />,
		//   sidebar: () => <RightSide />,
		// },

		{
			path: '/',
			exact: true,
			main: () => (isAuthenticated ? <Redirect to='/home' /> : <Landing />),
			// sidebar: () => isAuthenticated && <GuestNav />,
		},
		{
			path: '/login',
			exact: true,
			main: () => <Login />,
			// sidebar: () => <GuestNav />,
		},
		{
			path: '/register',
			exact: true,
			main: () => <Register />,
			// sidebar: () => <GuestNav />,
		},
		{
			path: '/verify/:token',
			exact: true,
			main: () => <VerifyUser />,
		},
		{
			path: '/reset/:token',
			exact: true,
			main: () => <ResetPassword />,
		},
		{
			path: '/forgot',
			exact: true,
			main: () => <ForgotPassword />,
		},

		{
			path: '',
			exact: false,
			isPrivate: false,
			noPostModal: true,
			main: () => <NotFound />,
		},
		,
	];

	return loading ? (
		<Spinner />
	) : (
		<Fragment>
			{!isAuthenticated && !loading && <GuestNav />}
			<div id='container' className={isAuthenticated ? 'auth' : 'unauth'}>
				{isAuthenticated && <Navbar />}

				<Switch>
					{routes.map(({ path, exact, isPrivate, main: component, sidebar }) =>
						isPrivate ? (
							<PrivateRoute {...{ path, exact, component }} />
						) : (
							<Route {...{ path, exact, component }} />
						)
					)}
				</Switch>
				<Switch>
					{routes.map(({ path, exact, isPrivate, sidebar: component }) => (
						<Route {...{ path, exact, component }} />
					))}
				</Switch>
			</div>
			{isAuthenticated && (
				<Switch>
					{routes.map(
						({ path, exact, noPostModal }) =>
							!noPostModal && (
								<PrivateRoute path={path} exact={exact} component={PostModal} />
							)
					)}
				</Switch>
			)}
		</Fragment>
	);
};

const mapStateToProps = ({ auth }) => ({
	auth,
});

export default connect(mapStateToProps, { loadUser })(withRouter(Routes));
