// this should be rewritten. horrible code!
const shouldMatchRegex = /^(?!(?:[^-]*-){2}|(?:[^ ]* ){5})[- a-z0-9]{0,32}$/i;
export default function _parseInput(input, formArr, dynamic = false) {
	const comma = input.charAt(input.length - 1) === ',';
	if (comma) {
		const parsedInput = input
			.substring(0, input.length - 1)
			.trim()
			.toLowerCase();
		const badInput = !parsedInput.match(shouldMatchRegex);

		if ((comma && input.length === 1) || badInput) return '_invalid';
		return formArr.length > 21
			? '_limit'
			: formArr.filter(e => e.value.toLowerCase() === parsedInput).length >
			  (dynamic ? 1 : 0)
			? '_dups'
			: `${parsedInput.split('')[0].toUpperCase()}${parsedInput
					.split('')
					.slice(1)
					.join('')}`;
	} else return '_goodInput';
}
