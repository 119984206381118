import React, { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { _handleReminder, _rmReminder } from '../common/_reminder.js';
import { connect } from 'react-redux';
// import { getThread } from '../../actions/post.js';
import ThreadItem from './ThreadItem';
import { hashtagify } from '../common/_utilities';
import Spinner from '../layout/Spinner';
import { PostImg, ImgModal } from './PostImg.js';

const Thread = ({
	post: {
		post: { parent },
	},
	thread: { posts, loading },
	history,
}) => {
	const [modalData, setModalData] = useState({ _id: '', src: {}, text: '' });
	const postImg = post => <PostImg {...{ post, setModalData }} />;

	return parent ? (
		loading ? (
			<Spinner />
		) : (
			<div id='anterior-thread-cont'>
				{modalData._id && <ImgModal {...{ modalData, setModalData }} />}
				{posts.map((ti, i) => (
					<ThreadItem
						{...{
							post: {
								...ti,
								text: hashtagify(ti.text, history),
							},
							postImg: postImg(ti),
							i,
						}}
					/>
				))}
			</div>
		)
	) : (
		''
	);
};

Thread.propTypes = {
	post: PropTypes.object.isRequired,
};

const mapStateToProps = ({ post, thread }) => ({
	post,
	thread,
});

export default connect(mapStateToProps, {
	// getThread,
})(withRouter(Thread));
