import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { _handleReminder, _rmReminder } from '../common/_reminder.js';
import { parsePicsUser } from '../common/_utilities.js';
// import PostActions from '../common/PostActions.js';
import PostContent from '../post/PostContent.js';
import { PostImg, ImgModal } from '../post/PostImg';

const QuotedPost = ({ post }) => {
	// console.log('LINE2: 11  post ', post);
	const { _id, user, postType } = post;
	const history = useHistory();
	const wasRedacted = user.username === 'redacted';
	const [modalData, setModalData] = useState({ _id: '', src: {}, text: '' });
	const postImg = post => <PostImg {...{ post, setModalData }} />;
	return (
		<div className='quoted-post-cont'>
			<div className='post-img'>
				<div className={`post-cont ${wasRedacted ? 'greyed-out-txt' : ''}`}>
					<div className='post quoted-post'>
						<div
							className='thread-item-cont'
							onClick={e => {
								e.stopPropagation();
								if (window.getSelection().toString().length === 0) {
									history.push(
										`/posts/${
											postType === 'BasePost' ? 'Post' : postType
										}/${_id}`
									);
								}
							}}>
							<div className='pic-thread'>
								<div
									className='profile-pic-cont img-cont'
									onClick={e =>
										!e.stopPropagation() &&
										history.push(`/profile/${user.username}`)
									}>
									<img
										className='br-50'
										src={parsePicsUser(user, 'thumb')}
										alt='avatar'
									/>
								</div>
							</div>
							<div className={` thread-post `}>
								<PostContent
									{...{ post, postImg: postImg(post), wasRedacted }}
								/>
								{/* <PostActions {...{ post, wasRedacted, postActionCreators }} /> */}
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

QuotedPost.propTypes = {
	post: PropTypes.object.isRequired,
};

export default QuotedPost;
