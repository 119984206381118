import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { connect } from 'react-redux';
import { deleteCensorEvent } from '../../actions/authProfile';

const DashCensorship = ({
	auth: {
		user: { censorEvents },
	},
	deleteCensorEvent,
	setHeader,
	history,
}) => {
	const [showUnlist, setShowUnlist] = useState({ initial: false });

	setHeader(<h1 className='text-primary'>Your Censorship Events</h1>);

	const CensorEvents = censorEvents.map(cen => (
		<tr
			onClick={e => {
				history.push(`/posts/CensorEvent/${cen._id}`);
			}}
			key={cen._id}
			className={`${showUnlist[cen._id] ? 'highlight-del' : ''}`}>
			<td>{cen.platform}</td>
			<td className='hide-md'>{cen.formOfCensor}</td>
			<td className='dash-delete-date'>
				{showUnlist[cen._id] && (
					<button
						onClick={e => {
							e.stopPropagation();
							window.confirm('Are you sure? This CANNOT be undone!') &&
								deleteCensorEvent(cen._id);
						}}
						className='btn btn-danger '>
						<span>
							<i className='fas fa-times'></i> Unlist
						</span>
					</button>
				)}
				{!showUnlist[cen._id] && (
					<Moment format='MM/DD/YYYY'>{cen.dates && cen.citationDate}</Moment>
				)}
			</td>
			<td
				className='dash-delete-ellipsis'
				onClick={e => {
					e.stopPropagation();
					setShowUnlist({
						...showUnlist,
						[cen._id]: !showUnlist[cen._id],
					});
				}}>
				<div>
					{/* <i className='fas fa-caret-left' /> */}
					<i className='fas fa-ellipsis-v' />
				</div>
			</td>
		</tr>
	));

	return (
		<div className='dash-table-cont'>
			<h2 className='mx-2 '>Censorship Events</h2>
			<table>
				<thead className=''>
					{CensorEvents.length ? (
						<tr className=''>
							<th>Platform</th>
							<th className='hide-md'>Form</th>
							<th>Submitted</th>
							<th>
								<i className='fas fa-times' />
							</th>
						</tr>
					) : (
						<th>No censorship events</th>
					)}
				</thead>
				<tbody>{CensorEvents}</tbody>
			</table>
		</div>
	);
};
// className='dash-item'
DashCensorship.propTypes = {
	auth: PropTypes.object.isRequired,
	deleteCensorEvent: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => ({
	auth,
});

export default connect(mapStateToProps, { deleteCensorEvent })(DashCensorship);
