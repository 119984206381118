import { combineReducers } from 'redux';
import alert from './alert';
import auth from './auth';
import { profiles, userProfile, subscriptions } from './profile';
import { thread, post, posteriorThread, comments } from './post';
import { posts } from './posts';
import { homePosts } from './homePosts';
import { explorePosts } from './explorePosts';
import { censorPosts } from './censorPosts';
import notifications from './notifications';
import { common, trending } from './common';

export default combineReducers({
	alert,
	auth,
	userProfile,
	subscriptions,
	profiles,
	posts,
	homePosts,
	explorePosts,
	censorPosts,
	thread,
	post,
	posteriorThread,
	comments,
	notifications,
	common,
	trending,
});
