import { INITIAL_POSTS_STATE } from '../components/common/_constants';
import { LOAD_LIMIT } from '../components/common/_shared_constants';
import {
	GET_POSTS,
	POSTS_ERROR,
	CLEAR_POSTS,
	GET_TOP_PLATFORMS,
	SET_LOAD_MORE_POSTS,
	GET_PROFILE_POSTS_COUNT,
	APPEND_POSTS,
} from '../actions/types';

import {
	_posts_common_actions_switch,
	_clear_posts,
	_get_posts,
	_append_posts,
	_add_post,
	_remove_post,
	_posts_error,
	_get_top_platforms,
	_set_load_more,
	_set_scroll_y,
} from './_posts_common';

export const posts = (state = INITIAL_POSTS_STATE, action) => {
	const { type, payload } = action;
	switch (type) {
		case APPEND_POSTS:
			return _append_posts({ state, payload });

		case GET_POSTS:
			return _get_posts({
				state: { ...INITIAL_POSTS_STATE, topPlatforms: state.topPlatforms },
				payload,
			});

		case CLEAR_POSTS:
			return _clear_posts(INITIAL_POSTS_STATE);

		case POSTS_ERROR:
			return _posts_error({ state, payload });

		case GET_TOP_PLATFORMS:
			return _get_top_platforms({ state, payload });

		case SET_LOAD_MORE_POSTS:
			return _set_load_more({ state, payload });

		case GET_PROFILE_POSTS_COUNT:
			return {
				...state,
				postsCount: payload,
			};
	}

	return _posts_common_actions_switch({ type, state, payload });
};
