import React, { useEffect, useState, Fragment } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { queryStringToObject } from '../common/_utilities';
import SearchBox from '../common/SearchBox';
import Posts from '../posts/Posts';
import _ from 'lodash';
import {
	getSearchPosts,
	appendSearchPosts,
	clearPosts,
	setLoadMorePosts,
	getTopPlatforms,
	// //setScrollYPosts,
} from '../../actions/posts';
import FilterDropdown from '../common-post/FilterDropdown';
import TopPlatforms from '../common/TopPlatforms';

const Search = ({
	posts,
	getSearchPosts,
	appendSearchPosts,
	getTopPlatforms,
	clearPosts,
	setLoadMorePosts,
	location,
}) => {
	const { loading, topPlatforms } = posts;

	const [query] = useState(queryStringToObject(location.search));

	const { text, postType } = query;

	useEffect(() => {
		console.log('LINE2: 38  text', text);
		getSearchPosts(query);
		getTopPlatforms(query);
		if (!text) {
			!text && document.querySelector('#search-box form input[type=text]').focus();
		}
	}, [query]);

	const [emptyQueryMsg, setEmptyQueryMsg] = useState('');

	useEffect(() => {
		if (text) {
			setEmptyQueryMsg(
				text && `No ${!postType ? 'result' : postType}s for \"${text}\"`
			);
		}
	}, [text]);

	return (
		<Fragment>
			{loading ? (
				<small className='form-text empty'>
					{!text ? 'Search for something..' : 'Searching...'}
				</small>
			) : (
				<Fragment>
					<TopPlatforms
						{...{
							topPlatforms,
							label: `Top censored platforms for "${text}"`,
							query,
						}}
					/>
					<FilterDropdown
						{...{
							query,
							clearPostsAction: clearPosts,
							active: { submissionDate: true, postType: true, sortBy: true },
						}}
					/>
					<Posts
						posts={posts}
						getPostsAction={appendSearchPosts}
						// setScrollY={//setScrollYPosts}
						setLoadMore={setLoadMorePosts}
						emptyMessage={emptyQueryMsg}
					/>
				</Fragment>
			)}
		</Fragment>
	);
};

Search.propTypes = {
	getSearchPosts: PropTypes.func.isRequired,
	appendSearchPosts: PropTypes.func.isRequired,
	getTopPlatforms: PropTypes.func.isRequired,
	posts: PropTypes.object.isRequired,
	clearPosts: PropTypes.func.isRequired,
	setLoadMorePosts: PropTypes.func.isRequired,
	//setScrollYPosts: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, posts }) => ({
	auth,
	posts,
});

export default connect(mapStateToProps, {
	clearPosts,
	getSearchPosts,
	appendSearchPosts,
	getTopPlatforms,
	//setScrollYPosts,
	setLoadMorePosts,
	// clearTopPlatforms,
})(withRouter(Search));
