import React, { Fragment as F, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { _handleReminder, _rmReminder } from '../common/_reminder.js';
import { UpdatedMoment as moment } from '../common/_utilities';
import { CensorPostData } from '../posts/CensorPostData.js';
import QuotedPost from '../post/QuotedPost';

const PostContent = ({
	post,
	postImg,
	history,
	wasRedacted,
	handleUserSummary,
}) => {
	const {
		text = '',
		name,
		user: { username },
		date,
		img,
		quotedPost,
		postType,
		isThread,
	} = post;

	return (
		<div
			className={`post-content  ${isThread ? 'top-connector-compensate' : ''}`}>
			<div className='name-cont' {...handleUserSummary}>
				<b
					className='text-link'
					onClick={e =>
						!e.stopPropagation() && history.push(`/profile/${username}`)
					}>
					{name}
				</b>
				{!wasRedacted && (
					<span
						className={'lite-txt'}
						onMouseEnter={e =>
							_handleReminder({ e, _class: 'my-3', msg: 'Date submitted' })
						}
						onMouseLeave={_rmReminder}>
						@{username}{' '}
					</span>
				)}
				<span className={!wasRedacted ? 'lite-txt' : 'greyed-out-txt'}>
					&nbsp; · &nbsp;
				</span>
				<span
					className={!wasRedacted ? 'lite-txt' : 'greyed-out-txt'}
					onMouseEnter={e =>
						_handleReminder({ e, _class: 'my-3', msg: 'Date submitted' })
					}
					onMouseLeave={_rmReminder}>
					{moment(date).fromNow(true)}
				</span>
			</div>
			<div className='text-cont'>
				<p>{text}</p>
			</div>
			{postType === 'CensorEvent' && <CensorPostData {...{ post }} />}
			{img && postImg}
			{quotedPost && <QuotedPost {...{ post: quotedPost }} />}
		</div>
	);
};

PostContent.propTypes = {
	post: PropTypes.object.isRequired,
	postImg: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired,
	wasRedacted: PropTypes.bool.isRequired,
};

export default withRouter(PostContent);
