import {
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	USER_LOADED,
	AUTH_ERROR,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT,
	ACCOUNT_DELETED,
	FORGOT_SUCCESS,
	FORGOT_FAIL,
	UPASSWORD_SUCCESS,
	UPASSWORD_FAIL,
	UPDATE_AUTH_CENSOR,
	GET_PROFILE,
	UPDATE_ACTIVE_ACCT,
	DELETE_AUTH_CENSOR,
	DELETE_ACTIVE_ACCT,
	AUTH_PROFILE_ERROR,
} from '../actions/types';

import { hasExistence } from '../components/common/_utilities';
// import util from 'util';

const initialState = {
	token: localStorage.getItem('token'),
	isAuthenticated: null,
	loading: true,
	user: null,
	// scrollY: 0
	error: [],
};

export default function (state = initialState, action) {
	const { type, payload } = action;
	// console.log('LINE2: 38  payload ', payload);
	const { user } = state;
	switch (type) {
		case GET_PROFILE:
			return {
				...state,
				...payload,
			};

		case UPDATE_ACTIVE_ACCT:
			return {
				...state,
				user: {
					...state.user,
					activeAccts: [payload, ...state.user.activeAccts],
				},
			};
		case DELETE_ACTIVE_ACCT:
			return {
				...state,
				user: {
					...user,
					activeAccts: [...user.activeAccts.filter(act => act.id !== payload)],
				},
			};
		case GET_PROFILE:
			return {
				...state,
				user: {
					...state.user,
					profile: payload,
				},
			};

		case USER_LOADED:
			return {
				...state,
				isAuthenticated: true,
				loading: false,
				user: payload,
			};
		case REGISTER_SUCCESS:
		case LOGIN_SUCCESS:
		case UPASSWORD_SUCCESS:
			// TODO
			// should this be setAuthToken in the action?
			localStorage.setItem('token', payload.token);
			localStorage.setItem('privateRoom', payload.privateRoom);

			return {
				...state,
				...payload,
				isAuthenticated: true,
			};

		case AUTH_PROFILE_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};

		case REGISTER_FAIL:
		// case AUTH_ERROR:
		case LOGIN_FAIL:
		case LOGOUT:
		case FORGOT_FAIL:
		case UPASSWORD_FAIL:
			localStorage.removeItem('token');
			localStorage.removeItem('privateRoom');
			console.log(`auth reducer fail or logout`);
			return {
				...state,
				token: null,
				// privateRoom:null
				isAuthenticated: false,
				loading: false,
			};

		case AUTH_ERROR: // dev test: not sure this is going to work for production. Was above, but his prevents logouts during development.
			return {
				...state,
				// token: null,
				isAuthenticated: false,
				loading: false,
				error: [...state.error, payload],
			};
		case ACCOUNT_DELETED:
			// Should other fails also clear user?
			localStorage.removeItem('token');
			localStorage.removeItem('privateRoom');
			return {
				...state,
				token: null,
				user: null,
				isAuthenticated: false,
				loading: false,
			};

		case UPDATE_AUTH_CENSOR:
			return {
				...state,
				user: {
					...user,
					censorEvents: hasExistence(user, 'censorEvents')
						? [...state.user.censorEvents, payload]
						: [payload],
				},
				loading: false,
			};

		case DELETE_AUTH_CENSOR:
			console.log('OUTPUT: profile -> state', state);
			return {
				...state,
				user: {
					...user,
					censorEvents: [...user.censorEvents.filter(ce => ce._id !== payload)],
				},
			};

		case FORGOT_SUCCESS:
		default:
			return state;
	}
}
