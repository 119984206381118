import React, { useEffect, Fragment, useState } from 'react';
import {
	Redirect,
	Route,
	Switch,
	Link,
	withRouter,
	useRouteMatch,
	useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DashActions from './DashActions';
import DashYourData from './DashYourData';
import DashSettings from './DashSettings';
import DashCensorship from './DashCensorship';
import DashActive from './DashActive';
import DashEditEmailToken from '../dashboard/DashEditEmailToken';
import DashEditEmailRequest from '../dashboard/DashEditEmailRequest';
import DashEditName from '../dashboard/DashEditName';
import DashDeleteAcct from '../dashboard/DashDeleteAcct';
import { deactivateNotifications } from '../../actions/notifications';
import { logout } from '../../actions/auth';
import { _handleReminder, _rmReminder } from '../common/_reminder';
import copy from 'copy-to-clipboard';

const Dashboard = ({ auth: { user }, setHeader, logout, history }) => {
	const { profile } = user;
	const { path, url } = useRouteMatch();

	const location = useLocation();

	// this maintains the location.state within dash routes and is used by header to go directly back to route before dash
	const dashHistory = {
		...history,
		push: (historyState => path => history.push(path, historyState))(
			location.state
		),
	};

	return (
		<Fragment>
			<div id='dash-cont' style={{ marginBottom: 'auto ' }}>
				<ul className='selection '>
					<Route strict path={`${url}/`}>
						<li onClick={e => history.goBack()}>
							<span>
								<i class='fas fa-arrow-left' />
							</span>
						</li>
					</Route>
					<Route exact path={path}>
						{profile && (
							<Fragment>
								<li onClick={e => dashHistory.push(`${url}/actions`)}>
									<span>Actions</span>
								</li>
								<li onClick={e => dashHistory.push(`${url}/your-data`)}>
									<span>Your Data</span>
								</li>
								{/*
								<li onClick={e => dashHistory.push(`${url}/censor-events`)}>
									<span>Censorship Events</span>
								</li>
								<li onClick={e => dashHistory.push(`${url}/active-accounts`)}>
									<span>Active Accounts</span>
								</li>
								*/}
								<li
									onClick={e => dashHistory.push(`/profile-id/${user._id}`)}
									onMouseEnter={e =>
										_handleReminder({
											e,
											msg:
												'This link will always point to your profile even if username changes',
											_class: 'mx-2',
										})
									}
									onMouseLeave={_rmReminder}>
									<span>Profile permalink</span>
									<span
										className='px-2 text-icon'
										onMouseEnter={e => {
											_rmReminder(e.currentTarget.parentNode);
										}}
										onClick={e => {
											e.stopPropagation();
											_handleReminder({ e, msg: 'copied', _class: 'mx-2' });
											setTimeout(function () {
												_rmReminder();
											}, 1000);

											copy(`${window.location.host}/profile-id/${user._id}`);
										}}>
										<i className='far fa-copy' />
									</span>
								</li>
							</Fragment>
						)}
						<li onClick={e => dashHistory.push(`${url}/settings`)}>
							<span>Settings</span>
						</li>
					</Route>
				</ul>
				<Switch>
					<Route path={`${path}/actions`}>
						{profile && <DashActions setHeader={setHeader} />}
					</Route>
					<Route path={`${path}/your-data`}>
						{profile && <DashYourData setHeader={setHeader} />}
					</Route>
					<Route path={`${path}/settings`}>
						<DashSettings {...{ history: dashHistory }} />
					</Route>
					<Route path={`${path}/censor-events`}>
						<DashCensorship {...{ history: dashHistory }} />
					</Route>
					<Route path={`${path}/active-accounts`}>
						<DashActive {...{ history: dashHistory }} />
					</Route>
					<Route path={`${path}/edit-name`}>
						<DashEditName />
					</Route>
					<Route path={`${path}/edit-email`}>
						<Switch>
							<Route exact path={`${path}/edit-email`}>
								<DashEditEmailRequest />
							</Route>
							<Route path={`${path}/edit-email/token`}>
								<DashEditEmailToken />
							</Route>
						</Switch>
					</Route>
					<Route path={`${path}/delete-account`}>
						<DashDeleteAcct />
					</Route>
				</Switch>
			</div>
		</Fragment>
	);
};

Dashboard.propTypes = {
	auth: PropTypes.object.isRequired,
	logout: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	auth: state.auth,
	posts: state.posts,
});

export default connect(mapStateToProps, {
	deactivateNotifications,
	logout,
})(withRouter(Dashboard));
