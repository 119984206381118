import ms from 'ms';

// AddCensorEvents
const SUGGEST_BANKS = [
	'Bank of America',
	'Wells Fargo',
	'Citi Bank',
	'Industrial & Commercial Bank of China',
	'China Construction Bank Corp.',
	'Agricultural Bank of China',
	'Bank of China',
	'HSBC Holdings',
	'JPMorgan Chase & Co.',
	'BNP Paribas',
	'Mitsubishi UFJ Financial Group',
	'Credit Agricole Group',
];

const SUGGEST_PLATFORMS = [
	...[
		'Facebook',
		'Instagram',
		'iTunes',
		'Twitter',
		'WhatsApp',
		'Youtube',
		'WeChat',
		'Messenger',
		'QQ',
		'Tumblr',
		'Qzone',
		'Tik Tok',
		'Sina Weibo',
		'Reddit',
		'Baidu Tieba',
		'LinkedIn',
		'PayPal',
		'GoFundMe',
		'Viber',
		'Patreon',
		'Snapchat',
		'Pinterest',
		'Line',
		'Telegram',
		'Medium',
		'Stripe',
		'Venmo',
		'Cash App',
		'Uber',
		'UberEATS',
		'Grubhub',
		'Airbnb',
	],
	...SUGGEST_BANKS,
];

const SUGGEST_CENSORTYPES = [
	'demonetization',
	'warning',
	'restricted functionality',
	'temporary ban',
	'deplatformed',
	'shadow–banned',
	'permanent ban',
	'self-censorship',
	'copyright strike',
];

// TODO: get a better list of subjects
const SUGGEST_SUBJECTS = ['lifestyle', 'politics', 'music', 'fashion'];

// const LOAD_LIMIT = 5;
// const KEEP_LIMIT = 15;

// const LOAD_LIMIT = 13;
const KEEP_LIMIT = 100;
const PULL_LIMIT = 10; // when load limit is reached, pull this amount
// ^ this should be renamed. PULL_AMOUNT or something

// this should work, but it's not
const TLD_REGEX = '^((?!-)[A-Za-z0–9-]{1, 63}(?<!-).)+[A-Za-z]{2, 6}$';

const EMAIL_REGEX = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

// const REDACTED_ID = '5f7d3d241906d79acdc59e1c';

const CNSR_VERSION = 'beta 0.1';

// const DATE_SORT = {
// 	// key: 'sortBy',
// 	key: 'date',
// 	label: 'SORT BY',
// 	options: [
// 		{
// 			method: 'Date added (oldest)',
// 			value: 1, //ascending
// 		},
// 		{
// 			method: 'Date added (newest)',
// 			value: -1, //descending
// 		},
// 	],

// 	default: {
// 		method: 'Date added (newest)',
// 		value: -1, //descending
// 	},
// };

// const SUBMISSION_DATE = {
// 	key: 'submissionDate',

// 	options: [
// 		{
// 			method: 'All time',
// 			value: 0,
// 		},
// 		{
// 			method: 'Last hour',
// 			value: '1h',
// 		},
// 		{
// 			method: 'Today',
// 			value: '1d',
// 		},
// 		{
// 			method: 'This week',
// 			value: '7d',
// 		},
// 		{
// 			method: 'This month',
// 			value: '30d',
// 		},
// 		{
// 			method: 'This year',
// 			value: '1y',
// 		},
// 		,
// 	],
// 	default: {
// 		method: 'All time',
// 		value: 0,
// 	},
// };

// it would be awesome to add QuotedPosts to this
// const POST_TYPE = {
// 	key: 'postType',
// 	options: [
// 		{
// 			method: 'All',
// 			value: 'BasePost',
// 		},
// 		{
// 			method: 'Censor events',
// 			value: 'CensorEvent',
// 		},
// 		{
// 			method: 'Posts',
// 			value: 'Post',
// 		},
// 		{
// 			method: 'Comments',
// 			value: 'Comment',
// 		},
// 	],
// 	default: {
// 		method: 'All',
// 		value: 'BasePost',
// 	},
// };

const INITIAL_POSTS_STATE = {
	posts: [],
	topPlatforms: [], // this could be only added into those who use it
	skipIndex: 0,
	scrollY: 0,
	prependAlert: false,
	noMore: false,
	loadMore: false,
	loading: true,
	query: {},
	error: {},
};

// const INTERVAL_UPDATE_TIME = ms('2m');
const INTERVAL_UPDATE_TIME = ms('40m');
// const INTERVAL_UPDATE_TIME = 1200000;
// const INTERVAL_UPDATE_TIME = 13000;
const SCROLL_ALERT_LIMIT = 200; //px

const PRIMARY_COLOR = '#FF7900';

// const IMG_UPLOAD_SIZE_LIMIT = 2040750;

export {
	SUGGEST_CENSORTYPES,
	SUGGEST_SUBJECTS,
	SUGGEST_PLATFORMS,
	// LOAD_LIMIT,
	KEEP_LIMIT,
	PULL_LIMIT,
	TLD_REGEX,
	EMAIL_REGEX,
	// REDACTED_ID,
	CNSR_VERSION,
	// POST_TYPE,
	// DATE_SORT,
	// SUBMISSION_DATE,
	INITIAL_POSTS_STATE,
	INTERVAL_UPDATE_TIME,
	SCROLL_ALERT_LIMIT,
	PRIMARY_COLOR,
	// IMG_UPLOAD_SIZE_LIMIT,
};
