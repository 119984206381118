import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { _handleReminder, _rmReminder } from '../common/_reminder.js';
import RecycleActions from '../common-post/RecycleActions';
import VotingActions from '../common-post/VotingActions';
import { postActionsAuthWrapper } from '../common/_utilities';
import { setCommentOn, setQuotePost } from '../../actions/post';
import {
	upvotePost,
	downvotePost,
	deletePost,
	recyclePost,
	unrecyclePost,
} from '../../actions/posts';
import abbreviate from 'number-abbreviate';

const PostActions = ({
	mainId = '',
	auth,
	post,
	upvotePost,
	downvotePost,
	deletePost,
	setCommentOn,
	setQuotePost,
	recyclePost,
	unrecyclePost,
}) => {
	const history = useHistory();
	const [showDelete, setShowDelete] = useState(false);

	useEffect(() => {
		if (showDelete) window.onclick = () => !setShowDelete(false);
		else window.onclick = '';
	}, [showDelete]);

	const isOwner = auth.isAuthenticated && auth.user.id === post.user._id;

	const postActionCreators = postActionsAuthWrapper({
		postActionCreators: {
			upvotePost,
			downvotePost,
			recyclePost,
			unrecyclePost,
			setQuotePost,
		},
		auth,
		history,
	});

	const [wasRedacted] = useState(post && post.user.username === 'redacted');

	return (
		<div id={mainId} className='post-actions actions-cont-shared'>
			<div className={`${isOwner ? 'actions-with-ellipsis ' : 'actions'}`}>
				<VotingActions {...{ post, postActionCreators }} />

				<RecycleActions {...{ postActionCreators, post }} />

				<div
					onMouseEnter={e => _handleReminder({ e, _class: 'myt-6', msg: 'Reply' })}
					onMouseLeave={_rmReminder}
					onClick={e => {
						e.stopPropagation();
						auth.isAuthenticated ? setCommentOn(post) : history.push('/login');
					}}
					className={`${!wasRedacted ? 'reply' : 'greyed-out-txt'} action p-1`}>
					<span>
						<i className='fas fa-comment-alt ' /> &nbsp;
						{!!post.v_comments_count && abbreviate(post.v_comments_count)}
					</span>
				</div>

				{isOwner ? (
					<span
						className='action custom-ellipsis p-1 '
						onClick={e => !e.stopPropagation() && setShowDelete(!showDelete)}>
						<i className='fas fa-ellipsis-v '></i>
					</span>
				) : (
					<span className='no-ellipsis'></span>
				)}
				{showDelete && (
					<button
						onClick={e => {
							e.stopPropagation();
							deletePost({
								_id: post._id,
								history,
							});
							setShowDelete(false);
						}}
						className='btn btn-danger delete-x'>
						<span>
							<i className='fas fa-times' /> Delete
						</span>
					</button>
				)}
			</div>
		</div>
	);
};

PostActions.prototypes = {
	auth: PropTypes.object.isRequired,
	post: PropTypes.object.isRequired,
	postActionCreators: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	auth: state.auth,
});

export default connect(mapStateToProps, {
	upvotePost,
	downvotePost,
	deletePost,
	setCommentOn,
	setQuotePost,
	recyclePost,
	unrecyclePost,
})(PostActions);
