import axios from 'axios';
import { setAlert } from './alert';
import {
	GET_POST,
	ADD_COMMENT,
	GET_COMMENTS,
	GET_THREAD,
	POSTS_COMMENTS,
	POST_ERROR,
	THREAD_ERROR,
	COMMENT_ON,
	QUOTE_POST,
	CLEAR_COMMENT_ON,
	CLEAR_QUOTE_POST,
	GET_POSTERIOR,
	APPEND_COMMENTS,
	SET_LOAD_MORE_COMMENTS,
} from './types';

export const getPost = ({ _id, postType }) => async dispatch => {
	try {
		const res = await axios.get(`/api/post/get-post-by-id/${postType}/${_id}`);

		const { anteriorThread, posteriorThread, comments, post } = res.data;
		console.log('actions/post.js: 34  post ', post);
		console.log('actions/post.js: 34  posteriorThread', posteriorThread);
		console.log('actions/post.js: 34  comments', comments);

		dispatch({
			type: GET_THREAD,
			payload: anteriorThread,
		});

		dispatch({
			type: GET_POST,
			payload: post,
		});

		dispatch({
			type: GET_POSTERIOR,
			payload: posteriorThread,
		});

		dispatch({
			type: GET_COMMENTS,
			payload: comments,
		});
	} catch (error) {
		post_error(error, dispatch);
	}
};

// change to appendComments
export const appendComments = query => async dispatch => {
	console.log('actions/post.js: 84  query', query);
	try {
		const res = await axios.put(`/api/post/get-more-comments`, query);

		console.log('LINE2: 75  res ', res);

		dispatch({
			type: APPEND_COMMENTS,
			payload: res.data,
		});
	} catch (error) {
		post_error(error, dispatch);
	}
};

export const setLoadMoreComments = index => dispatch => {
	dispatch({ type: SET_LOAD_MORE_COMMENTS });
};

export const addComment = (formDataJSON, { main_id }) => async dispatch => {
	const formData = new FormData();
	for (let key in formDataJSON) {
		if (!Array.isArray(formDataJSON[key])) {
			formData.append(key, formDataJSON[key]);
		} else formData.append(key, JSON.stringify(formDataJSON[key]));
	}

	try {
		const res = await axios.post(`/api/post/comment`, formData);

		dispatch({
			type: POSTS_COMMENTS,
			payload: res.data,
		});

		console.log('LINE2: 199  main_id ', main_id);
		console.log('LINE2: 201  res.data.parent', res.data.parent);
		main_id &&
			res.data.parent === main_id &&
			dispatch({
				type: ADD_COMMENT,
				payload: res.data,
			});

		const { postType, _id } = res.data;

		dispatch(
			setAlert({
				msg: 'Comment Added',
				alertType: 'success',
				postUrl: `/posts/${postType === 'BasePost' ? 'Post' : postType}/${_id}`,
			})
		);
	} catch (error) {
		post_error(error, dispatch);
	}
};

// Post Form Modal
export const setCommentOn = post => async dispatch => {
	try {
		// post &&
		dispatch({
			type: COMMENT_ON,
			payload: post,
		});
	} catch (error) {
		post_error(error, dispatch);
	}
};

export const setQuotePost = post => async dispatch => {
	try {
		dispatch({
			type: QUOTE_POST,
			payload: post,
		});
	} catch (error) {
		post_error(error, dispatch);
	}
};

export const clearCommentOn = () => dispatch =>
	dispatch({ type: CLEAR_COMMENT_ON });

export const clearQuotePost = () => dispatch =>
	dispatch({ type: CLEAR_QUOTE_POST });

// Errors
const post_error = (error, dispatch) => {
	console.log('OUTPUT: post_error -> error', error);
	const errors = error.response.data.errors;
	dispatch({
		type: POST_ERROR,
		payload: {
			msg: error.response.statusText,
			status: error.response.status,
		},
	});
	if (errors) {
		errors.forEach(({ msg }) => dispatch(setAlert({ msg, alertType: 'danger' })));
		return 400;
	}
};

const thread_error = (error, dispatch) => {
	const errors = error.response.data.errors;
	dispatch({
		type: THREAD_ERROR,
		payload: {
			msg: error.response.statusText,
			status: error.response.status,
		},
	});
	if (errors) {
		errors.forEach(({ msg }) => dispatch(setAlert({ msg, alertType: 'danger' })));
		return 400;
	}
};
