import React from 'react';
import PropTypes from 'prop-types';
// import { genKey } from '../common/_utilities';
import uuid from 'uuid';

const RadioGroup = ({
	options,
	value,
	classes,
	required = false,
	onWheel = false,
	onMouseLeave = false,
	setRadioSelected = () => {},
	...rest
}) => {
	const inputOptions = options.map((option, i) => {
		const isSelected = option === value;

		if (isSelected && setRadioSelected) {
			setRadioSelected(true);
		} else {
			setRadioSelected(false);
		}

		const unique = isSelected ? 'radio-selection' : uuid.v4();
		return (
			<li key={unique}>
				<input
					{...{
						type: 'radio',
						id: unique,
						value: option,
						checked: isSelected,
						required: !i && required && required,
						...rest,
					}}
				/>
				<label htmlFor={unique}>{option}</label>
			</li>
		);
	});

	return Array.isArray(options) ? (
		<ul
			id='ks-radiotags'
			className={`ks-radiotags ${classes ? classes : ''}`}
			onWheel={onWheel && onWheel}
			onMouseLeave={onMouseLeave && onMouseLeave}>
			{inputOptions}
		</ul>
	) : (
		''
	);
};

RadioGroup.propTypes = {
	onChange: PropTypes.func,
	onClick: PropTypes.func,
	options: PropTypes.array.isRequired,
	name: PropTypes.string.isRequired,
	value: PropTypes.string.isRequired,
	required: PropTypes.bool,
};

export default RadioGroup;
