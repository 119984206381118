import React, { Fragment, useState, useRef, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { findByUsername } from '../../actions/posts';
import { addCensorEvent } from '../../actions/censorPosts';
import {
	transTextareaFocus,
	transTextareaBlur,
	detectCharCodes,
	hasExistence,
	hasNestedExistence,
} from '../common/_utilities';
import {
	SUGGEST_PLATFORMS,
	SUGGEST_CENSORTYPES,
	SUGGEST_SUBJECTS,
} from '../common/_constants';
import { IMG_UPLOAD_SIZE_LIMIT } from '../common/_shared_constants.js';

import {
	labelTransNotEmpty,
	dateLabelTransition,
	checkAndTransLabels,
} from './_profileFormUtilities';
import { setAlert } from '../../actions/alert.js';
import TagSuggestions from '../common/TagSuggestions';
import { ImgModal } from '../post/PostImg';
import RadioGroup from '../common/RadioGroup';
import useTagDetection from '../common/hooks/useTagDetection';

const getMonthFormatted = function (date) {
	var month = date.getMonth() + 1;
	return month < 10 ? '0' + month : '' + month;
};

const getDayFormatted = function (date) {
	var day = date.getDate();
	return day < 10 ? '0' + day : '' + day;
};

const dateObj = new Date();
const today = `${dateObj.getFullYear()}-${getMonthFormatted(
	dateObj
)}-${getDayFormatted(dateObj)}`;

const AddCensorEvent = ({
	addCensorEvent,
	history,
	auth: { user },
	// alert: { alert },
	setAlert,
	findByUsername,
}) => {
	const [validForm, setValidForm] = useState(false);

	const [formData, setFormData] = useState({
		personalAcct: 'Yes',
		platform: '',
		// acctName: user && user.name,
		acctName: '',
		handle: '',
		// reach: null,
		income: false,
		incomeInfo: '',
		citationDate: today,
		formOfCensor: '',
		subject: '',
		text: '',
		imgName: '',
		imgFile: '',
	});

	useEffect(() => {
		user && setFormData({ ...formData, acctName: user.name });
	}, [user]);

	const [overSubmit, setOverSubmit] = useState(false);

	const [imgPreview, setImgPreview] = useState({});

	const [compensationDisabled, toggleDisabled] = useState(false);

	const [showOptional, toggleAdditionalData] = useState(false);

	const {
		personalAcct,
		platform,
		acctName,
		handle,
		reach,
		income,
		incomeInfo,
		citationDate,
		formOfCensor,
		subject,
		text,
		imgFile,
		imgName,
	} = formData;

	const handleOnChange = ({ target: { name, value } }) =>
		setFormData({ ...formData, [name]: value });

	const [modalData, setModalData] = useState({ src: '', _id: '', text: '' });

	useEffect(() => {
		checkAndTransLabels({ formData });
	}, [formData]);

	const {
		handleOnKeyDown,
		handleOnKeyPress,
		handleOnKeyCapture,
		tagSuggestions,
		completeTag,
	} = useTagDetection({ formData, setFormData, findByUsername, field: 'text' });

	return (
		<Fragment>
			<div style={{ margin: '1em' }}>
				<p>
					and help better understand internet censorship{' '}
					<i className='fas fa-fire-alt'></i>
				</p>
			</div>
			<div className='mx-1'>
				<span className='red'>*</span>
				<small> = required field</small>
			</div>
			<form
				id='add-censor-form'
				encType='multipart/form-data'
				className='form '
				onSubmit={e => {
					e.preventDefault();
					console.log('OUTPUT: formData', formData);
					addCensorEvent(formData, history);
				}}
				onKeyUp={e =>
					setValidForm(
						![...e.currentTarget.querySelectorAll('input')].filter(
							f => !f.checkValidity()
						).length
					)
				}>
				<div className='form-group'>
					<small className='form-text mx-1'>
						<span className='red'>*</span> Is this for a personal account?
					</small>
					<RadioGroup
						name='personalAcct'
						onChange={handleOnChange}
						options={['Yes', 'No, a submission for someone else']}
						value={personalAcct}
						required={true}
					/>
				</div>

				<div className='form-group '>
					<div className='use-trans-label'>
						<input
							id='platform'
							type='text'
							name='platform'
							value={platform}
							onChange={e => handleOnChange(e)}
							maxLength='26'
							list='suggest-platform'
							onBlur={labelTransNotEmpty}
							// required
						/>
						<label htmlFor='platform'>
							<span className='red'>*</span> Platform
						</label>
					</div>
					<datalist id='suggest-platform'>
						{SUGGEST_PLATFORMS.map(platform => (
							<option value={platform}></option>
						))}
					</datalist>
				</div>

				<div className='form-group'>
					<div className='use-trans-label'>
						<input
							type='text'
							name='formOfCensor'
							value={formOfCensor}
							onChange={e => handleOnChange(e)}
							onBlur={labelTransNotEmpty}
							maxLength='26'
							list='suggest-form-of-censor'
							// required
						/>
						<label>
							<span className='red'>*</span> Form of Censorship
						</label>
					</div>
					<datalist id='suggest-form-of-censor'>
						{SUGGEST_CENSORTYPES.map(formOfCensor => (
							<option value={formOfCensor}>{formOfCensor}</option>
						))}
					</datalist>
				</div>

				<div className='form-group' id='citation-date'>
					<div className='use-trans-label'>
						<label className='form-text'>
							<span className='red'>* </span>
							Date of Occurrence
						</label>
						<input
							type='date'
							name='citationDate'
							value={citationDate}
							max={today}
							onChange={e => handleOnChange(e)}
							onBlur={dateLabelTransition}
							// required
						/>
					</div>
				</div>

				<div className='form-group'>
					<div className='use-trans-label'>
						<input
							type='text'
							id='acctName'
							name='acctName'
							value={acctName}
							onChange={e => handleOnChange(e)}
							onBlur={labelTransNotEmpty}
							maxLength='26'
							list='suggest-name'
							// required
						/>
						<label htmlFor='acctName'>
							<span className='red'>* </span>
							Account Name
						</label>
					</div>
					<datalist id='suggest-name'>
						<option value={user && user.name}></option>
					</datalist>
				</div>

				<div className='form-group '>
					<div className='use-trans-label'>
						<textarea
							id='form-textarea'
							name='text'
							value={text}
							onKeyDown={handleOnKeyDown}
							onKeyUpCapture={handleOnKeyCapture}
							onKeyPress={handleOnKeyPress}
							onChange={e => handleOnChange(e)}
							onFocus={transTextareaFocus}
							onBlur={e => !labelTransNotEmpty(e) && transTextareaBlur(e, overSubmit)}
							rows='10'
							maxLength='2000'
						/>
						<label htmlFor='form-textarea'>Submission text</label>
					</div>

					<TagSuggestions
						{...{
							tagSuggestions,
							completeTag,
							positioningClass: 'ts-add-censor-event',
						}}
					/>

					{!!modalData._id && (
						<ImgModal
							{...{
								modalData,
								setModalData,
							}}
						/>
					)}

					<input
						id='imgFile'
						// className='invisible btn btn-light'
						className='invisible'
						type='file'
						onChange={e => {
							if (e.target.files.length > 0) {
								let file = e.target.files[0] || e.dataTransfer.files[0];
								if (file.size > IMG_UPLOAD_SIZE_LIMIT) {
									console.log(`CreatePro_file.size:${file.size}`);
									setAlert({
										msg: 'File is too large. 2mb limit.',
										alertType: 'danger',
									});
									return;
								}
								let reader = new FileReader();
								reader.onloadend = () => {
									setFormData({
										...formData,
										imgFile: file,
									});
									setImgPreview({
										url: reader.result,
									});
								};
								reader.readAsDataURL(file);
							}

							if (
								hasNestedExistence(e, ['target', 'files']) ||
								hasNestedExistence(e, ['dataTransfer', 'files'])
							) {
								let file = e.target.files[0] || e.dataTransfer.files[0];
								let reader = new FileReader();
							}
						}}
					/>
					{!formData.imgFile ? (
						<div className='center-btn-label'>
							<div className='mx-1'>
								<small className='form-text'>Attach an image</small>
								<label
									htmlFor='imgFile'
									style={{
										fontSize: '1.5rem',
										margin: '1rem ',
									}}>
									<span>
										<i className='far fa-image' />
									</span>
								</label>
							</div>
						</div>
					) : (
						<div className='form-img-preview'>
							<div className='img-cont '>
								<img
									src={imgPreview.url}
									alt='img-preview'
									className='br-10'
									onClick={e => {
										setModalData({
											src: e.target.src,
											text: formData.text,
											_id: 1,
										});
									}}
								/>
							</div>
							<span
								className='remove-preview'
								onClick={e => {
									setImgPreview({});
									setFormData({
										...formData,
										imgFile: '',
									});
								}}>
								<i className='fas fa-times' />
							</span>
						</div>
					)}
				</div>
				{!showOptional && (
					<span
						className='form-text m-1'
						style={{
							cursor: 'pointer',
							display: 'flex',
							alignContent: 'center',
							justifyContent: 'center',
						}}
						onClick={() => toggleAdditionalData(!showOptional)}>
						{/* <small>Add censorship event metadata </small>{' '} */}
						<span>Optional additional data</span>
						&nbsp;
						<i className='fas fa-sort-down' />
					</span>
				)}
				{showOptional && (
					<Fragment>
						<div className='form-group '>
							<div className='use-trans-label'>
								<input
									type='text'
									name='handle'
									value={handle}
									onChange={e => handleOnChange(e)}
									onBlur={labelTransNotEmpty}
									maxLength='26'
								/>
								<label>Handle or username</label>
							</div>
						</div>

						<div className='form-group'>
							<div className='use-trans-label'>
								<input
									id='reach'
									type='number'
									name='reach'
									value={reach}
									onChange={e => handleOnChange(e)}
									onBlur={labelTransNotEmpty}
									min='0'
									max='200000000'
								/>
								<label htmlFor='reach'>People reached (followers)</label>
							</div>
						</div>

						<div className='form-group'>
							<Fragment>
								<small className='form-text'>Platform relates to your income.</small>
								<label className='single-checkbox'>
									Income
									<input
										type='checkbox'
										name='income'
										checked={income}
										value={income}
										onChange={e => {
											setFormData({
												...formData,
												income: !income,
											});
											toggleDisabled(true);
										}}
									/>{' '}
									<span className='checkmark'></span>
								</label>
							</Fragment>
							{!compensationDisabled ? null : (
								<Fragment>
									<div className='my-2 use-trans-label'>
										<textarea
											name='incomeInfo'
											value={incomeInfo}
											onChange={e => handleOnChange(e)}
											onFocus={transTextareaFocus}
											onBlur={e =>
												!labelTransNotEmpty(e) && transTextareaBlur(e, overSubmit)
											}
											maxLength='400'
											rows='5'
										/>
										<label htmlFor=''>Anything to add</label>
									</div>
								</Fragment>
							)}
						</div>
						<div className='form-group '>
							<div className='use-trans-label'>
								<input
									id='subject'
									type='text'
									name='subject'
									value={subject}
									onChange={e => handleOnChange(e)}
									onBlur={labelTransNotEmpty}
									maxLength='40'
									list='suggest-subject'
								/>
								<label htmlFor='subject'>Subject or topic</label>
							</div>
							<datalist id='suggest-subject'>
								{SUGGEST_SUBJECTS.map(subject => (
									<option value={subject} />
								))}
							</datalist>
						</div>
					</Fragment>
				)}

				{/* <input
          id='submit-censor'
          type='submit'
          className={`m-1 btn btn-big btn-${
            validForm ? 'primary' : 'white'
          }`}
          value='Submit'
          onMouseEnter={() => setOverSubmit(true)}
          onMouseLeave={() => setOverSubmit(false)}
        /> */}
				<input
					id='submit-censor'
					// className='invisible'
					type='submit'
					style={{ display: 'none' }}
				/>
				<button
					htmlFor='submit-censor'
					className={`submit-btn m-1 btn btn-big btn-${
						validForm ? 'primary' : 'white'
					}`}
					type='submit'
					onMouseEnter={() => setOverSubmit(true)}
					onMouseLeave={() => setOverSubmit(false)}>
					<span>Submit</span>
				</button>
				{/* <Link className='btn btn-light my-1' to='/home'>
          Go Back
        </Link> */}
			</form>
		</Fragment>
	);
};

AddCensorEvent.propTypes = {
	addCensorEvent: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired,
	findByUsername: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	// const { profile, alert } = state;
	// const { profile } = state;
	auth: state.auth,
});

export default connect(mapStateToProps, {
	addCensorEvent,
	setAlert,
	findByUsername,
})(withRouter(AddCensorEvent));
