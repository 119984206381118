import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { _handleReminder, _rmReminder } from '../common/_reminder.js';
import { labelTransNotEmpty } from '../profile-forms/_profileFormUtilities';
import Fuse from 'fuse.js';
import { country_arr, s_a as state_arr } from '../common/countries';

const CountrySelect = ({
	// auth: { user },
	setFormData,
	formData,
	isEdit,
}) => {
	const [generatedRegions, setGeneratedRegions] = useState([]);

	const genPopulateState = (
		state_arr,
		setGeneratedRegions
	) => _country_index => {
		const _statesOfCountry_arr = !_country_index
			? []
			: state_arr[_country_index].split('|');
		setGeneratedRegions(_statesOfCountry_arr);
	};

	const populateState = genPopulateState(state_arr, setGeneratedRegions);

	const { country, state } = formData;

	useEffect(() => {
		const label = document.getElementById('state-label');
		label && label.classList.remove('label-not-empty');
	}, [country]);

	const [validForm, setValidForm] = useState(false);

	useEffect(() => {
		setValidForm(
			![...document.querySelectorAll('input')].filter(f => !f.checkValidity())
				.length
		);
	}, [formData]);

	return (
		<div className='form-group '>
			<small className='form-text my-2'>Location</small>
			<div className='use-trans-label'>
				<input
					type='text'
					id='country'
					name='country'
					value={country}
					onChange={e => {
						const { value, name } = e.target;
						const state = '';
						setFormData({ ...formData, [name]: value, state });
					}}
					onBlur={labelTransNotEmpty}
					list='suggest-country'
				/>

				{/* <label htmlFor='country' className={isEdit && 'hide'}> */}
				<label htmlFor='country'>
					<span className='red'>*</span> Country
				</label>
				<datalist id='suggest-country'>
					{country_arr.map((country, i) => (
						<option key={`${country}-${i}`} value={country}>
							{country}
						</option>
					))}
				</datalist>
			</div>

			{(!!generatedRegions.length || country) && (
				<Fragment>
					<div
						className='use-trans-label'
						style={{ margin: '1.2rem 0 0 1rem' }}>
						<input
							type='text'
							id='state'
							name='state'
							value={state}
							onFocus={() => {
								if (country) {
									console.log('LINE: 49 populate state', country);
									const options = {
										// isCaseSensitive: false,
										// shouldSort: true,
										// includeMatches: false,
										// findAllMatches: false,
										// minMatchCharLength: 1,
										// location: 0,
										threshold: 0.3, // 0.6
										distance: 10, // 100
										// useExtendedSearch: false,
										// ignoreLocation: false,
										// ignoreFieldNorm: false,
										includeScore: true,
									};

									const fuse = new Fuse(country_arr, options);
									const fuseData = fuse.search(country);
									console.log('LINE: 56 fuseData ', fuseData);
									if (fuseData.length) {
										const _country_index = fuseData[0].refIndex + 1;

										console.log('LINE: 57 _country_index', _country_index);

										populateState(_country_index);
									} else {
										console.log('LINE: 53 reset regions');
										setGeneratedRegions([]);
									}
								}
							}}
							onChange={e => {
								const { value, name } = e.target;
								setFormData({ ...formData, [name]: value });
							}}
							onBlur={labelTransNotEmpty}
							list='suggest-region'
						/>
						<label
							htmlFor='state'
							id='state-label'
							// className={isEdit && country && state && 'hide'}
						>
							State/Region
						</label>
						<datalist id='suggest-region'>
							{generatedRegions.map((state, i) => (
								<option key={`${state}-${i}`} value={state}>
									{state}
								</option>
							))}
						</datalist>
					</div>
				</Fragment>
			)}
		</div>
	);
};

CountrySelect.propTypes = {
	onChange: PropTypes.func.isRequired,
	setFormData: PropTypes.func.isRequired,
	formData: PropTypes.object.isRequired,
	populateState: PropTypes.func.isRequired,
	generatedRegions: PropTypes.array.isRequired,
	country_arr: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
	//profile: state.profile,
	auth: state.auth,
});

export default connect(mapStateToProps, {})(withRouter(CountrySelect));
