import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getTrendingHashtags } from '../../actions/common';
import { INTERVAL_UPDATE_TIME } from '../common/_constants';
import Spinner from '../layout/Spinner';

const TrendingHashtags = ({ trending, getTrendingHashtags }) => {
	useEffect(() => {
		//console.log('LINE: 10', 'HASHTAGS USEEFFECT ');
		if (trending.hashtags.loading) {
			getTrendingHashtags();

			const intervalId = setInterval(() => {
				//console.log('LINE: 14', 'updating trending hashtags');
				getTrendingHashtags();
			}, INTERVAL_UPDATE_TIME);
			return () => clearInterval(intervalId);
		}
	}, []);

	return (
		<div className='trending-cont' id='trending-hashtags'>
			<h3 className='text-primary'>Trending Hashtags</h3>
			{trending.hashtags.loading ? (
				<Spinner />
			) : (
				<div className='trending-items-cont'>
					{trending.hashtags.values.map(([key, value], idx) => (
						<Link onClick={e => e.stopPropagation()} to={`/hashtag/${key}`}>
							{idx + 1}. #{key}
						</Link>
					))}
				</div>
			)}
		</div>
	);
};

TrendingHashtags.propTypes = {};

const mapStateToProps = ({ trending }) => ({ trending });

export default connect(mapStateToProps, { getTrendingHashtags })(
	withRouter(TrendingHashtags)
);
