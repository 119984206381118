import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import {
	getQuotePosts,
	appendQuotePosts,
	clearPosts,
	setLoadMorePosts,
} from '../../actions/posts';
import {} from '../../actions/common';
import { PostImg, ImgModal } from '../post/PostImg';
import {
	dropdownHandler,
	queryStringToObject,
	objStrToNum,
} from '../common/_utilities';
import { DATE_SORT } from '../common/_shared_constants';
import { _handleReminder, _rmReminder } from '../common/_reminder.js';
import Posts from '../posts/Posts';
import DropdownOptions from '../common/DropdownOptions';

const QuotedPosts = ({
	posts,
	getQuotePosts,
	appendQuotePosts,
	setLoadMorePosts,
	clearPosts,
	history,
	match,
}) => {
	const { loading } = posts;
	const location = useLocation();
	const [query] = useState(queryStringToObject(location.search));

	const { id } = match.params;

	useEffect(() => {
		getQuotePosts(id, objStrToNum(query));
	}, [query]);

	const [selectionVal, setSelectionVal] = useState('');

	useEffect(() => {
		const currentSelection = DATE_SORT.options.filter(
			({ value }) => value === query[DATE_SORT.key]
		);

		const { value } = currentSelection.length
			? currentSelection[0]
			: DATE_SORT.default;

		setSelectionVal(value);
	}, [query]);

	return (
		<Fragment>
			<DropdownOptions
				{...{
					columns: [
						{
							label: 'UPLOAD DATE',
							onClick: ({ e, value }) => {
								e.stopPropagation();
								clearPosts();
								history.push(
									`${window.location.pathname}?${new URLSearchParams({
										[DATE_SORT.key]: value,
									})}`
								);
							},
							options: DATE_SORT.options,
							selectionVal,
						},
					],
				}}
			/>
			<Posts
				posts={posts}
				getPostsAction={(id => query => appendQuotePosts(id, query))(id)}
				postsComponent='quotePosts'
				setLoadMore={setLoadMorePosts}
			/>
		</Fragment>
	);
};

QuotedPosts.propTypes = {
	getQuotePosts: PropTypes.func.isRequired,
	appendQuotePosts: PropTypes.func.isRequired,
	setLoadMorePosts: PropTypes.func.isRequired,
	post: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	auth: state.auth,
	posts: state.posts,
	common: state.common,
});

export default connect(mapStateToProps, {
	getQuotePosts,
	appendQuotePosts,
	setLoadMorePosts,
	clearPosts,
})(withRouter(QuotedPosts));
