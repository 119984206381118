import React, { Fragment, useState, useEffect } from 'react';
// import { Link, withRouter } from 'react-router-dom';
import { Redirect, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addActive } from '../../actions/authProfile';
import { _handleReminder, _rmReminder } from '../common/_reminder.js';
import {
	checkAndTransLabels,
	labelTransNotEmpty,
} from './_profileFormUtilities';
import { transTextareaFocus, transTextareaBlur } from '../common/_utilities';
import { TLD_REGEX } from '../common/_constants';

const AddActive = ({
	auth: { user },
	addActive,
	history,
	// profile: { profile, loading },
}) => {
	console.log('LINE: 21 user ', user);
	const [validForm, setValidForm] = useState(false);

	const [overSubmit, setOverSubmit] = useState(false);

	const [primaryDisabled, togglePrimary] = useState(false);

	const [formData, setFormData] = useState({
		platform: '',
		name: '',
		handle: '',
		url: '',
		primary: primaryDisabled,
		info: '',
	});

	useEffect(() => {
		const { name } = user;
		console.log('LINE: 39 name ', name);
		setFormData({ ...formData, name });
	}, [user]);

	const { platform, name, handle, url, primary, info } = formData;

	const onChange = e =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = e => {
		e.preventDefault();
		addActive(formData, history);
	};

	const primaryLimit = user.activeAccts.filter(i => i.primary).length >= 4;

	useEffect(() => {
		checkAndTransLabels({ formData });
	}, [formData]);

	return !user.profile ? (
		<Redirect to='/home' />
	) : (
		<Fragment>
			<div className='m-1'>
				<div>
					<span className='red'>*</span>
					<small> = required field</small>
				</div>
				<div>
					<span className='primary'>*</span>
					<small> = at least one required</small>
				</div>
			</div>{' '}
			<form
				id='add-active-acct-form'
				className='form '
				onSubmit={e => onSubmit(e)}
				onKeyUp={e =>
					setValidForm(
						![...e.currentTarget.querySelectorAll('input')].filter(
							f => !f.checkValidity()
						).length
					)
				}>
				<div className='form-group '>
					<div className='use-trans-label'>
						<input
							type='text'
							name='platform'
							value={platform}
							onChange={e => onChange(e)}
							onBlur={labelTransNotEmpty}
							maxLength='26'
							required
						/>
						<label htmlFor=''>
							<span className='red'>*</span> Platform
						</label>
					</div>
				</div>

				<div className='form-group '>
					<div className='use-trans-label'>
						<input
							// {/* type='url' */}
							type='text'
							name='url'
							value={url}
							onChange={e => onChange(e)}
							onBlur={labelTransNotEmpty}
							// {/* pattern={TLD_REGEX} */}
							required
						/>
						<label htmlFor=''>
							<span className='red'>*</span> Account URL
						</label>
					</div>
				</div>

				<div className='form-group '>
					<div className='use-trans-label'>
						<input
							type='text'
							name='name'
							id='name'
							value={name}
							onChange={e => onChange(e)}
							onBlur={labelTransNotEmpty}
							list='suggest-name'
							maxLength='26'
						/>
						<label htmlFor='name'>
							<span className='primary'>*</span> Name
						</label>
					</div>
					<datalist id='suggest-name'>
						<option value={user && user.name}></option>
					</datalist>
				</div>

				<div className='form-group '>
					<div className='use-trans-label'>
						<input
							type='text'
							name='handle'
							value={handle}
							onChange={e => onChange(e)}
							onBlur={labelTransNotEmpty}
							maxLength='26'
						/>
						<label htmlFor=''>
							<span className='primary'>*</span> Handle or username
						</label>
					</div>
				</div>

				<div className='form-group'>
					<small className='form-text'>
						Set as one of your primary accounts.
					</small>
					<label
						className='single-checkbox'
						onMouseEnter={e =>
							primaryLimit &&
							_handleReminder({ e, msg: 'Limit of 4 primary accounts' })
						}
						onMouseLeave={_rmReminder}>
						Primary
						<input
							disabled={primaryLimit}
							type='checkbox'
							name='primary'
							checked={primary}
							value={primary}
							onChange={e => {
								setFormData({ ...formData, primary: !primary });
								togglePrimary(!primaryDisabled);
							}}
						/>
						<span className='checkmark'></span>
					</label>
					<div className='use-trans-label ' style={{ margin: '2rem .2rem' }}>
						<textarea
							name='info'
							value={info}
							onChange={e => onChange(e)}
							onFocus={transTextareaFocus}
							onBlur={e =>
								!labelTransNotEmpty(e) && transTextareaBlur(e, overSubmit)
							}
							cols='30'
							maxLength='500'
						/>
						<label htmlFor=''>Additional info</label>
					</div>
				</div>
				<input type='submit' id='submit-active' style={{ display: 'none' }} />
				<button
					htmlFor='submit-active'
					className={`submit-btn m-1 btn btn-big btn-${
						validForm ? 'primary' : 'white'
					}`}
					onMouseEnter={() => setOverSubmit(true)}
					onMouseLeave={() => setOverSubmit(false)}>
					<span>Submit</span>
				</button>
				{/* <Link className='btn btn-light my-1' to='/home'> */}
				{/* Go Back */}
				{/* </Link> */}
			</form>
		</Fragment>
	);
};

AddActive.propTypes = {
	addActive: PropTypes.func.isRequired,
	// actives: PropTypes.array.isRequired
	// profile: PropTypes.object.isRequired,
};

const mapStateToProps = ({ auth }) => ({
	auth,
});

export default connect(mapStateToProps, { addActive })(withRouter(AddActive));
