import React, { useState, Fragment as Frag } from 'react';
import { _handleReminder, _rmReminder } from '../common/_reminder.js';
import Moment from 'react-moment';

export const CensorPostData = ({
	post: {
		personalAcct,
		reach,
		handle,
		acctName,
		income,
		incomeInfo,
		subject,
		platform,
		formOfCensor,
		citationDate,
	},
}) => {
	const [expandData, setExpandData] = useState(false);
	return (
		<div className='censor-post-data'>
			<span onClick={e => !e.stopPropagation() && setExpandData(!expandData)}>
				<span
					onMouseEnter={e =>
						_handleReminder({ e, _class: 'my-2', msg: 'Data' })
					}
					onMouseLeave={_rmReminder}>
					{expandData ? (
						<i className='fas fa-chevron-circle-up'></i>
					) : (
						<i className='fas fa-chevron-circle-down'></i>
					)}
				</span>
				{'  '}
				<span
					className='platform'
					onMouseEnter={e =>
						_handleReminder({ e, _class: 'my-2', msg: 'Platform' })
					}
					onMouseLeave={_rmReminder}>
					{platform}
				</span>{' '}
				·{' '}
				<span
					onMouseEnter={e =>
						_handleReminder({ e, _class: 'my-2', msg: 'Form of censorship' })
					}
					onMouseLeave={_rmReminder}>
					{formOfCensor}
				</span>{' '}
			</span>
			{expandData && (
				// {true && (
				<Frag>
					<div className='censor-data'>
						<h2>Censorship data</h2>
						<table>
							<tr>
								<th>Personal Account</th>
								<td>{JSON.stringify(personalAcct)}</td>
							</tr>
							{platform && (
								<tr>
									<th>Platform</th>
									<td>{platform}</td>
								</tr>
							)}
							{formOfCensor && (
								<tr>
									<th>Form of censorship</th>
									<td>{formOfCensor}</td>
								</tr>
							)}
							{acctName && (
								<tr>
									<th>Account name</th>
									<td>{acctName}</td>
								</tr>
							)}
							{handle && (
								<tr>
									<th>Handle</th>
									<td>{handle}</td>
								</tr>
							)}

							{/* <td></td> */}
							{citationDate && (
								<tr>
									<th>Date of occurence</th>
									{/* <th>Citation date</th> */}
									<td>
										<Moment format='MMM DD, YYYY'>{citationDate}</Moment>
									</td>
								</tr>
							)}

							{/* ### TODO bug:  reach "null" is a string */}
							{/* {reach !== 'null' && ( */}
							{reach && (
								<tr>
									<th>Reach</th>
									<td>{reach} followers</td>
								</tr>
							)}
							{subject && (
								<tr>
									<th>Citation subject</th>
									<td>{subject}</td>
								</tr>
							)}
							{income && (
								<tr>
									<th>Income</th>
									<td>{income.toString()}</td>
								</tr>
							)}
							{income && incomeInfo && (
								<tr>
									<th>Income info</th>
									<td>{incomeInfo}</td>
								</tr>
							)}
						</table>
					</div>
				</Frag>
			)}
		</div>
	);
};
