import {
	SET_POSTS_INDEX_SKIP,
	COMMON_ERROR,
	SET_COMMON_DATA,
	SET_TRENDING_HASHTAGS,
	SET_TRENDING_CENSOR,
} from '../actions/types';

export function common(
	state = {
		loading: true,
		error: {},
	},
	action
) {
	const { type, payload } = action;
	switch (type) {
		case SET_COMMON_DATA:
			return {
				...state,
				...payload,
				// loading:false
			};
		case COMMON_ERROR:
			return {
				...state,
				error: payload,
				// loading: false,
			};
		default:
			return state;
	}
}

export function trending(
	state = {
		hashtags: {
			loading: true,
			values: [],
		},
		censor: {
			loading: true,
			values: [],
		},
		error: {},
	},
	action
) {
	const { type, payload } = action;
	switch (type) {
		case SET_TRENDING_HASHTAGS:
			return {
				...state,
				hashtags: {
					values: payload,
					loading: false,
				},
			};
		case SET_TRENDING_CENSOR:
			return {
				...state,
				censor: {
					values: payload,
					loading: false,
				},
			};

		case COMMON_ERROR:
			return {
				...state,
				error: payload,
				// loading: false,
			};
		default:
			return state;
	}
}
