import React, { Fragment as F, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { hashtagify, parsePicsUser } from '../common/_utilities.js';
import { PostImg, ImgModal } from './PostImg.js';
import { CensorPostData } from '../posts/CensorPostData.js';
import QuotedPost from '../post/QuotedPost';
import { followToggle } from '../../actions/profile';
import useUserSummary from '../common/useUserSummary';

const MainContent = ({ post: { post }, history }) => {
	const { user, name, img, tags, postType, quotedPost } = post;

	const [modalData, setModalData] = useState({ _id: '', src: {}, text: '' });

	const [UserSummary, handleUserSummary] = useUserSummary({
		post,
		followToggle,
		// user,
	});

	return (
		<F>
			<div className={`thread-item-cont`} id='main-content'>
				<div className='pic-thread'>
					<div
						className='profile-pic-cont img-cont'
						onClick={e =>
							!e.stopPropagation() && history.push(`/profile/${user.username}`)
						}
						{...handleUserSummary(
							window.matchMedia('(max-width: 700px)').matches
								? {
										top: '0.3rem',
										left: '1rem',
								  }
								: {
										top: '0.8rem',
										left: '-3.8rem',
								  }
						)}>
						<img className='br-50' src={parsePicsUser(post.user)} alt='avatar' />
					</div>
					<UserSummary />
				</div>
				<div
					className={`main-name`}
					{...handleUserSummary({
						top: '-2rem',
						left: '5rem',
					})}>
					<b
						onClick={e =>
							!e.stopPropagation() && history.push(`/profile/${user.username}`)
						}>
						{name}
					</b>
					<p className='lite-txt'>@{user.username}</p>
				</div>
			</div>

			<div className='text-cont'>
				{!!tags.length && (
					<div id='replying-to' className='secondary-color'>
						Replying to&nbsp;&nbsp;
						{tags.map(t => (
							<Link to={`/profile/${t}`} activeClassName='current-route'>
								@{t}&nbsp;&nbsp;
							</Link>
						))}
					</div>
				)}
				<p>{hashtagify(post.text, history)}</p>
			</div>
			{modalData._id && <ImgModal {...{ modalData, setModalData }} />}
			{img && <PostImg {...{ post, setModalData }} />}
			{'CensorEvent' === postType && <CensorPostData post={post} />}
			{quotedPost && <QuotedPost {...{ post: quotedPost }} />}
		</F>
	);
};

MainContent.propTypes = {
	post: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	post: state.post,
});

export default connect(mapStateToProps, { followToggle })(
	withRouter(MainContent)
);
