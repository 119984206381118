// import './App.css';
// import './css/style.min.css';
import './css/style.css';
import React, { useLayoutEffect } from 'react';
import { BrowserRouter as Router, Route, withRouter } from 'react-router-dom';

import Routes from './components/routing/Routes';
import Alert from './components/layout/Alert';
import { Provider } from 'react-redux';
import store from './store';

// do I need this? multiple calls
// if (localStorage.token) {
//   setAuthToken(localStorage.token);
// }

const ScrollToTop = withRouter(({ children, location: { pathname } }) => {
	useLayoutEffect(() => {
		window.scrollTo(0, 0);
	}, [pathname]);

	return children || null;
});

const App = () => {
	return (
		<Provider store={store}>
			<Router>
				<ScrollToTop>
					<Route component={Routes} />
					<Alert />
				</ScrollToTop>
			</Router>
		</Provider>
	);
};

export default App;
