import { KEEP_LIMIT, PULL_LIMIT } from '../components/common/_constants';
import { LOAD_LIMIT } from '../components/common/_shared_constants';
import { hasExistence } from '../components/common/_utilities';

import {
	GET_COMMENTS,
	APPEND_COMMENTS,
	GET_POST,
	POST_ERROR,
	UPDATE_MAIN,
	DELETE_MAIN,
	ADD_COMMENT,
	CLEAR_POST,
	GET_THREAD,
	CLEAR_THREAD,
	COMMENT_ON,
	CLEAR_COMMENT_ON,
	THREAD_ERROR,
	DELETE_POSTERIOR,
	DELETE_THREAD,
	QUOTE_POST,
	CLEAR_QUOTE_POST,
	POSTS_COMMENTS,
	UPDATE_POSTS_USER_FOLLOW,
	REMOVE_POST,
	GET_POSTERIOR,
	UPDATE_POSTS,
	SET_LOAD_MORE_COMMENTS,
} from '../actions/types';

// const initialState = {
// 	posts: [],
// 	loading: true,
// 	topPlatforms: [],
// 	skipIndex: 0,
// 	scrollY: 0,
// 	noMore: false,
// 	query:{},
// 	error: {},
// };

// not being used
// const _append_posts = ({ state, payload }) => ({
// 	...state,
// 	posts: payload,
// 	loading: false,
// 	noMore: payload.length < LOAD_LIMIT,
// });

const _prepend_posts = ({ state, payload }) => {
	console.log('LINE2: 25  payload ', payload);
	console.log('LINE2: 25  state', state);
	console.log('LINE2: 27  !!payload.length', !!payload.length);
	if (!payload.length) return state;
	const total = state.posts.length + payload.length;
	const toCut = total > KEEP_LIMIT ? -10 : 0;

	return {
		...state,
		posts: [
			...payload,
			...(toCut < 0 ? state.posts.slice(0, toCut) : state.posts),
		],
		prependAlert: !!payload.length,
		loading: false,
	};
};

export const _clear_prepend_alert = ({ state }) => ({
	...state,
	prependAlert: false,
});

const _get_posts = ({ state, payload }) => {
	return {
		...state,
		posts: payload,
		skipIndex: state.skipIndex + payload.length,
		firstIndexDate: payload.length && payload[0].date,
		noMore: payload.length < LOAD_LIMIT,
		loading: false,
	};
};

const _append_posts = ({ state, payload }) => {
	const total = state.posts.length + payload.length;
	const toCut = total > KEEP_LIMIT ? PULL_LIMIT : 0;
	const updated = [...state.posts.slice(toCut), ...payload];

	console.log('reducers/_posts_common.js: 56  updated.length ', updated.length);
	return {
		...state,
		posts: updated,
		skipIndex: state.skipIndex + payload.length,
		firstIndexDate: updated.length && updated[0].date,
		noMore: payload.length < LOAD_LIMIT,
		loadMore: false,
		loading: false,
	};
};

const _update_posts = ({ state, payload }) => {
	// console.log('LINE2: 10  payload ', payload);
	return {
		...state,
		posts: state.posts.map(post => {
			const isARecycle = !!post.recycle;
			return isARecycle
				? post.recycle._id === payload._id
					? { ...post, recycle: payload }
					: post
				: post._id === payload._id
				? payload
				: post;
		}),
	};
};

// const _clear_posts = ({ state, payload }) => ({
// 	...state,
// 	posts: [],
// 	noMore: false,
// 	loading: true,
// });
const _clear_posts = initialState => initialState;
// const _clear_posts = () => initialState;

const _add_post = ({ state, payload }) => {
	// console.log('LINE2: 42  payload ', payload);
	return {
		...state,
		posts: [payload, ...state.posts],
	};
};

const _remove_post = ({ state, payload }) => ({
	...state,
	posts: state.posts.filter(p => p._id !== payload._id),
});

const _set_load_more = ({ state }) => ({
	...state,
	loadMore: true,
});

const _set_scroll_y = ({ state, payload }) => ({ ...state, scrollY: payload });

const _set_query = ({ state, payload }) => ({
	...state,
	query: payload,
	noMore: false,
	scrollY: 0,
	skipIndex: 0,
});

const _get_top_platforms = ({ state, payload }) => ({
	...state,
	topPlatforms: payload,
});

const _posts_update_comments = ({ state, payload }) => ({
	...state,
	posts: state.posts.map(p =>
		p._id === payload.parent
			? { ...p, v_comments_count: p.v_comments_count + 1 }
			: p
	),
});

const _update_posts_user_follow = ({ state, payload }) => ({
	...state,
	posts: state.posts.map(p =>
		payload.user_id === p.user._id
			? {
					...p,
					user: {
						...p.user,
						follows: payload,

						// {
						// 	...p.user.follows,
						// 	isFollowing: payload.isFollowing,
						// 	// followerCount: hasExistence(payload,'followerCount')?payload.followerCount:p.user.follows.followerCount
						// 	// followerCount: Number.isInteger(payload.followerCount) ? payload.followerCount: p.user.follows.followerCount,
						// 	followerCount: payload.followerCount ?? p.user.follows.followerCount,
						// },
					},
			  }
			: p
	),
});

const _posts_common_actions_switch = ({ state, payload, type }) => {
	switch (type) {
		case UPDATE_POSTS:
			return _update_posts({ state, payload });

		case UPDATE_POSTS_USER_FOLLOW:
			return _update_posts_user_follow({ state, payload });

		case POSTS_COMMENTS:
			return _posts_update_comments({ state, payload });

		case REMOVE_POST:
			return _remove_post({ state, payload });

		default:
			return state;
	}
};

const _posts_error = ({ state, payload }) =>
	!console.log('LINE2: 75  payload ', payload) && {
		...state,
		error: payload,
		loading: false,
	};

export {
	_posts_common_actions_switch,
	_add_post,
	_update_posts,
	_remove_post,
	_prepend_posts,
	_get_posts,
	_append_posts,
	_set_load_more,
	_set_scroll_y,
	_posts_update_comments,
	_get_top_platforms,
	_clear_posts,
	_update_posts_user_follow,
	_set_query,
	_posts_error,
};
