import React from 'react';

const About = ({}) => {
	return (
		<div className='main-component about-page'>
			<h2 className='large text-primary'>Cnsrshp.com</h2>
			<article>
				<p>
					Firstly, <b>Cnsrshp.com</b> is designed to help start a conversation about online
					censorship. Unless we know who and
					what, and for what reason, something is being censored, it's hard to have a conversation about it. Should
					private corporations have the ability to arbitrarily ban people at whim? The set of rules are often vague, and even those who meticulously try to adhere live in constant fear something my happen to their accounts. Determining what is deemed acceptable conversation on the major platforms has enormous influence on society. And for those who come in contact with the ban hammer, what course of action do they have? 
				Begging to a faceless corporate email is their only recourse. People can have years of work ripped from under them in a manner of seconds.
				</p>
				<br />

				<p>
					In addition, <b>Cnsrshp.com</b> also can be used as a tool for content creators. It serves as a notification system for those who have experienced censorship. People can follow those who they follow on other platforms and can be notified of censorship events when they happen
					or when a new account on a different
					platform is created. Visit a user's profile page to subscribe to updates via local site
					notifications and/or email notifications.
				</p>
				<br />

				<p>
					As technology increasingly becomes intertwined with our lives, we need to
					be aware of the implications of this power. By knowing who is being
					censored and for what, we can better understand the nature of online
					censorship.
				</p>
			</article>
			<br />
			<footer>
				To report bugs or constructive criticism, please send an email to{' '}
				<a href='mailto:support@cnsrshp.com'>support@cnsrshp.com</a>
			</footer>
		</div>
	);
};

export default About;
