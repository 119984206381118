import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect, useHistory } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import { register } from '../../actions/auth';
import PropTypes from 'prop-types';
import { labelTransNotEmpty } from '../profile-forms/_profileFormUtilities';
import { EMAIL_REGEX } from '../common/_constants';

const Register = ({ setAlert, register, isAuthenticated }) => {
	const history = useHistory();
	const [validForm, setValidForm] = useState(false);

	const [formData, setFormData] = useState({
		name: '',
		email: '',
		password: '',
		password2: '',
	});

	const { name, email, password, password2 } = formData;

	const onChange = e =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = async e => {
		e.preventDefault();
		if (password !== password2) {
			setAlert({ msg: 'Passwords do not match', alertType: 'danger' });
		} else {
			register(formData, history);
		}
	};

	if (isAuthenticated) {
		return <Redirect to='/home' />;
	}

	return (
		<div className='main-component guest-main-component'>
			<h1 className='large text-primary p-1'>Sign Up</h1>
			<p className='lead m-1'>
				<i className='fas fa-user'></i> Create Your Account
			</p>
			<form
				className='form'
				onSubmit={e => onSubmit(e)}
				onKeyUp={e =>
					setValidForm(
						![...e.currentTarget.querySelectorAll('input')].filter(f =>
							f.type === 'email'
								? !EMAIL_REGEX.test(f.value)
								: !f.checkValidity()
						).length
					)
				}>
				<div className='form-group'>
					<div className='use-trans-label'>
						<input
							type='text'
							name='name'
							value={name}
							onChange={onChange}
							onBlur={labelTransNotEmpty}
							required
							maxLength='30'
						/>
						<label htmlFor='Name'>Name</label>
					</div>
					<div className='use-trans-label myt-2'>
						<input
							type='email'
							name='email'
							value={email}
							onChange={e => onChange(e)}
							onBlur={labelTransNotEmpty}
							maxLength='254'
							required
						/>
						<label htmlFor=''>Email Address</label>
					</div>
				</div>

				<div className='form-group '>
					<div className='use-trans-label'>
						<input
							type='password'
							name='password'
							value={password}
							onChange={e => onChange(e)}
							onBlur={labelTransNotEmpty}
							minLength='6'
							maxLength='50'
							required
						/>
						<label htmlFor=''>Password</label>
					</div>
					<div className='use-trans-label myt-2'>
						<input
							type='password'
							name='password2'
							value={password2}
							onChange={e => onChange(e)}
							onBlur={labelTransNotEmpty}
							minLength='6'
							required
						/>
						<label htmlFor=''>Confirm Password</label>
					</div>
				</div>
				<input type='submit' id='submit-register' style={{ display: 'none' }} />
				<button
					htmlFor='submit-register'
					className={`submit-btn btn m-1 btn-big btn-${
						validForm && password === password2 ? 'primary' : 'white'
					}`}>
					<span>Register</span>
				</button>
			</form>
			<p className='m-1'>
				Already have an account? <Link to='/login'>Sign In</Link>
			</p>
		</div>
	);
};

Register.propTypes = {
	setAlert: PropTypes.func.isRequired,
	register: PropTypes.func.isRequired,
	isAuthenticated: PropTypes.bool,
};

const mapStateToProps = state => ({
	isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps, { setAlert, register })(Register);
