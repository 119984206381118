import {
	CLEAR_PREPEND_EXPLORE_ALERT,
	GET_EXPLORE_POSTS,
	EXPLORE_POSTS_ERROR,
	ADD_EXPLORE_POST,
	CLEAR_EXPLORE_POSTS,
	SET_SCROLL_Y_EXPLORE,
	SET_LOAD_MORE_EXPLORE,
	SET_EXPLORE_QUERY,
	PREPEND_EXPLORE_POSTS,
} from '../actions/types';

import {
	_posts_common_actions_switch,
	_set_scroll_y,
	_prepend_posts,
	_append_posts,
	_clear_posts,
	_add_post,
	_set_load_more,
	_clear_prepend_alert,
	_set_query,
	_posts_error,
} from './_posts_common';

import { INITIAL_POSTS_STATE } from '../components/common/_constants';

export const explorePosts = (state = INITIAL_POSTS_STATE, action) => {
	const { type, payload } = action;

	switch (type) {
		case CLEAR_PREPEND_EXPLORE_ALERT:
			return _clear_prepend_alert({ state });

		case PREPEND_EXPLORE_POSTS:
			return _prepend_posts({ state, payload });

		case GET_EXPLORE_POSTS:
			return _append_posts({ state, payload });

		case CLEAR_EXPLORE_POSTS:
			return _clear_posts(INITIAL_POSTS_STATE);

		case ADD_EXPLORE_POST:
			return _add_post({ state, payload });

		case SET_LOAD_MORE_EXPLORE:
			return _set_load_more({ state, payload });

		case SET_SCROLL_Y_EXPLORE:
			return _set_scroll_y({ state, payload });

		case EXPLORE_POSTS_ERROR:
			return _posts_error({ state, payload });

		case SET_EXPLORE_QUERY:
			return _set_query({ state, payload });
	}

	return _posts_common_actions_switch({ type, state, payload });
};
