import axios from 'axios';
import { setAlert } from './alert';
import {
	GET_USER_PROFILE,
	USER_PROFILE_ERROR,
	GET_PROFILES,
	PROFILES_ERROR,
	UPDATE_SUBSCRIPTIONS,
	SUBSCRIPTIONS_ERROR,
	CLEAR_USER_PROFILE,
	UPDATE_POSTS_USER_FOLLOW,
	UPDATE_USER_PROFILE_FOLLOW,
} from './types';
import util from 'util';
import { loadUser } from './auth';

// Get all profiles
export const getProfiles = () => async dispatch => {
	try {
		const res = await axios.get('/api/profile');

		dispatch({
			type: GET_PROFILES,
			payload: res.data,
		});
	} catch (error) {
		dispatch({
			type: PROFILES_ERROR,
			payload: {
				msg: error.response.statusText,
				status: error.response.status,
			},
		});
	}
};

// Get profile by ID
export const getProfileById = (userId, loggedInUserId) => async dispatch => {
	try {
		const res = await axios.get(`/api/profile/id/${userId}/${loggedInUserId}`);

		dispatch({
			type: GET_USER_PROFILE,
			payload: res.data,
		});

		res.data.msg &&
			dispatch(setAlert({ msg: res.data.msg, alertType: 'danger' }));
	} catch (error) {
		console.log('OUTPUT: error', error);
		dispatch({
			type: USER_PROFILE_ERROR,
			payload: {
				msg: error.response.statusText,
				status: error.response.status,
			},
		});
	}
};

export const getProfileByUsername = (
	username,
	loggedInUserId
) => async dispatch => {
	try {
		const res = await axios.get(
			`/api/profile/username/${username}/${loggedInUserId}`
		);
		dispatch({
			type: GET_USER_PROFILE,
			payload: res.data,
		});

		res.data.msg &&
			dispatch(
				setAlert({ msg: res.data.msg, alertType: 'danger', timeout: 10000 })
			);
	} catch (error) {
		console.log('OUTPUT: error', error);
		dispatch({
			type: USER_PROFILE_ERROR,
			payload: {
				msg: error.response.statusText,
				status: error.response.status,
			},
		});
	}
};

export const clearUserProfile = () => dispatch => {
	dispatch({
		type: CLEAR_USER_PROFILE,
	});
};

// export const followToggle = ({ _id, followerCount }) => async dispatch => {
export const followToggle = data => async dispatch => {
	try {
		const res = await axios.put(`/api/profile/follow-toggle/`, data);

		console.log('actions/profile.js: 102  res', res);
		dispatch(setAlert(res.data));

		const { isFollowing, followerCount } = res.data;
		const payload = { isFollowing, followerCount, user_id: data.user_id };
		dispatch({
			type: UPDATE_USER_PROFILE_FOLLOW,
			payload,
		});

		dispatch({
			type: UPDATE_POSTS_USER_FOLLOW,
			payload,
		});

		return isFollowing; // this is still used for UserItem
	} catch (error) {
		console.log('OUTPUT: error', error);
		const errors = error.response.data.errors;

		if (errors) {
			errors.forEach(({ msg }) =>
				dispatch(setAlert({ msg, alertType: 'danger' }))
			);
		}
	}
};

export const getSubscriptions = ({ _id }) => async dispatch => {
	try {
		const res = await axios.get(`/api/profile/subscriptions/${_id}`);

		dispatch({
			type: UPDATE_SUBSCRIPTIONS,
			payload: res.data,
		});
	} catch (error) {
		console.log('OUTPUT: error', error);
		const errors = error.response.data.errors;
		// if (errors) {
		//   errors.forEach(({ msg }) =>
		//     dispatch(setAlert({ msg, alertType: 'danger' }))
		//   );
		// }
		dispatch({
			type: SUBSCRIPTIONS_ERROR,
			payload: {
				msg: error.response.statusText,
				status: error.response.status,
			},
		});
	}
};

export const subCensorEmail = ({ _id, setTo, firstName }) => async dispatch => {
	try {
		const res = await axios.put(`/api/profile/sub-censor-email/${_id}/`, {
			setTo,
		});

		dispatch({
			type: UPDATE_SUBSCRIPTIONS,
			payload: res.data,
		});

		dispatch(
			!res.data.censorship.email
				? setAlert({
						msg: `You will no longer recieve censorship email updates for ${firstName}.`,
						alertType: 'primary',
				  })
				: setAlert({
						msg: `You will now recieve censorship email updates for ${firstName}.`,
						alertType: 'success',
				  })
		);
	} catch (error) {
		console.log('OUTPUT: error', error);
		dispatch({
			type: SUBSCRIPTIONS_ERROR,
			payload: {
				msg: error.response.statusText,
				status: error.response.status,
			},
		});
	}
};

export const subActiveEmail = ({ _id, setTo, firstName }) => async dispatch => {
	try {
		const res = await axios.put(`/api/profile/sub-active-email/${_id}/`, {
			setTo,
		});

		dispatch({
			type: UPDATE_SUBSCRIPTIONS,
			payload: res.data,
		});

		dispatch(
			!res.data.activeAcct.email
				? setAlert({
						msg: `You will no longer recieve email updates for ${firstName}'s new accounts.`,
						alertType: 'primary',
				  })
				: setAlert({
						msg: `You will recieve email updates for ${firstName}'s  new accounts.`,
						alertType: 'success',
				  })
		);
	} catch (error) {
		console.log('OUTPUT: error', error);
		dispatch({
			type: SUBSCRIPTIONS_ERROR,
			payload: {
				msg: error.response.statusText,
				status: error.response.status,
			},
		});
	}
};

export const subCensorNotification = ({
	_id,
	setTo,
	firstName,
}) => async dispatch => {
	try {
		const res = await axios.put(`/api/profile/sub-censor-notification/${_id}/`, {
			setTo,
		});

		dispatch({
			type: UPDATE_SUBSCRIPTIONS,
			payload: res.data,
		});

		dispatch(
			!res.data.censorship.notification
				? setAlert({
						msg: `You will no longer recieve censorship notifications for ${firstName}.`,
						alertType: 'primary',
				  })
				: setAlert({
						msg: `You will recieve censorship notifications for ${firstName}.`,
						alertType: 'success',
				  })
		);
	} catch (error) {
		console.log('OUTPUT: error', error);
		dispatch({
			type: SUBSCRIPTIONS_ERROR,
			payload: {
				msg: error.response.statusText,
				status: error.response.status,
			},
		});
	}
};

export const subActiveNotification = ({
	_id,
	setTo,
	firstName,
}) => async dispatch => {
	try {
		const res = await axios.put(`/api/profile/sub-active-notification/${_id}/`, {
			setTo,
		});

		dispatch({
			type: UPDATE_SUBSCRIPTIONS,
			payload: res.data,
		});

		dispatch(
			!res.data.activeAcct.notification
				? setAlert({
						msg: `You will no longer recieve notifications for ${firstName}'s new accounts.`,
						alertType: 'primary',
				  })
				: setAlert({
						msg: `You will recieve notifications for ${firstName}'s newly added accounts`,
						alertType: 'success',
				  })
		);
	} catch (error) {
		console.log('OUTPUT: error', error);
		dispatch({
			type: SUBSCRIPTIONS_ERROR,
			payload: {
				msg: error.response.statusText,
				status: error.response.status,
			},
		});
	}
};
