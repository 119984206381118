import React, { useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { _handleReminder, _rmReminder } from '../common/_reminder.js';
import { dropdownHandler } from '../common/_utilities';
import abbreviate from 'number-abbreviate';

const RecycleActions = ({
	auth: { isAuthenticated, user },
	postActionCreators: { recyclePost, unrecyclePost, setQuotePost },
	post,
}) => {
	const history = useHistory();
	const { _id, postType, recyclers } = post;

	const isRecycler =
		isAuthenticated && recyclers.filter(r => r === user.id).length;

	return (
		<span
			onClick={e => {
				e.stopPropagation();
			}}
			className={`recycle action p-1 recycle-options-cont 
				${isAuthenticated && recyclers.includes(user.id) ? 'action-initiated' : ''}
			`}
			onMouseEnter={e => _handleReminder({ e, _class: 'myt-6', msg: 'Recycle' })}
			onMouseLeave={_rmReminder}>
			<div id='recycle-options-cont'>
				<div
					className='drop-parent '
					onClick={e => {
						e.stopPropagation();
						console.log('LINE2: 79 click  ');
						dropdownHandler(e);
					}}>
					<i className='fas fa-retweet' /> &nbsp;
					<ul id='recycle-options' className='selection'>
						<li
							className='drop-option'
							onClick={e => {
								e.stopPropagation();

								isRecycler
									? unrecyclePost({ _id, postType })
									: recyclePost({ _id, postType });
							}}>
							<i className='fas fa-retweet ' /> &nbsp;
							<span className=''>{isRecycler ? 'Unrecycle' : 'Recycle'}</span>
						</li>

						<li
							className='drop-option'
							onClick={e => {
								e.stopPropagation();
								setQuotePost(post);
							}}>
							<span>
								<i className='fas fa-pen' />
								<span>Quote</span>
							</span>
						</li>
					</ul>
				</div>
			</div>
			{/* <span>{recyclers.length > 0 ? recyclers.length : ' '}</span> */}
			<b>{recyclers.length > 0 ? abbreviate(recyclers.length) : ' '}</b>
		</span>
	);
};

const mapStateToProps = state => ({
	auth: state.auth,
});

export default connect(mapStateToProps, {})(RecycleActions);
