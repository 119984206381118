import React, { useMemo, useState, useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import { hasExistence } from '../common/_utilities';

const MainWrapper = ({ Main, header: initHeader, path = null }) => {
	const history = useHistory();
	const location = useLocation();

	const [header, setHeader] = useState(initHeader);

	const [headerScrollClass, setHeaderScrollClass] = useState('');
	useScrollPosition(
		({ prevPos, currPos }) => {
			//console.log('LINE2: 14  currPos ', currPos);
			const scrollDown = currPos.y < prevPos.y;
			setHeaderScrollClass(
				!currPos.y ? '' : scrollDown ? 'header-scroll-down' : 'header-scroll-up'
			);
		},
		[headerScrollClass],
		null,
		false,
		500
	);

	return (
		<div id='center-container' className='main-borders-y main-component'>
			{!!header && (
				<div className={`${headerScrollClass} header`}>
					{header}
					<div
						className='aspect-ratio-bg'
						onClick={e => {
							path !== '/dashboard'
								? history.push('/dashboard', { prevPath: location.pathname })
								: hasExistence(location.state, 'prevPath')
								? history.push(location.state.prevPath)
								: history.goBack();
						}}>
						<div>
							<span className='content'>
								<i
									className={`fas ${path !== '/dashboard' ? 'fa-bars' : 'fa-times'} `}
								/>
							</span>
						</div>
					</div>
				</div>
			)}
			<Main setHeader={setHeader} />
		</div>
	);
};

MainWrapper.propTypes = {
	Main: PropTypes.func.isRequired,
	header: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ common }) => ({
	common,
});

export default connect(mapStateToProps, null)(MainWrapper);
// export default MainWrapper;
