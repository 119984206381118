import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {} from '../../actions/common';
import PropTypes from 'prop-types';
import { withRouter, useLocation } from 'react-router-dom';
import { queryStringToObject } from './_utilities';

const SearchBox = ({ history }) => {
	const { search } = useLocation();

	const [formData, setFormData] = useState();

	useEffect(() => {
		const queryObj = queryStringToObject(search);
		const { text } = queryObj;
		setFormData(text);
	}, [search]);

	return (
		<div id='search-box'>
			<form
				onSubmit={e => {
					e.preventDefault();

					if (formData) {
						const queryString = new URLSearchParams({
							text: formData.trim(),
						}).toString();
						window.scrollTo(0, 0);
						history.push(`/search/query?${queryString}`);
					}
				}}>
				<input
					type='text'
					placeholder='Search'
					value={formData}
					onChange={e => {
						const { value } = e.target;
						setFormData(value);
					}}
				/>
				<input id='submit-search' type='submit' style={{ display: 'none' }} />
				<label htmlFor='submit-search'>
					<i className='fas fa-search' />
				</label>
			</form>
		</div>
	);
};

SearchBox.propTypes = {
	// filterQuery: PropTypes.object.isRequired,
	common: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	common: state.common,
});

export default connect(mapStateToProps, {})(withRouter(SearchBox));
