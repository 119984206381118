import React, { useState, useEffect, Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import {
	hasExistence,
	hashtagify,
	hasNestedExistence,
	parsePicsUser,
} from '../common/_utilities.js';
import abbreviate from 'number-abbreviate';
import uuid from 'uuid';
import { check } from 'express-validator';
import Ellipsis from 'ftellipsis';

import axios from 'axios';

// debug
const useUserSummary = ({
	// user,
	followToggle,
	getUserSummaryFollowData,
	i,
	post,
}) => {
	const { user, isOwner } = post;

	// for dev: strange bug where post.user.follows were not being added. Check
	// redacted and recycles/quotes, then remove post and i
	if (!hasNestedExistence(user, ['follows', 'isFollowing'])) {
		//console.log('common/UserSummary.js: 20  i', i);
		//console.log('common/UserSummary.js: 27  post', post);
		////console.log(
		//			"common/UserSummary.js: 18  hasNestedExistence(user, ['follows', 'isFollowing'])",
		//			hasNestedExistence(user, ['follows', 'isFollowing'])
		//		);
		//console.log('common/UserSummary.js: 19  user', user);
	}

	const { isFollowing = false, followerCount = 0 } =
		hasExistence(user, 'follows') && user.follows;
	const history = useHistory();

	const [activeSummary, setActiveSummary] = useState(false);

	const [checkActiveSummary, toggleCheckActiveSummary] = useState(false);

	useEffect(() => {
		//console.log('LINE2: 13  activeSummary', activeSummary);
		//console.log('LINE2: 14  showSummary ', showSummary);
		//console.log('LINE2: 20  checkActiveSummary', checkActiveSummary);
		if (showSummary && !activeSummary) {
			setShowSummary(false);
			setActiveSummary(false);
		}
	}, [checkActiveSummary]);

	const [showSummary, setShowSummary] = useState(false);

	const [mouseOver, setMouseOver] = useState(false);

	const [summaryStyle, setSummaryStyle] = useState({});

	const [checkIfMouseLeft, toggleCheckIfMouseLeft] = useState(false);

	useEffect(() => {
		if (mouseOver) {
			isFollowing == 'Loading' && getUserSummaryFollowData(user._id);
			setShowSummary(true);
		} else setShowSummary(false);
	}, [checkIfMouseLeft]);

	useEffect(() => {
		showSummary &&
			window.addEventListener(
				'scroll',
				() => {
					setTimeout(() => {
						// setActiveSummary(false);
						!activeSummary && setShowSummary(false);
					}, 500);
				},
				{ once: true }
			);
	}, [activeSummary]); // changed from showSummary to activeSummary does this even do anything now?

	useEffect(() => {
		const bio = document.getElementById('us_bio');
		const ellipsis = new Ellipsis(bio);
		ellipsis.calc();
		ellipsis.set();
	});

	const gotoProfile = {
		onClick: e => {
			//console.log('LINE: 58', 'gotoProfile');
			history.push(`/profile/${user.username}`);
		},
	};

	const UserSummary = () => {
		// const { isFollowing, followerCount } = user.follows;

		// 		useEffect(() => {
		//console.log('common/UserSummary.js: 89  showSummary ', showSummary);
		// 			showSummary &&
		// 				isFollowing == 'Loading' &&
		// 				getUserSummaryFollowData(user._id);
		// 		}, [showSummary]);

		// return hasExistence(user, 'profile') && showSummary ? (
		return showSummary ? (
			<div
				style={{ position: 'relative' }}
				onClick={e => e.stopPropagation()}
				key={uuid.v4()}
				id={uuid.v4()}
				onMouseEnter={e => {
					e.stopPropagation();
					setActiveSummary(true);
				}}
				onMouseLeave={e => {
					//console.log('LINE: 21 onmouse leave');
					setShowSummary(false);
					setActiveSummary(false);
					// toggleCheckActiveSummary(false);
				}}>
				<div className='user_summary' style={summaryStyle}>
					<div className='img-cont'>
						<img
							src={parsePicsUser(user, 'thumb')}
							alt=''
							{...gotoProfile}
							className='br-50'
						/>
					</div>

					<div className='us_content'>
						<div className='us_user'>
							<div>
								<b {...gotoProfile} className='text-link'>
									{user.name}
								</b>
								<span {...gotoProfile} className='text-link'>
									@{user.username}
								</span>
							</div>
						</div>

						<div className='us_bio' id='us_bio'>
							<p>{hashtagify(user.profile.bio, history)}</p>
						</div>
					</div>

					<div className='us_follow'>
						<div>
							<b>{followerCount == '?' ? followerCount : abbreviate(followerCount)}</b>{' '}
							<span>follows</span>
						</div>

						<div
							className={`btn btn-${isFollowing ? 'white' : 'primary'}`}
							// className={`btn btn-${isFollowing ? 'white' : 'primary'}`}
							onClick={e => {
								e.stopPropagation();
								!isOwner &&
									typeof isFollowing === 'boolean' &&
									followToggle({ user_id: user._id, isFollowing, followerCount });
							}}>
							{/* <span>{isFollowing ? 'Unfollow' : 'Follow'}</span> */}
							<span>
								{isFollowing == 'Loading'
									? isFollowing
									: isFollowing
									? 'Unfollow'
									: 'Follow'}
							</span>
						</div>
					</div>
				</div>
			</div>
		) : (
			''
		);
	};

	return [
		UserSummary,
		style => ({
			onMouseOver: e => {
				if (hasExistence(user, 'profile')) {
					setMouseOver(true);

					const midHeight = window.innerHeight / 2;
					const spaceBelow = window.innerHeight - e.clientY;
					setSummaryStyle(
						spaceBelow > midHeight ? style : { ...style, top: '-520px' }
					);
					setActiveSummary(false);
				}
			},
			onMouseOut: () => {
				setMouseOver(false);
			},
			onMouseEnter: () => {
				setTimeout(() => {
					toggleCheckIfMouseLeft(!checkIfMouseLeft);
				}, 500);
			},
			onMouseLeave: () => {
				setTimeout(() => {
					//console.log('LINE: 100', 'onMouseLeave');
					toggleCheckActiveSummary(!checkActiveSummary);
				}, 100);
			},
		}),
	];
};

export default useUserSummary;
