import { KEEP_LIMIT } from '../components/common/_constants';
import {
	GET_HOME_POSTS,
	HOME_POSTS_ERROR,
	ADD_HOME_POST,
	CLEAR_HOME_POSTS,
	SET_SCROLL_Y_HOME,
	SET_LOAD_MORE_HOME,
	PREPEND_HOME_POSTS,
	CLEAR_PREPEND_HOME_ALERT,
} from '../actions/types';

import {
	_posts_common_actions_switch,
	_set_scroll_y,
	_append_posts,
	_clear_posts,
	_add_post,
	_remove_post,
	_set_load_more,
	_clear_prepend_alert,
	_prepend_posts,
	_posts_error,
} from './_posts_common';

const initialState = {
	posts: [],
	loading: true,
	topPlatforms: [],
	skipIndex: 0,
	scrollY: 0,
	error: {},
};

export const homePosts = (state = initialState, action) => {
	const { type, payload } = action;
	switch (type) {
		case CLEAR_PREPEND_HOME_ALERT:
			return _clear_prepend_alert({ state });

		case PREPEND_HOME_POSTS:
			return _prepend_posts({ state, payload });

		case GET_HOME_POSTS:
			return _append_posts({ state, payload });

		case CLEAR_HOME_POSTS:
			return _clear_posts({ state, payload });

		case ADD_HOME_POST:
			return _add_post({ state, payload });

		case SET_LOAD_MORE_HOME:
			return _set_load_more({ state, payload });

		case SET_SCROLL_Y_HOME:
			return _set_scroll_y({ state, payload });

		case HOME_POSTS_ERROR:
			return _posts_error({ state, payload });
	}

	return _posts_common_actions_switch({ type, state, payload });
};
