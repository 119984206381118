import React, { useEffect, useState } from 'react';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';

// TODO: change name of thingLoading
function useDetectBottom({
	thingLoading: { skipIndex, firstIndexDate, noMore, loadMore },
	setScrollY = false,
	setLoadMore = false,
	getAction,
}) {
	useScrollPosition(
		({ prevPos, currPos }) => {
			setScrollY && setScrollY(window.scrollY);
			if (
				window.innerHeight + currPos.y + 100 >= document.body.offsetHeight &&
				!noMore &&
				!loadMore
			) {
				setLoadMore();
				const newQuery = {
					skipIndex,
					firstIndexDate,
				};
				console.log('hooks/useDetectBottom.js: 22  newQuery ', newQuery);

				getAction({
					skipIndex,
					firstIndexDate,
				});
			}
		},
		[loadMore, noMore, skipIndex, firstIndexDate],
		false,
		true,
		300
	);
}

export default useDetectBottom;
