import React, { Fragment as Frag } from 'react';
import { connect } from 'react-redux';
import { _handleReminder, _rmReminder } from '../common/_reminder.js';
import { upvotePost, downvotePost } from '../../actions/posts';
import abbreviate from 'number-abbreviate';

const VotingActions = ({
	auth: { isAuthenticated, user },
	post: { _id, votes, postType },
	upvotePost,
	downvotePost,
	postActionCreators,
}) => {
	return (
		<Frag>
			<span
				onClick={e => {
					e.stopPropagation();
					postActionCreators.upvotePost({ _id, postType });
				}}
				className={`vote action p-1
				${isAuthenticated && votes.upvotes.includes(user.id) ? 'action-initiated' : ''}
				`}
				onMouseEnter={e => _handleReminder({ e, _class: 'myt-6', msg: 'Upvote' })}
				onMouseLeave={_rmReminder}>
				<i className='fas fa-angle-up' />
				&nbsp;
				<b>{votes.upvotes.length > 0 ? abbreviate(votes.upvotes.length) : ' '}</b>
			</span>
			<span
				onClick={e => {
					e.stopPropagation();
					postActionCreators.downvotePost({ _id, postType });
				}}
				className={`vote action p-1
				${
					isAuthenticated && votes.downvotes.includes(user.id)
						? 'action-initiated'
						: ''
				}
				`}
				onMouseEnter={e => _handleReminder({ e, _class: 'myt-6', msg: 'Downvote' })}
				onMouseLeave={_rmReminder}>
				<i className='fas fa-angle-down ' />
				&nbsp;
				<b>
					{votes.downvotes.length > 0 ? abbreviate(votes.downvotes.length) : ' '}
				</b>
			</span>
		</Frag>
	);
};

const mapStateToProps = ({ auth }) => ({ auth });

export default connect(mapStateToProps, {
	upvotePost,
	downvotePost,
})(VotingActions);
