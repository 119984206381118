import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { hashtagify } from '../common/_utilities';

const ProfileAbout = ({
	userProfile: {
		userProfile: {
			name,
			profile: {
				bio,
				// identity: { political, ethnic, religious, dob },
				pcStatus,
			},
		},
	},
}) => {
	const history = useHistory();
	const firstName = typeof name === 'string' ? name.trim().split(' ')[0] : '';
	return (
		(pcStatus.length || bio) && (
			// <div className='profile-about profile-cont p-2 modest-shadow'>
			<div className='profile-about profile-cont p-2 '>
				{bio && (
					<Fragment>
						<h2 className='text-primary'>
							<span>
								{firstName[firstName.length - 1] === 's'
									? `${firstName}' Bio`
									: `${firstName}'s Bio`}
							</span>
						</h2>
						<p>{hashtagify(bio, history)}</p>
						<div className='line'></div>
					</Fragment>
				)}

				{!!pcStatus.length && (
					<Fragment>
						<h2 className='text-primary'>Areas Affected by Censorship</h2>
						<div className='pc-status'>
							{pcStatus
								.filter(pc => pc.checked)
								.map(pc => (
									<span key={pc._id} className='p-1'>
										{/* <i className='fas fa-book-dead fa-2x hide-md'></i>{' '} */}
										{pc.value[0].toUpperCase() + pc.value.slice(1)}
									</span>
								))}
						</div>
					</Fragment>
				)}
			</div>
		)
	);
};
const mapStateToProps = ({ userProfile }) => ({
	userProfile,
});

ProfileAbout.propTypes = {
	userProfile: PropTypes.object.isRequired,
};

export default connect(mapStateToProps)(ProfileAbout);
