import axios from 'axios';
import { setAlert } from './alert';
import {
	REGISTER_SUCCESS,
	REGISTER_FAIL,
	USER_LOADED,
	LOGIN_SUCCESS,
	LOGIN_FAIL,
	LOGOUT,
	FORGOT_SUCCESS,
	FORGOT_FAIL,
	UPASSWORD_SUCCESS,
	UPASSWORD_FAIL,
	ACCOUNT_DELETED,
	AUTH_ERROR,
} from './types';
import setAuthToken from '../utils/setAuthToken';
import ms from 'ms';
// import util from 'util';

// Load User
export const loadUser = () => async dispatch => {
	console.log('LINE2: 25  localStorage', localStorage);
	if (localStorage.token !== undefined) {
		setAuthToken(localStorage.token);
	}

	try {
		const res = await axios.get('/api/auth');
		console.log('OUTPUT: res', res);
		if (res.data) {
			dispatch({
				type: USER_LOADED,
				payload: res.data,
			});
		} else throw new Error('Something went wrong');
	} catch (error) {
		console.log('LINE2: 40  error', error);
		// dispatch({ type: AUTH_ERROR });
		dispatch({
			type: AUTH_ERROR,
			payload: {
				msg: error.response.statusText,
				status: error.response.status,
			},
		});
	}
};

// Register User
export const register = (body, history) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	// const body = JSON.stringify({ name, email, password });

	try {
		const res = await axios.post('/api/users', body, config);

		dispatch(
			setAlert({ msg: res.data.msg, timeout: ms('13m'), alertType: 'success' })
		);

		history.push('/');
	} catch (error) {
		console.log('OUTPUT: error', error);
		const errors = error.response.data.errors;
		if (errors) {
			errors.forEach(({ msg }) =>
				dispatch(setAlert({ msg, alertType: 'danger' }))
			);
		}

		dispatch({
			type: REGISTER_FAIL,
		});
	}
};

// Verify User Email
export const verifyUser = (token, history) => async dispatch => {
	// console.log('LINE2: 75  token ', token);
	try {
		const res = await axios.get(`/api/users/verify-user-email/${token}`);
		// console.log('LINE2: 78  res ', res);
		if (res.status === 206) {
			dispatch(setAlert({ msg: res.data.msg }));
			return { invalidToken: true };
		} else {
			dispatch({
				type: REGISTER_SUCCESS,
				payload: res.data,
			});

			dispatch(loadUser());

			dispatch(
				setAlert({
					msg: 'To get started create a profile.',
					alertType: 'success',
					timeout: 10000,
				})
			);

			history.push('/home');
			return { invalidToken: false };
		}
	} catch (error) {
		console.log('OUTPUT: error', error);
		const errors = error.response.data.errors;
		if (errors) {
			errors.forEach(({ msg }) =>
				dispatch(setAlert({ msg, alertType: 'danger' }))
			);
		}
		dispatch({
			type: REGISTER_FAIL,
		});
	}
};

// Resend verification
export const resendVerification = (body, history) => async dispatch => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		const res = await axios.put(`/api/users/resend-verification`, body, config);

		dispatch(setAlert({ msg: res.data.msg, timeout: ms('3m') }));
		history.push('/');
	} catch (error) {
		if (error.response) {
			const errs = error.response.data.errors;
			if (errs) {
				errs.forEach(({ msg }) =>
					dispatch(setAlert({ msg, alertType: 'danger', timeout: 30000 }))
				);
			}
		}
	}
};

// Login User
export const login = formData => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	// TODO: why did I stringify all these?
	const body = JSON.stringify(formData);

	try {
		const res = await axios.post('/api/auth', body, config);

		dispatch({
			type: LOGIN_SUCCESS,
			payload: res.data,
		});

		dispatch(loadUser());
	} catch (error) {
		// ** recent error
		// loading error: Invariant Violation: Maximum update depth exceeded. This can happen when a component repeatedly calls setState inside componentWillUpdate or componentDidUpdate. React limits the number of nested updates to prevent infinite loops.
		console.log(`loading error: ${error}`);

		const errors = error.response.data.errors;

		if (errors) {
			errors.forEach(({ msg }) =>
				dispatch(setAlert({ msg, alertType: 'danger' }))
			);
		}

		dispatch({
			type: LOGIN_FAIL,
		});
	}
};

export const editEmail = (body, history) => async dispatch => {
	// console.log('LINE2: 130  body ', body);
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	try {
		const { data } = await axios.put('/api/auth/edit-email', body, config);
		// console.log('LINE2: 141  data ', data);

		dispatch(
			setAlert({
				msg: data.msg,
				alertType: 'success',
			})
		);
		dispatch(loadUser());
		// history.push('/home');
		history.push('/dashboard/edit-email');
	} catch (error) {
		console.log('OUTPUT: error', error);
		const errors = error.response.data.errors;
		if (errors) {
			errors.forEach(({ msg }) =>
				dispatch(setAlert({ msg, alertType: 'danger' }))
			);
		}
		// TODO
		//     dispatch({
		//       type: REGISTER_FAIL,
		//     });
	}
};

export const editName = (body, history) => async dispatch => {
	// console.log('LINE2: 130  body ', body);
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	try {
		const { data } = await axios.put('/api/auth/edit-name', body, config);

		dispatch(
			setAlert({
				msg: data.msg,
				alertType: 'success',
			})
		);
		dispatch(loadUser());
	} catch (error) {
		console.log('OUTPUT: error', error);
		const errors = error.response.data.errors;
		if (errors) {
			errors.forEach(({ msg }) =>
				dispatch(setAlert({ msg, alertType: 'danger' }))
			);
		}
		// TODO
		//     dispatch({
		//       type: REGISTER_FAIL,
		//     });
	}
};

// Logout / Clear Profile
export const logout = () => dispatch => {
	console.log('LINE: 253', 'logout');
	dispatch({ type: LOGOUT });
};

// Forgot Password
export const forgotPassword = (email, history) => async dispatch => {
	const config = {
		headers: {
			'Content-Type': 'application/json',
		},
	};

	const body = JSON.stringify({ email });

	try {
		const res = await axios.post('/api/users/forgotPassword', body, config);

		dispatch({
			// is this needed?
			type: FORGOT_SUCCESS,
			payload: res.data,
		});

		dispatch(
			setAlert({
				msg: `${res.data.msg} to ${email}`,
				alertType: 'success',
				timeout: 80000,
			})
		);
		dispatch(
			setAlert({
				msg: `Check your Spam folder if email is not in Inbox`,
				alertType: 'success',
				// timeout: 80000,
			})
		);
		history.push('/');
	} catch (error) {
		if (error.response) {
			const errors = error.response.data.errors;
			if (errors) {
				errors.forEach(({ msg }) =>
					dispatch(setAlert({ msg, alertType: 'danger' }))
				);
			}
		}
		dispatch({
			type: FORGOT_FAIL,
		});
	}
};

// Reset Password
export const resetPassword = ({ token }, history) => async dispatch => {
	try {
		const res = await axios.get(`/api/users/resetPassword/${token}`);
		// console.log('OUTPUT: res', res);

		dispatch(setAlert({ msg: res.data.msg, alertType: 'success' }));
	} catch (error) {
		console.log('OUTPUT: error', error);
		const errors = error.response.data.errors;
		if (errors) {
			errors.forEach(({ msg }) =>
				dispatch(setAlert({ msg, alertType: 'danger', timeout: 30000 }))
			);
		}

		history.push('/');
	}
};

// Update Password (reset)
export const updatePassword = ({
	token,
	password,
	history,
}) => async dispatch => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		const res = await axios.put(
			`/api/users/updatePassword/${token}`,
			JSON.stringify({ password }),
			config
		);
		dispatch({ type: UPASSWORD_SUCCESS, payload: res.data });
		dispatch(loadUser());
		dispatch(setAlert({ msg: `Success: ${res.data.msg}`, alertType: 'success' }));
	} catch (error) {
		if (error.response) {
			const errs = error.response.data.errors;
			if (errs) {
				errs.forEach(({ msg }) =>
					dispatch(setAlert({ msg, alertType: 'danger', timeout: 30000 }))
				);
			}
		}
		dispatch({
			type: UPASSWORD_FAIL,
		});
	}
};

// Delete Account & Profile
export const deleteAcct = (formData, history) => async dispatch => {
	// console.log('LINE2: 291  formData', formData);
	if (window.confirm('Are you sure? This CANNOT be undone!')) {
		try {
			await axios.put('/api/auth/delete-acct', formData, {
				headers: {
					'Content-Type': 'application/json',
				},
			});

			dispatch({ type: ACCOUNT_DELETED });
			history.push('/');

			dispatch(
				setAlert({
					msg: 'Your account has been permanently deleted',
					alertType: 'success',
				})
			);
		} catch (error) {
			dispatch({
				type: AUTH_ERROR,
				payload: {
					msg: error.response.statusText,
					status: error.response.status,
				},
			});
		}
	}
};

export const toggleEmailNotifications = () => async dispatch => {
	try {
		const { data } = await axios.get('/api/auth/toggle-email-notifications');
		console.log('LINE2: 399  data ', data);

		dispatch(
			setAlert({
				msg: data.msg,
				alertType: 'success',
			})
		);

		dispatch(loadUser());
	} catch (error) {
		console.log('OUTPUT: error', error);
		const errors = error.response.data.errors;
		if (errors) {
			errors.forEach(({ msg }) =>
				dispatch(setAlert({ msg, alertType: 'danger' }))
			);
		}
	}
};
