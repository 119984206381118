import React, { useEffect, useState } from 'react';
import { detectCharCodes } from '../../common/_utilities';

// field and id were added to allow bio to use. checkAndTransLabels needs the id
// to be the same otherwise it would be changed to form-textarea
function useTagDetection({
	formData,
	setFormData,
	findByUsername,
	field,
	id = 'form-textarea',
}) {
	const [toSearch, setToSearch] = useState({}),
		[tagSuggestions, setTagSuggestions] = useState([]),
		[tagSelected, setTagSelected] = useState('');

	const handleOnKeyDown = e => {
		if (tagSuggestions.length) {
			if (
				detectCharCodes({
					e,
					charCodes: 40,
				})
			) {
				// downArrow
				const firstTagSuggestion = document.querySelector(
					'#tag-suggestions ul:first-child li:first-child'
				);
				firstTagSuggestion.focus();
			} else if (detectCharCodes({ e, charCodes: [27] })) {
				// escape key
				setTagSuggestions([]);
			} else if (detectCharCodes({ e, charCodes: [9, 13] })) {
				// tab or enter
				e.preventDefault();
				completeTag(e.target, tagSuggestions[0].username);
			}
		}
	};

	const handleOnKeyCapture = e => {
		const charCode = e.which || e.keyCode;
		if (charCode !== 27) {
			// escape key
			tagDetection(e);
		} else if (tagSuggestions.length) setTagSuggestions([]);
	};

	useEffect(() => {
		// if (showPostForm && tagSelected) {
		if (tagSelected) {
			const textarea = document.getElementById('form-textarea');
			const cursorIndex =
				toSearch.cursorIndex + (tagSelected.length - toSearch.input.length + 1);

			textarea.focus();
			textarea.setSelectionRange(cursorIndex, cursorIndex);
			setTagSelected('');
		}
	}, [tagSelected]);

	const handleOnKeyPress = e => {
		const enterKey = detectCharCodes({
			e,
			charCodes: 13,
		});
		enterKey &&
			e.ctrlKey &&
			e.target.closest('form').querySelector('input[type=submit]').click();
	};

	const completeTag = (textarea, toComplete) => {
		const { [field]: text } = formData;
		const { selectionStart } = textarea;
		setToSearch({ ...toSearch, cursorIndex: selectionStart });

		const beforeCursorIndexNoInput = text.substring(
			0,
			selectionStart - toSearch.input.length
		);

		const afterCursorIndex = text.substring(selectionStart);

		setFormData({
			...formData,
			[field]: `${beforeCursorIndexNoInput}${toComplete} ${afterCursorIndex}`,
		});

		setTagSuggestions([]);

		setTagSelected(toComplete);
	};

	const tagDetection = ({ target: { value, selectionStart } }) => {
		const currentWord = value
			.substring(0, selectionStart)
			.split(/\s+/g)
			.slice(-1)[0];

		const atEndOfWord =
			RegExp('^\\s').test(value.substring(selectionStart)) ||
			!value.substring(selectionStart);

		if (currentWord.match(/^(@)[a-z0-9]{1,15}$/i) && atEndOfWord) {
			const toSearch = currentWord.substring(1);
			setToSearch({ input: toSearch });

			(async () => {
				const res = await findByUsername({
					username: toSearch,
				});
				setTagSuggestions(res);
			})();
		} else if (tagSuggestions.length) setTagSuggestions([]);
	};

	return {
		handleOnKeyPress,
		handleOnKeyCapture,
		handleOnKeyDown,
		tagSuggestions,
		completeTag,
	};
}

export default useTagDetection;
