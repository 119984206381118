import React, { useEffect, useState, Fragment } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { parseQuerySelection, queryStringToObject } from '../common/_utilities';
import Posts from '../posts/Posts';
import {
	clearPosts,
	getHashtagPosts,
	appendHashtagPosts,
	setLoadMorePosts,
} from '../../actions/posts';
import { getTopPlatforms } from '../../actions/posts';
import TopPlatforms from '../common/TopPlatforms';
import DropdownOptions from '../common/DropdownOptions';
import FilterDropdown from '../common-post/FilterDropdown';
import qs from 'qs';
import {
	SUBMISSION_DATE,
	POST_TYPE,
	DATE_SORT,
} from '../common/_shared_constants';
import _ from 'lodash';

const Hashtag = ({
	setHeader,
	posts,
	getHashtagPosts,
	appendHashtagPosts,
	setLoadMorePosts,
	clearPosts,
	getTopPlatforms,
	match,
	location,
	history,
}) => {
	const { loading, topPlatforms } = posts;
	const { hashtag } = match.params;

	console.log(
		'LINE2: 33  queryStringToObject(location.search)',
		queryStringToObject(location.search)
	);

	const [query] = useState({
		hashtags: hashtag,
		...queryStringToObject(location.search),
	});

	// 	const [query] = useState({
	// 		hashtags: hashtag,
	// 		...qs.parse(location.search, { ignoreQueryPrefix: true }),
	// 	});

	useEffect(() => {
		if (!hashtag) history.push('/search');
	}, []);

	useEffect(() => {
		console.log('LINE2: 55  query', query);
		getHashtagPosts(query);
		getTopPlatforms(query);
	}, [query]);

	setHeader(
		<h1 className='text-primary large'>
			<i className='fas fa-hashtag' />
			{query.hashtags}
		</h1>
	);

	return (
		<Fragment>
			<TopPlatforms
				{...{
					topPlatforms,
					label: `Top censored platforms for #${hashtag}`,
					query,
				}}
			/>

			<FilterDropdown
				{...{
					query,
					clearPostsAction: clearPosts,
					// active: { submissionDate: true, postType: true, sortBy: true },
					active: { submissionDate: true, postType: true, date: true },
				}}
			/>

			<Posts
				posts={posts}
				getPostsAction={appendHashtagPosts}
				setLoadMore={setLoadMorePosts}
				emptyMessage={
					query.postType
						? `No ${query.postType}s for #${query.hashtags}`
						: ` No results for #${query.hashtags}`
				}
			/>
		</Fragment>
	);
};

Hashtag.propTypes = {
	posts: PropTypes.object.isRequired,
	getHashtagPosts: PropTypes.func.isRequired,
	setLoadMorePosts: PropTypes.func.isRequired,
	setHeader: PropTypes.func,
	match: PropTypes.object.isRequired,
	history: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, posts }) => ({
	auth,
	posts,
});

export default connect(mapStateToProps, {
	getHashtagPosts,
	appendHashtagPosts,
	clearPosts,
	setLoadMorePosts,
	getTopPlatforms,
})(withRouter(Hashtag));
