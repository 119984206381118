import React from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { _handleReminder, _rmReminder } from '../common/_reminder.js';
import PostActions from './PostActions';
import PostContent from '../post/PostContent';
import { connect } from 'react-redux';
import { getUserSummaryFollowData } from '../../actions/posts';
import { followToggle } from '../../actions/profile';
import { parsePicsUser } from '../common/_utilities.js';
import useUserSummary from '../common/useUserSummary';

const ThreadItem = ({
	post,
	history,
	postImg,
	getUserSummaryFollowData,
	followToggle,
	i,
}) => {
	const { _id, user, parent, postType, threadDescendant } = post;

	const [UserSummary, handleUserSummary] = useUserSummary({
		//---- dev remove these from useUserSummary too
		i,
		post,
		// ------
		followToggle,
		getUserSummaryFollowData,
		// user,
	});

	return (
		<div className='post-cont' id={`${!i ? 'thread-start' : ''}`}>
			<div className={`post-thread `}>
				<div
					className='thread-item-cont '
					onClick={() => {
						if (window.getSelection().toString().length === 0) {
							history.push(
								`/posts/${postType === 'BasePost' ? 'Post' : postType}/${_id}`
							);
						}
					}}>
					<div className='pic-thread'>
						{threadDescendant && i > 0 && (
							<div className='thread-connector top-connector' />
						)}
						<div
							className='profile-pic-cont img-cont'
							{...handleUserSummary(
								window.matchMedia('(max-width: 700px)').matches
									? {
											top: '0.3rem',
											left: '-0.75rem',
									  }
									: {
											top: '0.8rem',
											left: '-3.8rem',
									  }
							)}
							onClick={e =>
								!e.stopPropagation() && history.push(`/profile/${post.user._id}`)
							}>
							<img className='br-50' src={parsePicsUser(user, 'thumb')} alt='avatar' />
						</div>
						<UserSummary />
						<div className='thread-connector' />
					</div>
					<div className={` thread-post   ${parent ? 'top-connector' : ''} `}>
						<PostContent
							{...{
								post,
								postImg,
								handleUserSummary: handleUserSummary({
									left: '3.8rem',
									top: '-1rem',
								}),
							}}
						/>

						<PostActions
							{...{
								post,
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

ThreadItem.propTypes = {
	post: PropTypes.object.isRequired,
	recycleThread: PropTypes.func.isRequired,
	unrecycleThread: PropTypes.func.isRequired,
};

export default connect(null, {
	followToggle,
	getUserSummaryFollowData,
})(withRouter(ThreadItem));
