import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import { Link, withRouter } from 'react-router-dom';
import { forgotPassword } from '../../actions/auth';
import PropTypes from 'prop-types';
import { labelTransNotEmpty } from '../profile-forms/_profileFormUtilities';

const ForgotPassword = ({ forgotPassword, history }) => {
	const [validForm, setValidForm] = useState(false);
	const [formData, setFormData] = useState({ email: '' });

	const { email } = formData;

	const onChange = e =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = e => {
		e.preventDefault();
		forgotPassword(email, history);
	};

	return (
		<div className='main-component guest-main-component'>
			<h1 className='large text-primary'>Forgot Password</h1>
			<p className='lead'>
				<i className='fas fa-unlock-alt mx-1'> </i> Reset Password
			</p>
			<form
				className='form'
				onSubmit={e => onSubmit(e)}
				onKeyUp={e =>
					setValidForm(
						![...e.currentTarget.querySelectorAll('input')].filter(
							f => !f.checkValidity()
						).length
					)
				}>
				<div className='form-group'>
					<div className='use-trans-label'>
						<input
							type='email'
							name='email'
							// data-name='words'
							value={email}
							onChange={e => onChange(e)}
							onBlur={labelTransNotEmpty}
							maxLength='254'
							required
						/>
						<label htmlFor='email'>Email Address</label>
					</div>
				</div>

				<input type='submit' id='submit-forgot' style={{ display: 'none' }} />
				<button
					htmlFor='submit-forgot'
					className={`btn m-1 btn-big btn-${validForm ? 'primary' : 'white'}`}>
					<span>Send</span>
				</button>
			</form>
			<p className='m-1'>
				Already have an account? <Link to='/login'>Sign In</Link>
			</p>
		</div>
	);
};

ForgotPassword.propTypes = {
	forgotPassword: PropTypes.func.isRequired,
};

export default connect(null, { forgotPassword })(withRouter(ForgotPassword));
