import React, { useEffect, Fragment, useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import {
	getHomePosts,
	// clearHomePosts,
	setScrollYHome,
	setLoadMoreHome,
	clearPrependHomeAlert,
	prependNewHomePosts,
} from '../../actions/homePosts';
import Posts from '../posts/Posts';
import { hasExistence } from '../common/_utilities';
import PrependAlert from '../common/PrependAlert';
import { INTERVAL_UPDATE_TIME, SCROLL_ALERT_LIMIT } from '../common/_constants';

const Home = ({
	auth: { user, loading, isAuthenticated },
	homePosts,
	getHomePosts,
	clearPrependHomeAlert,
	prependNewHomePosts,
	setLoadMoreHome,
	setScrollYHome,
}) => {
	const { loading: postsLoading } = homePosts;
	const profile = hasExistence(user, 'profile') && { ...user.profile };

	const [localFilter, setLocalFilter] = useState({
		sortBy: -1, //descending (newest)
	});

	useEffect(() => {
		postsLoading && getHomePosts(localFilter);
		// return () => {
		//   clearHomePosts();
		// };
	}, [postsLoading]);

	useEffect(() => {
		homePosts.prependAlert &&
			homePosts.scrollY < SCROLL_ALERT_LIMIT &&
			clearPrependHomeAlert();
	}, [homePosts.scrollY]);

	useEffect(() => {
		const { posts } = homePosts;
		const intervalId =
			posts.length &&
			setInterval(() => {
				// console.log('LINE: 49 HomePosts interval');
				prependNewHomePosts({ firstIndexDate: posts[0].date });
			}, INTERVAL_UPDATE_TIME);
		return () => clearInterval(intervalId);
	}, [homePosts]);

	console.log('LINE2: 59  user', user);
	return loading || user === null ? (
		<Spinner />
	) : (
		<Fragment>
			{!profile ? (
				<div style={{ margin: '0 2rem auto ' }}>
					<p className='lead p-1'>
						<span>
							Welcome <b>{user.firstName}</b>
						</span>
					</p>

					<p className='m-2'>No profile yet. Make one!</p>
					<Link to='/create-profile' className='btn btn-primary m-1'>
						<span>Create Profile</span>
					</Link>
				</div>
			) : (
				<Fragment>
					{homePosts.prependAlert && homePosts.scrollY > SCROLL_ALERT_LIMIT && (
						<PrependAlert
							{...{
								msg: `New Post`,
								clear: clearPrependHomeAlert,
							}}
						/>
					)}
					<Posts
						posts={homePosts}
						getPostsAction={getHomePosts}
						setLoadMore={setLoadMoreHome}
						setScrollY={setScrollYHome}
						emptyMessage='Follow someone to receive updates here'
					/>
				</Fragment>
			)}
		</Fragment>
	);
};

Home.propTypes = {
	auth: PropTypes.object.isRequired,
	profile: PropTypes.object.isRequired,
	setLoadMoreHome: PropTypes.func.isRequired,
	getHomePosts: PropTypes.func.isRequired,
	prependNewHomePosts: PropTypes.func.isRequired,
	clearPrependHomeAlert: PropTypes.func.isRequired,
	setScrollYHome: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, profile, homePosts }) => ({
	auth,
	profile,
	homePosts,
});

export default connect(mapStateToProps, {
	getHomePosts,
	// clearHomePosts,
	setLoadMoreHome,
	setScrollYHome,
	clearPrependHomeAlert,
	prependNewHomePosts,
})(withRouter(Home));
