import axios from 'axios';
import { setAlert } from './alert';
import {
	GET_PROFILE,
	UPDATE_ACTIVE_ACCT,
	DELETE_ACTIVE_ACCT,
	DELETE_AUTH_CENSOR,
	AUTH_PROFILE_ERROR,
} from './types';
import { loadUser } from './auth';

// Create or update profile
export const createProfile = (
	formDataJSON,
	history,
	edit = false
) => async dispatch => {
	try {
		console.log('LINE2: 57  formDataJSON', formDataJSON);
		const formData = new FormData();
		// for (let key in formDataJSON) {
		//   if (key !== 'pcStatus') formData.append(key, formDataJSON[key]);
		// }
		// formData.append('pcStatus', JSON.stringify(formDataJSON.pcStatus));
		// TODO why is this needed?  config needs to be created is this why?
		for (let key in formDataJSON) {
			if (!Array.isArray(formDataJSON[key])) {
				formData.append(key, formDataJSON[key]);
			} else formData.append(key, JSON.stringify(formDataJSON[key]));
		}

		const res = await axios.post('/api/auth-profile', formData);

		console.log('OUTPUT: res', res);

		// TODO: this is called twice in reducer. and change the name
		dispatch({
			type: GET_PROFILE,
			payload: res.data,
		});

		// if (formDataJSON.imgFile) // should check if pic is updated or not then loadUser
		dispatch(loadUser());

		dispatch(
			setAlert({
				msg: edit ? 'Profile Updated' : 'Profile Created',
				alertType: 'success',
			})
		);

		history.push('/home');
	} catch (error) {
		console.log('LINE: 102', error);
		const errors = error.response.data.errors;
		if (errors) {
			console.log('LINE: 104', errors);
			errors.forEach(({ msg }) =>
				dispatch(setAlert({ msg, alertType: 'danger' }))
			);
		}
		//now that profile uses authProfile, this resets the form on incomplete form
		//dispatch({
		//type: AUTH_PROFILE_ERROR,
		//payload: {
		//msg: error.response.statusText,
		//status: error.response.status,
		//},
		//});
	}
};

// Create Demographic Info
export const demographicInfo = (
	formData,
	history
	// edit = true
) => async dispatch => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		const res = await axios.put('/api/auth-profile/demography', formData, config);

		dispatch(loadUser());

		dispatch(
			setAlert({
				msg: res.data.msg,
				alertType: 'success',
			})
		);

		history.push('/home');
	} catch (error) {
		const errors = error.response.data.errors;

		if (errors) {
			errors.forEach(({ msg }) =>
				dispatch(setAlert({ msg, alertType: 'danger' }))
			);
		}

		// causes from to reset; same as profiles
		// dispatch({
		//   type: AUTH_PROFILE_ERROR,
		//   payload: {
		//     msg: error.response.statusText,
		//     status: error.response.status,
		//   },
		// });
	}
};

// Add Active Account
export const addActive = (formData, history) => async dispatch => {
	try {
		const config = {
			headers: {
				'Content-Type': 'application/json',
			},
		};

		console.log('LINE: 187 formData', formData);

		const res = await axios.put('/api/auth-profile/active', formData, config);

		dispatch({
			type: UPDATE_ACTIVE_ACCT,
			payload: res.data,
		});

		// dispatch(setAlert('Active Account Added', 'success'));
		dispatch(setAlert({ msg: 'Active Account Added', alertType: 'success' }));

		history.push('/dashboard');
	} catch (error) {
		const errors = error.response.data.errors;

		if (errors) {
			errors.forEach(({ msg }) =>
				dispatch(setAlert({ msg, alertType: 'danger' }))
			);
		}

		// this causes form to reset
		// need a different solution (also on ProfileForm)
		// dispatch({
		//   type: AUTH_PROFILE_ERROR,
		//   payload: {
		//     msg: error.response.statusText,
		//     status: error.response.status,
		//   },
		// });
	}
};

export const deleteCensorEvent = censor_id => async dispatch => {
	// uses same api route, but different action
	const res = await axios.delete(`/api/posts/${censor_id}`);
	console.log('LINE2: 175  res ', res);

	try {
		dispatch({
			type: DELETE_AUTH_CENSOR,
			payload: res.data.redactedPost._id,
		});

		// need to remove from all posts

		dispatch(setAlert({ msg: 'Censorship Event Removed', alertType: 'success' }));
	} catch (error) {
		console.log('OUTPUT: error', error);
		dispatch({
			type: AUTH_PROFILE_ERROR,
			payload: {
				msg: error.response.statusText,
				status: error.response.status,
			},
		});
	}
};

export const deleteActiveAcct = id => async dispatch => {
	try {
		const res = await axios.delete(`/api/auth-profile/active/${id}`);

		// prompt to email followers

		dispatch({
			type: DELETE_ACTIVE_ACCT,
			payload: id,
		});

		dispatch(setAlert({ msg: res.data.msg, alertType: 'success' }));
	} catch (error) {
		console.log('OUTPUT: error', error);
		dispatch({
			type: AUTH_PROFILE_ERROR,
			payload: {
				msg: error.response.statusText,
				status: error.response.status,
			},
		});
	}
};
