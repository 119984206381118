import React, { useState, useEffect } from 'react';
import { Link, Redirect, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { login } from '../../actions/auth';
import {
	labelTransNotEmpty,
	checkAndTransLabels,
} from '../profile-forms/_profileFormUtilities';
import { hasNestedExistence } from '../common/_utilities';

const Login = ({ auth: { isAuthenticated, user }, login }) => {
	const location = useLocation();
	console.log('LINE2: 14  location ', location);
	const [validForm, setValidForm] = useState(false);

	const [formData, setFormData] = useState({
		userLogin: '',
		password: '',
	});

	const { userLogin, password } = formData;

	const onChange = e =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = e => {
		e.preventDefault();
		login(formData);
	};

	useEffect(() => {
		// This is no longer working (Chromium arp)
		// console.log('OUTPUT: Login -> formData', formData);
		// what is checkValidtity above ^
		checkAndTransLabels({ formData });
	}, [formData]);

	// const { prevPath = false } = location.state;
	// console.log('LINE2: 39  prevPath', prevPath);

	return isAuthenticated && user ? (
		<Redirect
			to={{
				pathname: hasNestedExistence(location, ['state', 'prevPath '])
					? location.state.prevPath
					: '/home',
			}}
		/>
	) : (
		// <Fragment>
		<div className='main-component guest-main-component'>
			<h1 className='large text-primary p-1'>Login</h1>
			<p className='lead'>
				<i className='fas fa-user mx-1'></i> Sign into Your Account
			</p>
			<form
				className='form'
				onSubmit={e => onSubmit(e)}
				onKeyUp={e =>
					setValidForm(
						![...e.currentTarget.querySelectorAll('input')].filter(
							f => !f.checkValidity()
						).length
					)
				}>
				<div className='form-group'>
					<div className='use-trans-label'>
						<input
							id='userLogin'
							type='text'
							name='userLogin'
							value={userLogin}
							onChange={e => onChange(e)}
							onBlur={labelTransNotEmpty}
							required
						/>
						<label htmlFor='userLogin'>Email or username</label>
					</div>

					<div className='use-trans-label myt-2'>
						<input
							id='password'
							type='password'
							name='password'
							value={password}
							onChange={e => onChange(e)}
							onBlur={labelTransNotEmpty}
							minLength='6'
							required
						/>
						<label htmlFor='password'>Password</label>
					</div>
				</div>
				<input type='submit' id='submit-login' style={{ display: 'none' }} />
				<button
					htmlFor='submit-login'
					className={`submit-btn btn m-1 btn-big btn-${
						validForm ? 'primary' : 'white'
					}`}>
					<span>Login</span>
				</button>
			</form>
			<p className='m-1'>
				Don't have an account yet? <Link to='/register'>Create One</Link>
			</p>
			<p className='m-1'>
				<Link to='/forgot'>Forgot Password</Link>
			</p>
			{/* </Fragment> */}
		</div>
	);
};

Login.protoTypes = {
	login: PropTypes.func.isRequired,
	isAuthenticated: PropTypes.bool,
};

const mapStateToProps = state => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { login })(Login);
