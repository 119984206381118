import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import {} from '../../actions/common';
import { queryStringToObject } from '../common/_utilities';
import { _handleReminder, _rmReminder } from '../common/_reminder.js';
import Posts from '../posts/Posts';
import {
	clearExplorePosts,
	getExplorePosts,
	clearPrependExploreAlert,
	prependNewExplorePosts,
	setLoadMoreExplore,
	setScrollYExplore,
	setExploreQuery,
} from '../../actions/explorePosts';
import FilterDropdown from '../common-post/FilterDropdown';
import PrependAlert from '../common/PrependAlert';
import { INTERVAL_UPDATE_TIME, SCROLL_ALERT_LIMIT } from '../common/_constants';

const ExplorePosts = ({
	explorePosts,
	getExplorePosts,
	clearPrependExploreAlert,
	prependNewExplorePosts,
	setLoadMoreExplore,
	setScrollYExplore,
	clearExplorePosts,
	setExploreQuery,
}) => {
	const { loading, query: prevQuery } = explorePosts;
	const location = useLocation();
	const [query] = useState({
		...prevQuery,
		...queryStringToObject(location.search),
	});

	useEffect(() => {
		if (loading) {
			getExplorePosts(query);
		}
	}, [loading]);

	useEffect(() => {
		explorePosts.prependAlert &&
			explorePosts.scrollY < SCROLL_ALERT_LIMIT &&
			clearPrependExploreAlert();
	}, [explorePosts.scrollY]);

	useEffect(() => {
		if (query.sortBy !== 1) {
			const { posts } = explorePosts;
			const intervalId =
				posts.length &&
				setInterval(() => {
					// console.log('LINE: 49 ExplorePosts interval');
					prependNewExplorePosts({ firstIndexDate: posts[0].date, ...query });
				}, INTERVAL_UPDATE_TIME);
			return () => clearInterval(intervalId);
		}
	}, [explorePosts, query]);

	return (
		<Fragment>
			<FilterDropdown
				{...{
					query,
					clearPostsAction: clearExplorePosts,
					setQueryAction: setExploreQuery,
					active: { submissionDate: true, postType: true, sortBy: true },
				}}
			/>
			{explorePosts.prependAlert && explorePosts.scrollY > SCROLL_ALERT_LIMIT && (
				<PrependAlert
					{...{
						msg: `New ${query.platform ? query.platform : ''} Post`,
						clear: clearPrependExploreAlert,
					}}
				/>
			)}
			<Posts
				getPostsAction={getExplorePosts}
				posts={explorePosts}
				setScrollY={setScrollYExplore}
				setLoadMore={setLoadMoreExplore}
			/>
		</Fragment>
	);
};

ExplorePosts.propTypes = {
	getExplorePosts: PropTypes.func.isRequired,
	clearPrependExploreAlert: PropTypes.func.isRequired,
	setScrollYExplore: PropTypes.func.isRequired,
	setLoadMoreExplore: PropTypes.func.isRequired,
	clearExplorePosts: PropTypes.func.isRequired,
	setExploreQuery: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	auth: state.auth,
	explorePosts: state.explorePosts,
});

export default connect(mapStateToProps, {
	getExplorePosts,
	clearPrependExploreAlert,
	prependNewExplorePosts,
	setLoadMoreExplore,
	setScrollYExplore,
	clearExplorePosts,
	setExploreQuery,
})(withRouter(ExplorePosts));
