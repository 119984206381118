import React, { useState, useEffect, Fragment as F } from 'react';
import PropTypes from 'prop-types';
import { withRouter, useParams, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { addPost, findByUsername } from '../../actions/posts';
import {
	setCommentOn,
	clearCommentOn,
	setQuotePost,
	clearQuotePost,
} from '../../actions/post';
import { addComment } from '../../actions/post';
import useTagDetection from '../common/hooks/useTagDetection';
import {
	parsePicsUser,
	hasExistence,
	detectCharCodes,
	handleCloseDropdown,
} from '../common/_utilities';
import { setAlert } from '../../actions/alert';
import ThreadContent from '../common/ThreadContent';
import { _handleReminder, _rmReminder } from '../common/_reminder.js';
import TagSuggestions from '../common/TagSuggestions';
import CheckboxGroup from '../common/CheckboxGroup';
import QuotedPost from '../post/QuotedPost';
import { IMG_UPLOAD_SIZE_LIMIT } from '../common/_shared_constants';

const PostModal = ({
	auth: { user },
	post: { post, commentOn, quotePost },
	thread,
	addComment,
	addPost,
	setCommentOn,
	clearCommentOn,
	clearQuotePost,
	findByUsername,
	setAlert,
	history,
	match,
}) => {
	const params = useParams();
	const location = useLocation();

	const [currRoute, setCurrRoute] = useState(
		location.pathname.substring(1).split('/')[0]
	);

	useEffect(() => {
		setCurrRoute(location.pathname.substring(1).split('/')[0]);
	}, [match]);

	const initialPostData = { text: '', imgFile: '', tagThread: [] };
	const [formData, setFormData] = useState(initialPostData);
	const { text, imgFile, tagThread } = formData;

	const [imgPreview, setImgPreview] = useState({ url: '' });

	const [showPostForm, setShowPostForm] = useState(false);

	useEffect(() => {
		const body = document.getElementsByTagName('body')[0];
		if (showPostForm) {
			body.style.position = 'fixed';
			const textarea = document.getElementById('form-textarea');
			if (currRoute === 'profile') {
				textarea.focus();
				setFormData({ text: `@${params.username} ` });
			} else {
				textarea.focus();
			}
		} else {
			body.style.position = 'static';
		}
	}, [showPostForm]);

	const handleImg = file => {
		if (file.size > IMG_UPLOAD_SIZE_LIMIT) {
			setAlert({ msg: 'File is too large. 2mb limit.', alertType: 'danger' });
			return;
		} else {
			let reader = new FileReader();
			reader.onloadend = () => {
				setFormData({
					...formData,
					imgFile: file,
				});
				setImgPreview({ url: reader.result });
			};
			reader.readAsDataURL(file);
		}
	};

	useEffect(() => {
		setShowPostForm(commentOn || quotePost);
	}, [commentOn, quotePost]);

	const toggleModal = () => {
		if (showPostForm) {
			if (!text && !imgFile) {
				setShowPostForm(false);
				commentOn && clearCommentOn();
				quotePost && clearQuotePost();
			} else if (window.confirm('Disgard post?')) {
				setFormData(initialPostData);
				setImgPreview({});
				setShowPostForm(false);
				commentOn && clearCommentOn();
				quotePost && clearQuotePost();
			}
		} else setShowPostForm(true);
	};

	useEffect(() => {
		if (commentOn && hasExistence(commentOn, 'depth')) {
			const forTagThread = [commentOn, ...thread.posts]
				.filter(p => p.depth <= commentOn.depth)
				.map(({ user: { username } }) => ({
					username,
				}));

			const tagThread = [
				...new Set([commentOn.user.username, ...forTagThread.map(t => t.username)]),
			].map(t => ({
				value: `@${t}`,
				checked: true,
			}));

			setFormData({
				...formData,
				tagThread,
			});
		}
	}, [thread, commentOn]);

	const handleTagThread = e => {
		const { value, checked } = e.target;
		if (commentOn.user.username !== value.substring(1)) {
			const index = tagThread.findIndex(to => to.value === value);

			const tagThreadUpdated = [
				...tagThread.slice(0, index),
				{ value, checked },
				...tagThread.slice(index + 1),
			];

			setFormData({
				...formData,

				tagThread: tagThreadUpdated,
			});
		}
	};

	const {
		handleOnKeyDown,
		handleOnKeyPress,
		handleOnKeyCapture,
		tagSuggestions,
		completeTag,
	} = useTagDetection({ formData, setFormData, findByUsername, field: 'text' });

	return (
		user && (
			<F>
				<div
					className='btn btn-primary shadow-2 '
					id='make-post'
					onMouseEnter={e =>
						_handleReminder({
							e,
							_class: '_reminder_top_left',
							msg: `${
								currRoute === 'posts'
									? 'Leave a comment'
									: currRoute === 'profile'
									? `@${params.username}`
									: currRoute === 'censor-events'
									? 'Submit a Censorship Event'
									: 'Submit a post'
							}`,
						})
					}
					onMouseLeave={_rmReminder}
					onClick={e => {
						currRoute === 'posts' && setCommentOn(post);
						currRoute === 'censor-events'
							? history.push('/add-censorship')
							: toggleModal(e);
					}}>
					<span>
						&nbsp;
						{currRoute === 'posts' ? (
							<F>
								<i className='fas fa-comment-alt ' />{' '}
								<label className='hide-md '>Comment</label>
							</F>
						) : currRoute === 'profile' ? (
							<F>
								<i className='fas fa-at' />{' '}
								<label className='hide-md '>{params.username}</label>
							</F>
						) : location.pathname.includes('censor-events') ? (
							<F>
								<i className='far fa-meh-blank' />{' '}
								<label className='hide-md '>Submit censor event</label>
							</F>
						) : (
							<F>
								<i className='fas fa-plus ' /> <label className='hide-md '>Post</label>
							</F>
						)}
					</span>
				</div>
				{showPostForm && (
					<div id='post-modal' className='' onClick={toggleModal}>
						<form
							className='my-1 '
							onClick={e => {
								e.stopPropagation();
								handleCloseDropdown(e);
							}}
							onSubmit={e => {
								e.preventDefault();
								if (commentOn) {
									addComment(
										{
											...formData,
											parentType: commentOn.postType,
											parent_id: commentOn._id,
										},
										{ main_id: !!post && post._id }
									);
									clearCommentOn();
								} else {
									addPost(
										quotePost ? { ...formData, quotedPost: quotePost._id } : formData
									);
									setShowPostForm(false);
									quotePost && clearQuotePost();
								}
								setImgPreview({});
								setFormData(initialPostData);
							}}>
							<span className='modal-back icon-action ' onClick={toggleModal}>
								<i className='fas fa-times' />
							</span>
							<div id='form-thread'>
								{commentOn && (
									<div className='form-thread-item'>
										<div className='pic-thread'>
											<div className='profile-pic-cont img-cont pe-none'>
												<img
													className='br-50'
													// bug when delete all post and try to post
													src={parsePicsUser(commentOn['user'], 'thumb')}
													alt='avatar'
												/>
											</div>
											<div className='thread-connector'></div>
										</div>
										<div className='thread-post'>
											<ThreadContent {...{ post: commentOn }} />
											{!!tagThread.length && commentOn.user._id !== user._id && (
												<div className='m-1'>
													<small className='form-text'>Replying to: </small>
													<CheckboxGroup
														name='tag-thread'
														onChange={handleTagThread}
														options={tagThread.filter(
															t => t.value.substring(1) !== user.username
														)}
														className='m-0-1'
													/>
												</div>
											)}
										</div>
									</div>
								)}
								<div className='main-text form-thread-item'>
									<div className='pic-thread'>
										<div className='profile-pic-cont img-cont pe-none'>
											<img
												className='br-50'
												src={parsePicsUser(user, 'thumb')}
												alt='avatar'
											/>
										</div>
									</div>
									<div id='form-content' className='thread-post'>
										<textarea
											onPaste={e => {
												const { items } = e.clipboardData;
												[...items].forEach(i => {
													if (i.type === 'image/png') {
														const file = i.getAsFile();
														handleImg(file);
													}
												});
											}}
											id='form-textarea'
											placeholder={`Leave a ${currRoute === 'posts' ? 'comment' : 'post'}`}
											name='text'
											value={text}
											onKeyDown={handleOnKeyDown}
											onKeyUpCapture={handleOnKeyCapture}
											onKeyPress={handleOnKeyPress}
											onChange={e => {
												setFormData({
													...formData,
													text: e.target.value,
												});
											}}
											maxLength='700'
										/>

										<TagSuggestions
											{...{
												tagSuggestions,
												completeTag,
												positioningClass: 'ts-post-form',
											}}
										/>
									</div>
								</div>
							</div>

							{imgPreview.url && (
								<div className='form-img-preview'>
									<div className='img-cont'>
										<img src={imgPreview.url} alt='img-preview' className='br-10' />
									</div>
									<span
										className='remove-preview'
										onClick={e => {
											setImgPreview({});
											setFormData({ ...formData, imgFile: '' });
										}}>
										<i className='fas fa-times' />
									</span>
								</div>
							)}

							{quotePost && (
								<div className='pe-none'>
									<QuotedPost {...{ post: quotePost }} />
								</div>
							)}

							<div className='post-form-actions'>
								<input
									id='post-img'
									type='file'
									onChange={e => {
										if (e.target.files.length > 0) {
											let file = e.target.files[0] || e.dataTransfer.files[0];
											handleImg(file);
										}
									}}
								/>
								<label htmlFor='post-img' className='add-img icon-action' tabIndex={0}>
									<i className='far fa-image ' />
								</label>
								<input id='submit-post' type='submit' style={{ display: 'none' }} />

								<button
									className={`btn  btn-${text.length > 0 ? 'primary' : 'white'}`}
									htmlFor='submit-post'
									type='submit'>
									<span>Submit</span>
								</button>
							</div>
						</form>
					</div>
				)}
			</F>
		)
	);
};
PostModal.propTypes = {
	addComment: PropTypes.func.isRequired,
	addPost: PropTypes.func.isRequired,
	post: PropTypes.object.isRequired,
	setCommentOn: PropTypes.func.isRequired,
	clearCommentOn: PropTypes.func.isRequired,
	clearQuotePost: PropTypes.func.isRequired,
	findByUsername: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, post, thread }) => ({
	auth,
	post,
	thread,
});

export default connect(mapStateToProps, {
	addPost,
	addComment,
	setCommentOn,
	clearCommentOn,
	setQuotePost,
	clearQuotePost,
	findByUsername,
	setAlert,
})(withRouter(PostModal));
