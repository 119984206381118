import React, { Fragment, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, withRouter } from 'react-router-dom';
import { setAlert } from '../../actions/alert';
import { resetPassword } from '../../actions/auth';
import { updatePassword } from '../../actions/auth';
import PropTypes from 'prop-types';
// import util from 'util';

const ResetPassword = ({
	match,
	setAlert,
	resetPassword,
	updatePassword,
	isAuthenticated,
	history,
	// auth: { user }
	// user: { user, loading }
}) => {
	const [validForm, setValidForm] = useState(false);

	const [formData, setFormData] = useState({
		password: '',
		password2: '',
	});

	useEffect(() => {
		const foo = [...document.querySelectorAll('input')].filter(
			f => !f.checkValidity()
		);

		const bar = [...document.querySelectorAll('input')];

		bar.forEach(x => {
			// console.log('OUTPUT:  x.checkValidity()', x.checkValidity());
		});
		bar.forEach(x => {
			console.log('OUTPUT:  x.checkValidity()', x.checkValidity());
			console.log('OUTPUT: x', x);
		});

		// console.log('OUTPUT: bar', bar);
		// console.log('OUTPUT: foo', foo);
	}, [formData]);

	const { token } = match.params;

	useEffect(() => {
		const token = { ...match.params };
		console.log('OUTPUT: token', token);

		resetPassword(token, history);
		// }, [match, history, resetPassword]);
	}, []);

	const { password, password2 } = formData;

	const onChange = e =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = async e => {
		e.preventDefault();
		if (password !== password2) {
			setAlert({ msg: 'Passwords do not match', alertType: 'danger' });
		} else {
			await updatePassword({ password, token, history });
		}
	};

	if (isAuthenticated) {
		return <Redirect to='/home' />;
	}

	return (
		<div className='main-component guest-main-component'>
			<h1 className='large text-primary'>Reset Password</h1>
			<p className='lead mx-1'>
				<i className='fas fa-lock-open' /> Recovery
			</p>
			<form
				className='form'
				onSubmit={e => onSubmit(e)}
				onKeyUp={e =>
					setValidForm(
						![...e.currentTarget.querySelectorAll('input')].filter(
							f => !f.checkValidity()
						).length
					)
				}>
				<div className='form-group'>
					<input
						className='my-1'
						type='password'
						placeholder='Password'
						name='password'
						value={password}
						onChange={e => onChange(e)}
						minLength='6'
						required
					/>
					<input
						className='my-1'
						type='password'
						placeholder='Confirm Password'
						name='password2'
						value={password2}
						onChange={e => onChange(e)}
						minLength='6'
						required
					/>
				</div>
				<input type='submit' id='submit-reset' style={{ display: 'none' }} />
				<button
					htmlFor='submit-reset'
					className={`btn m-1 btn-big btn-${validForm ? 'primary' : 'white'}`}>
					<span>Reset</span>
				</button>
			</form>
		</div>
	);
};

ResetPassword.propTypes = {
	setAlert: PropTypes.func.isRequired,
	resetPassword: PropTypes.func.isRequired,
	updatePassword: PropTypes.func.isRequired,
	isAuthenticated: PropTypes.bool,
};

const mapStateToProps = ({ auth }) => ({
	isAuthenticated: auth.isAuthenticated,
});

export default connect(mapStateToProps, {
	setAlert,
	resetPassword,
	updatePassword,
})(withRouter(ResetPassword));
