import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { connect } from 'react-redux';
import { logout, toggleEmailNotifications } from '../../actions/auth';
import copy from 'copy-to-clipboard';
import { _handleReminder, _rmReminder } from '../common/_reminder';

const DashSettings = ({
	auth: { user, profile },
	logout,
	toggleEmailNotifications,
	history,
}) => {
	const [darkMode, setDarkMode] = useState(
		localStorage.getItem('theme') != 'light'
	);

	//	//dev
	//	const [emailNotificationsEnabled, setEmailNotificationsEnabled] = useState(
	//		true
	//	);

	const [showRmAcc, toggleRmAcc] = useState(false);
	useEffect(() => {
		window.addEventListener('click', () => toggleRmAcc(false), { once: true });
	}, [showRmAcc]);

	useEffect(() => {
		localStorage.setItem('theme', darkMode ? 'dark' : 'light');

		const root = document.getElementById('root');

		if (darkMode) root.classList.add('dark-mode');
		else root.classList.remove('dark-mode');
	}, [darkMode]);

	useEffect(() => {
		// TODO: is there a better way to do this with props?
		let dashHeader = document.getElementById('dash-header-text');
		console.log('dashboard/DashSettings.js: 39  dashHeader ', dashHeader);
		dashHeader.innerHTML = 'Settings';
	}, []);

	const output = useRouteMatch();
	console.log('LINE2: 36  output ', output);
	return (
		<Fragment>
			{/* <span className='px-2 my-1' onClick={e => history.goBack()}> */}
			{/* 	<i class='fas fa-arrow-left' /> */}
			{/* </span> */}
			{/* <ul id='dash-settings' className='selection'> */}
			<ul id='dash-cont' className='selection'>
				<li
					className='toggle-switch-cont'
					onClick={e => {
						e.stopPropagation();
						setDarkMode(!darkMode);
						// console.log('OUTPUT: !darkMode)', !darkMode);
					}}>
					<span className='switch'>
						<input type='checkbox' checked={darkMode} />
						<span className='slider round' />
					</span>{' '}
					<span style={{ paddingLeft: '.5rem' }}>
						{`${darkMode ? 'Dark' : 'Light'} mode`}
					</span>
				</li>
				<li
					className='toggle-switch-cont'
					onClick={e => {
						e.stopPropagation();
						// setEmailNotificationsEnabled(!user.emailNotificationsEnabled);
						console.log('LINE: 65', 'toggle email notifications');
						toggleEmailNotifications();
					}}>
					<span className='switch'>
						<input type='checkbox' checked={user.emailNotificationsEnabled} />
						<span className='slider round' />
					</span>

					<span style={{ paddingLeft: '.5rem' }}>
						{`Email notifications ${
							user.emailNotificationsEnabled ? 'enabled' : 'disabled'
						}`}
					</span>
				</li>

				<li
					className=''
					onClick={e => {
						e.preventDefault();
						if (window.confirm('Are you sure you want to logout?')) {
							// history.push('/login');
							// history.push('/');
							logout();
						}
					}}>
					<span>
						Logout <i className='fas fa-sign-out-alt' />
					</span>
				</li>
				<li onClick={() => history.push('/dashboard/edit-email')}>
					<span> Edit email</span>
				</li>
				<li onClick={() => history.push('/dashboard/edit-name')}>
					<span> Edit name</span>
				</li>
				<li>
					<i
						className={`px-1 ${!showRmAcc ? `fas fa-ellipsis-h` : `fas fa-times`}`}
						onClick={e => !e.stopPropagation() && toggleRmAcc(!showRmAcc)}
					/>
					{showRmAcc && (
						<button
							onClick={() => history.push('/dashboard/delete-account')}
							className='btn  btn-danger'>
							<span>
								<i className='fas fa-user-minus' /> Delete My Account
							</span>
						</button>
					)}
				</li>
			</ul>
		</Fragment>
	);
};

const mapStateToProps = ({ auth }) => ({
	auth,
});

export default connect(mapStateToProps, { logout, toggleEmailNotifications })(
	DashSettings
);
