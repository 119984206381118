import _parseInput from '../common/_parseInput';
import axios from 'axios';
import { country_arr, s_a as state_arr } from '../common/countries';

import { _handleReminder, _rmReminder } from '../common/_reminder.js';

export const labelTransNotEmpty = e => {
	const label = e.target.nextSibling;
	if (e.target.value.length === 0) {
		label.classList.remove('label-not-empty');
		return false;
	} else {
		label.classList.add('label-not-empty');
		return true;
	}
};

// change to dateLabelTransNotEmpty to match above
export const dateLabelTransition = e => {
	const label = e.target.previousSibling;
	if (e.target.value.length === 0)
		label.classList.remove('date-label-not-empty');
	else label.classList.add('date-label-not-empty');
};

export const genOnChange = ({
	usernameStatusClass,
	setUsernameStatusClass,
	formData,
	setFormData,
	setAlert,
}) => {
	return e => {
		const { value, name, checked, selectedIndex } = e.target;
		switch (name) {
			case 'username':
				const username = value.trim();
				const validMatch = username.match(/^[a-z0-9_]{5,15}$/i);

				if (validMatch) {
					(async () => {
						const { status } = await axios.put('/api/auth-profile/username-check', {
							username,
						});

						setUsernameStatusClass(
							status == 200 ? 'valid' : status == 226 ? 'invalid unavailable' : null
						);
					})();
				} else {
					const badChars = username.match(/\W+|_/i);
					badChars && setUsernameStatusClass('invalid alpha-only');

					username.length > 15 && setUsernameStatusClass('invalid too-many');

					usernameStatusClass !== null &&
						!badChars &&
						username.length < 5 &&
						setUsernameStatusClass('invalid too-few');
				}

				setFormData({ ...formData, username });
				break;
			case 'pcStatus':
				const indexOldElement = formData.pcStatus.findIndex(
					({ value: pcVal }) => pcVal === value
				);
				const pcStatus = [
					...formData.pcStatus.slice(0, indexOldElement),
					{ value, checked: checked },
					...formData.pcStatus.slice(indexOldElement + 1),
				];
				setFormData({
					...formData,
					pcStatus,
				});
				break;
			case 'pcStatusOther':
				const clonePcStatus = [...formData['pcStatus']];
				const parsedOutput = _parseInput(value, clonePcStatus);
				const pcStatusOther = '';
				switch (parsedOutput) {
					case '_goodInput':
						setFormData({ ...formData, [name]: value });
						break;
					case '_invalid':
						setFormData({ ...formData });
						setAlert({
							msg: 'Input too long or invalid characters.',
							alertType: 'primary',
						});
						break;
					case '_dups':
						setFormData({ ...formData, pcStatusOther });
						setAlert({ msg: 'no duplicates', alertType: 'danger' });
						break;
					case '_limit':
						setFormData({ ...formData, pcStatusOther });
						setAlert({ msg: 'limit reached', alertType: 'primary' });
						break;
					case parsedOutput:
						const pcStatus = [
							{ value: parsedOutput, checked: true },
							...formData.pcStatus,
						];
						setFormData({ ...formData, pcStatus, pcStatusOther });
						break;
				}
				break;
			case 'country':
				const state = '';
				setFormData({ ...formData, [name]: value, state });
				break;

			default:
				setFormData({ ...formData, [name]: value });
				break;
		}
	};
};

export const genElementsArray = function (...querySelectors) {
	return [].concat.apply(
		[],
		querySelectors.map(selector =>
			[...document.querySelectorAll(selector)].filter(l =>
				l.parentNode.classList.contains('use-trans-label')
			)
		)
	);
};

const inputTypes = [
	'input[type=text]',
	'input[type=password]',
	'select',
	'textarea',
];

// for this to work, id must match formData field
export const checkAndTransLabels = ({ formData }) => {
	const labels = genElementsArray('label');
	const inputsArr = genElementsArray(inputTypes);
	labels.forEach(label => {
		inputsArr.forEach(input => {
			if (label.htmlFor === input.id) {
				if (formData[input.id] && formData[input.id].length > 0) {
					label.classList.add('label-not-empty');
					// setTimeout(() => {
					// label.classList.remove('hide');
					// }, 200);
				}
			}
		});
	});
};
