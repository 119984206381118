// should split these into modules

export const SET_ALERT = 'SET_ALERT';
export const REMOVE_ALERT = 'REMOVE_ALERT';

// Auth
export const REGISTER_SUCCESS = 'REGISTER_SUCCESS';
export const REGISTER_FAIL = 'REGISTER_FAIL';
export const USER_LOADED = 'USER_LOADED';
export const AUTH_ERROR = 'AUTH_ERROR';
export const AUTH_PROFILE_ERROR = 'AUTH_PROFILE_ERROR';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGOUT = 'LOGOUT';
export const FORGOT_SUCCESS = 'FORGOT_SUCCESS';
export const FORGOT_FAIL = 'FORGOT_FAIL';
export const UPASSWORD_SUCCESS = 'UPASSWORD_SUCCESS';
export const UPASSWORD_FAIL = 'UPASSWORD_FAIL';
export const UPDATE_AUTH_CENSOR = 'UPDATE_AUTH_CENSOR';
export const ACCOUNT_DELETED = 'ACCOUNT_DELETED';
export const DELETE_AUTH_CENSOR = 'DELETE_AUTH_CENSOR';

// UserProfile
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_ACTIVE_ACCT = 'UPDATE_ACTIVE_ACCT';
export const DELETE_ACTIVE_ACCT = 'DELETE_ACTIVE_ACCT';
export const USER_PROFILE_ERROR = 'USER_PROFILE_ERROR';
export const CLEAR_USER_PROFILE = 'CLEAR_USER_PROFILE';
export const GET_PROFILE_POSTS_COUNT = 'GET_PROFILE_POSTS_COUNT';
export const UPDATE_USER_PROFILE_FOLLOW = 'UPDATE_USER_PROFILE_FOLLOW ';

// Profiles
export const GET_PROFILES = 'GET_PROFILES';
export const PROFILES_ERROR = 'PROFILES_ERROR';

// posts
export const POSTS_COMMENTS = 'POSTS_COMMENTS'; // used for all routes
export const GET_POSTS = 'GET_POSTS';
export const APPEND_POSTS = 'APPEND_POSTS';
// export const PREPEND_POSTS = 'PREPEND_POSTS';
export const GET_TOP_PLATFORMS = 'GET_TOP_PLATFORMS';
export const CLEAR_POSTS = 'CLEAR_POSTS';
export const POSTS_ERROR = 'POSTS_ERROR';
export const UPDATE_POSTS = 'UPDATE_POSTS';
export const REMOVE_POST = 'REMOVE_POST';
export const SET_LOAD_MORE_POSTS = 'SET_LOAD_MOR_POSTS';
export const UPDATE_POSTS_USER_FOLLOW = 'UPDATE_POSTS_USER_FOLLOW';

export const ADD_HOME_POST = 'ADD_HOME_POST';
export const GET_HOME_POSTS = 'GET_HOME_POSTS';
export const PREPEND_HOME_POSTS = 'PREPEND_HOME_POSTS';
export const CLEAR_PREPEND_HOME_ALERT = 'CLEAR_PREPEND_HOME_ALERT';
export const CLEAR_HOME_POSTS = 'CLEAR_HOME_POSTS';
export const HOME_POSTS_ERROR = 'HOME_POSTS_ERROR';
export const SET_LOAD_MORE_HOME = 'SET_LOAD_MOR_HOME';
export const SET_SCROLL_Y_HOME = 'SET_SCROLL_Y_HOME';

export const ADD_EXPLORE_POST = 'ADD_EXPLORE_POST';
export const GET_EXPLORE_POSTS = 'GET_EXPLORE_POSTS';
export const PREPEND_EXPLORE_POSTS = 'PREPEND_EXPLORE_POSTS';
export const CLEAR_PREPEND_EXPLORE_ALERT = 'CLEAR_PREPEND_EXPLORE_ALERT';
export const CLEAR_EXPLORE_POSTS = 'CLEAR_EXPLORE_POSTS';
export const EXPLORE_POSTS_ERROR = 'EXPLORE_POSTS_ERROR';
export const SET_LOAD_MORE_EXPLORE = 'SET_LOAD_MOR_EXPLORE';
export const SET_SCROLL_Y_EXPLORE = 'SET_SCROLL_Y_EXPLORE';
export const SET_EXPLORE_QUERY = 'SET_EXPLORE_QUERY ';

// export const GET_TOP_PLATFORMS = 'GET_TOP_PLATFORMS';
export const ADD_CENSOR_POST = 'ADD_CENSOR_POST';
export const GET_CENSOR_POSTS = 'GET_CENSOR_POSTS';
export const PREPEND_CENSOR_POSTS = 'PREPEND_CENSOR_POSTS';
export const CLEAR_PREPEND_CENSOR_ALERT = 'CLEAR_PREPEND_CENSOR_ALERT';
export const CLEAR_CENSOR_POSTS = 'CLEAR_CENSOR_POSTS';
export const CENSOR_POSTS_ERROR = 'CENSOR_POSTS_ERROR';
export const SET_LOAD_MORE_CENSOR = 'SET_LOAD_MORE_CENSOR';
export const SET_SCROLL_Y_CENSOR = 'SET_SCROLL_Y_CENSOR ';
export const GET_TOP_PLATFORMS_CENSOR = 'GET_TOP_PLATFORMS_CENSOR';
export const SET_CENSOR_QUERY = 'SET_CENSOR_QUERY ';

// post
export const GET_POST = 'GET_POST';
export const CLEAR_POST = 'CLEAR_POST';
export const COMMENT_ON = 'COMMENT_ON';
export const QUOTE_POST = 'QUOTE_POST';
export const CLEAR_COMMENT_ON = 'CLEAR_COMMENT_ON';
export const CLEAR_QUOTE_POST = 'CLEAR_QUOTE_POST';
export const UPDATE_MAIN = 'UPDATE_MAIN';
export const DELETE_MAIN = 'DELETE_MAIN';
export const ADD_COMMENT = 'ADD_COMMENT';
export const POST_ERROR = 'POST_ERROR';
export const DELETE_COMMENT = 'DELETE_COMMENT';
export const GET_COMMENTS = 'GET_COMMENTS';
export const APPEND_COMMENTS = 'APPEND_COMMENTS';
export const SET_LOAD_MORE_COMMENTS = 'SET_LOAD_MORE_COMMENTS';
// export const PREPEND_COMMENTS = 'PREPEND_COMMENTS';

// posteriorThread
export const GET_POSTERIOR = 'GET_POSTERIOR';
// export const POSTERIOR_ERROR = 'THREAD_ERROR';

// thread
export const THREAD_ERROR = 'THREAD_ERROR';
export const GET_THREAD = 'GET_THREAD';
export const CLEAR_THREAD = 'CLEAR_THREAD';

// common
export const COMMON_ERROR = 'COMMON_ERROR';
export const SET_POSTS_INDEX_SKIP = 'SET_POSTS_INDEX_SKIP';
export const SET_COMMON_DATA = 'SET_COMMON_DATA';
export const SET_TRENDING_HASHTAGS = 'SET_TRENDING_HASHTAGS';
export const SET_TRENDING_CENSOR = 'SET_TRENDING_CENSOR';

// notifications
export const UPDATE_NOTIFICATIONS = 'UPDATE_NOTIFICATIONS';
export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS';
// export const PREPEND_NOTIFICATIONS = 'PREPEND_NOTIFICATIONS';
export const POLL_NOTIFICATIONS = 'POLL_NOTIFICATIONS';
export const NOTIFICATION_ERROR = 'NOTIFICATION_ERROR';
export const SET_LOAD_MORE_NOTIFICATIONS = 'SET_LOAD_MORE_NOTIFICATIONS';

// subscriptions
export const UPDATE_SUBSCRIPTIONS = 'UPDATE_SUBSCRIPTIONS';
export const GET_SUBSCRIPTIONS = 'GET_SUBSCRIPTIONS';
export const SUBSCRIPTIONS_ERROR = 'SUBSCRIPTIONS_ERROR';
