import axios from 'axios';
import { setAlert } from './alert';
import util from 'util';

import {
	UPDATE_CENSOR_POSTS,
	ADD_POST,
	GET_CENSOR_POSTS,
	CLEAR_CENSOR_POSTS,
	GET_TOP_PLATFORMS_CENSOR,
	UPDATE_AUTH_CENSOR,
	// what is this ^
	CENSOR_POSTS_ERROR,
	SET_SCROLL_Y_CENSOR,
	ADD_CENSOR_POST,
	ADD_HOME_POST,
	ADD_EXPLORE_POST,
	SET_LOAD_MORE_CENSOR,
	SET_CENSOR_QUERY,
	PREPEND_CENSOR_POSTS,
	CLEAR_PREPEND_CENSOR_ALERT,
	// GET_CENSOR_POSTS_COUNT,
} from './types';

export const clearCensorPosts = () => dispatch =>
	dispatch({ type: CLEAR_CENSOR_POSTS });

export const getCensorEvents = query => async dispatch => {
	try {
		const res = await axios.put(`/api/posts/censor-events`, query);

		dispatch({
			type: GET_CENSOR_POSTS,
			payload: res.data,
		});
	} catch (error) {
		posts_error(error, dispatch);
	}
};

export const clearPrependCensorAlert = () => dispatch =>
	dispatch({
		type: CLEAR_PREPEND_CENSOR_ALERT,
	});

export const prependNewCensorPosts = query => async dispatch => {
	try {
		const res = await axios.put('/api/posts/prepend-new', query);
		console.log('LINE2: 42  res ', res);

		console.log('LINE2: 45  query', query);
		dispatch({
			type: query.sortBy > 0 ? GET_CENSOR_POSTS : PREPEND_CENSOR_POSTS,
			payload: res.data,
		});
	} catch (error) {
		posts_error(error, dispatch);
	}
};

export const getTopPlatformsCensor = query => async dispatch => {
	try {
		const res = await axios.put(`/api/posts/top-platforms-censor`, query);
		dispatch({
			type: GET_TOP_PLATFORMS_CENSOR,
			payload: res.data,
		});
	} catch (error) {
		posts_error(error, dispatch);
	}
};

export const clearTopPlatformsCensor = () => dispatch =>
	dispatch({
		type: GET_TOP_PLATFORMS_CENSOR,
		payload: [],
	});

export const addCensorEvent = (formDataJSON, history) => async dispatch => {
	try {
		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		};

		const formData = new FormData();
		for (let key in formDataJSON) {
			formData.append(key, formDataJSON[key]);
		}
		console.log('LINE2: 515  formData ', formData);

		const res = await axios.post('/api/posts/censor-event', formData, config);

		const { postType, _id } = res.data;

		dispatch({
			type: UPDATE_AUTH_CENSOR,
			payload: res.data,
		});

		dispatch({
			type: ADD_CENSOR_POST,
			payload: res.data,
		});

		dispatch({
			type: ADD_EXPLORE_POST,
			payload: res.data,
		});

		dispatch({
			type: ADD_HOME_POST,
			payload: res.data,
		});

		dispatch(
			setAlert({
				msg: 'Censorship Event Added',
				postUrl: `/posts/${postType}/${_id}`,
				alertType: 'success',
			})
		);

		// history.goBack();
		history.push('/censor-events/');
	} catch (error) {
		posts_error(error, dispatch);
	}
};

export const setLoadMoreCensor = index => dispatch => {
	dispatch({ type: SET_LOAD_MORE_CENSOR, payload: index });
};

export const setScrollYCensor = scrollY => dispatch => {
	dispatch({ type: SET_SCROLL_Y_CENSOR, payload: scrollY });
};

export const setCensorQuery = query => async dispatch => {
	try {
		dispatch({
			type: SET_CENSOR_QUERY,
			payload: query,
		});
	} catch (error) {
		posts_error(error, dispatch);
	}
};

const posts_error = (error, dispatch) => {
	console.log('OUTPUT: posts_error -> error', error);
	const errors = error.response.data.errors;
	dispatch({
		type: CENSOR_POSTS_ERROR,
		payload: {
			msg: error.response.statusText,
			status: error.response.status,
		},
	});
	if (errors) {
		errors.forEach(({ msg }) => dispatch(setAlert({ msg, alertType: 'danger' })));
		return 400;
	}
};
