import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { REMOVE_ALERT } from '../../actions/types';
import { useHistory } from 'react-router-dom';

const Alert = ({ alerts, dispatch }) => {
	const history = useHistory();

	const multiAlertStyle = alerts.map((a, i) => ({
		// top: `${80 - i * 15}%`,
		bottom: `${i ? i * 10 : 1}rem`,
		// background: 'yellow',
	}));

	// function rmAlert({ target: { id, classList } }) {
	function rmAlert({ currentTarget: { id, classList } }) {
		classList.remove('fade-in');
		classList.add('fade-out');
		setTimeout(() => {
			dispatch({ type: REMOVE_ALERT, payload: id });
		}, 190);
	}

	return (
		alerts !== null &&
		alerts.length > 0 &&
		alerts.map(({ id, msg, alertType, postUrl }, index) => (
			<div
				id={id}
				onClick={rmAlert}
				key={id}
				className={`fade-in alert alert-${alertType}`}
				style={multiAlertStyle[index]}>
				<div>{msg}</div>
				{postUrl && (
					<div
						onClick={e => {
							history.push(postUrl);
							e.target.parentNode.click();
						}}
						className='btn btn-success m-1'>
						<span>go-to</span>
					</div>
				)}
			</div>
		))
	);
};

Alert.propTypes = {
	alerts: PropTypes.array.isRequired,
};

const mapStateToProps = state => ({
	alerts: state.alert,
});

export default connect(mapStateToProps)(Alert);
