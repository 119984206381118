import { LOAD_LIMIT } from '../components/common/_shared_constants';
import {
	INITIAL_POSTS_STATE,
	KEEP_LIMIT,
} from '../components/common/_constants';
import {
	GET_COMMENTS,
	APPEND_COMMENTS,
	GET_POST,
	POST_ERROR,
	UPDATE_MAIN,
	DELETE_MAIN,
	ADD_COMMENT,
	CLEAR_POST,
	GET_THREAD,
	CLEAR_THREAD,
	COMMENT_ON,
	CLEAR_COMMENT_ON,
	THREAD_ERROR,
	QUOTE_POST,
	CLEAR_QUOTE_POST,
	GET_POSTERIOR,
	SET_LOAD_MORE_COMMENTS,
} from '../actions/types';

import {
	_posts_common_actions_switch,
	_remove_post,
	_clear_posts,
	_append_posts,
	_get_posts,
	_add_post,
	_set_load_more,
	_set_scroll_y,
	_get_top_platforms,
	_posts_error,
} from './_posts_common';

export const thread = (
	state = {
		loading: true,
		posts: [],
	},
	action
) => {
	const { type, payload } = action;
	switch (type) {
		//TODO: this could use _get_posts, if scrollToMain is removed.
		case GET_THREAD: {
			return {
				...state,
				posts: payload,
				loading: false,
			};
		}

		case CLEAR_THREAD:
			return { ...state, loading: true, thread: [] };

		case THREAD_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
	}

	return _posts_common_actions_switch({ type, state, payload });
};

export const post = (
	state = {
		post: null,
		commentOn: null,
		quotePost: null,
		loading: true,
		error: {},
	},
	action
) => {
	const { type, payload } = action;

	console.log('reducers/post.js: 119  state', state);

	switch (type) {
		case GET_POST:
			return {
				...state,
				post: payload,
				loading: false,
			};

		case COMMENT_ON:
			return {
				...state,
				commentOn: { ...payload },
			};

		case QUOTE_POST:
			return {
				...state,
				quotePost: payload,
			};

		// TODO: remove && thread;
		// 		case CLEAR_POST:
		// 			return initialState;

		case CLEAR_COMMENT_ON:
			return { ...state, commentOn: null };

		case CLEAR_QUOTE_POST:
			return { ...state, quotePost: null };

		case DELETE_MAIN:
			return !state.post || state.post._id != payload._id
				? state
				: {
						...state,
						post: null,
				  };

		case UPDATE_MAIN:
			return !state.post || state.post._id != payload._id
				? state
				: {
						...state,
						post: payload,
				  };

		case POST_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
		default:
			return state;
	}
};

export const posteriorThread = (
	state = {
		posts: [],
	},
	action
) => {
	const { type, payload } = action;

	switch (type) {
		case GET_POSTERIOR:
			return _get_posts({ state, payload });
	}
	return _posts_common_actions_switch({ type, state, payload });
};

const INITIAL_COMMENTS_STATE = {
	posts: [],
	skipIndex: 0,
	noMore: false,
	loadMore: false,
	loading: true,
	error: {},
};

// const INITIAL_COMMENTS_STATE = {
// 	posts: [],
// 	setLoadMore:
// 	noMore: false, // should I use post's initialState?
// 	skipIndex: 0,
// };

export const comments = (state = INITIAL_COMMENTS_STATE, action) => {
	const { type, payload } = action;
	switch (type) {
		case GET_COMMENTS:
			return _get_posts({
				state: INITIAL_COMMENTS_STATE,
				payload,
			});

		case APPEND_COMMENTS:
			return _append_posts({ state, payload });

		case SET_LOAD_MORE_COMMENTS:
			return _set_load_more({ state, payload });

		case ADD_COMMENT:
			return _add_post({ state, payload });

		case POST_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};
	}

	return _posts_common_actions_switch({ type, state, payload });
};
