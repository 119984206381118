import React, { Fragment, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { _handleReminder, _rmReminder } from '../common/_reminder';
import { labelTransNotEmpty } from '../profile-forms/_profileFormUtilities';
import { editName } from '../../actions/auth';

const DashEditName = ({
	auth: {
		user: { name },
	},
	editName,
}) => {
	const [formData, setFormData] = useState({ name: '' });
	const [validForm, setValidForm] = useState(false);

	const onChange = ({ target: { name, value } }) => {
		setFormData({ ...formData, [name]: value });
	};

	return (
		<Fragment>
			<span className='p-2'>
				<i className='fas fa-edit' />
				&nbsp; Edit name
			</span>
			<form
				key='edit-name-form'
				className='form'
				onSubmit={async e => {
					e.preventDefault();
					editName(formData);
				}}
				onKeyUp={e =>
					setValidForm(
						![...e.currentTarget.querySelectorAll('input')].filter(
							f => !f.checkValidity()
						).length
					)
				}>
				<div className='form-group'>
					<div className='use-trans-label'>
						<input
							id='edit-name'
							type='text'
							name='name'
							value={formData.name}
							onChange={onChange}
							onBlur={labelTransNotEmpty}
							spellcheck='false'
							required
						/>
						<label htmlFor='edit-name'>
							<span>{name}</span>
						</label>
					</div>
					<input
						type='submit'
						id='submit-edit-name'
						style={{ display: 'none' }}
					/>
					<button
						htmlFor='submit-edit-name'
						className={`btn my-2 btn-${validForm ? 'primary' : 'white'}`}
						style={{ marginLeft: '75%' }}>
						<span>Change name</span>
					</button>
				</div>
			</form>
		</Fragment>
	);
};

DashEditName.prototypes = {
	auth: PropTypes.object.isRequired,
	editName: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => ({
	auth,
});

export default connect(mapStateToProps, { editName })(DashEditName);
