import React, { useState, useEffect, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { _handleReminder, _rmReminder } from '../common/_reminder.js';
import PostActions from './PostActions.js';
import PostContent from './PostContent.js';
import { parsePicsUser } from '../common/_utilities.js';
import { followToggle } from '../../actions/profile';
import { getUserSummaryFollowData } from '../../actions/posts';
import useUserSummary from '../common/useUserSummary';

const PostItem = ({
	post,
	history,
	postImg,
	ShowThread = false,
	threadItem = false,
	postActionCreators,
	followToggle,
	getUserSummaryFollowData,
	i,
}) => {
	const {
		_id,
		user,
		postType,
		threadDescendant,
		isThread,
		recycledBy = false,
	} = post;

	const [wasRedacted] = useState(user.username === 'redacted');

	const [UserSummary, handleUserSummary] = useUserSummary({
		//---- dev remove these from useUserSummary too
		i,
		post,
		// ------
		followToggle,
		getUserSummaryFollowData,
		// user,
	});

	return (
		<div className={`post-cont ${wasRedacted ? 'greyed-out-txt' : ''}`}>
			<div className={threadItem ? 'post-thread' : 'post'}>
				{recycledBy && (
					<span
						className='recycled-by'
						onClick={e => {
							e.preventDefault();
							history.push(`/profile/${recycledBy}`);
						}}>
						<i className='fas fa-retweet' /> Recycled by @{recycledBy}
					</span>
				)}
				<div
					className='thread-item-cont'
					onClick={() => {
						if (window.getSelection().toString().length === 0) {
							history.push(
								`/posts/${postType === 'BasePost' ? 'Post' : postType}/${_id}`
							);
						}
					}}>
					<div className='pic-thread'>
						{isThread && !recycledBy && (
							<div className='thread-connector top-connector' />
						)}
						<div
							className='profile-pic-cont img-cont'
							{...handleUserSummary(
								window.matchMedia('(max-width: 700px)').matches
									? {
											top: '0.3rem',
											left: '-0.75rem',
									  }
									: {
											top: '0.8rem',
											left: '-3.8rem',
									  }
							)}
							onClick={e =>
								!e.stopPropagation() && history.push(`/profile/${user.username}`)
							}>
							<img
								className='br-50'
								src={parsePicsUser(user, 'thumb')}
								// src='https://ui-avatars.com/api/?name=r+f'
								alt='avatar'
							/>
						</div>

						<UserSummary />

						{threadItem && threadDescendant && !recycledBy && (
							<div className='thread-connector ' />
						)}
						{/* there are two because I was getting a very strange bug when adding them together. Id was showing in the jsx and also messing up the styling. */}
						{!post.depth && post.threadDescendant && !recycledBy && (
							<div className='thread-connector ' />
						)}
					</div>
					<div className={` thread-post `}>
						<PostContent
							{...{
								post,
								postImg,
								wasRedacted,
								handleUserSummary: handleUserSummary({
									left: '3.8rem',
									top: '-1rem',
								}),
							}}
						/>
						<PostActions {...{ post, wasRedacted }} />
					</div>
				</div>
				{ShowThread && <ShowThread />}
			</div>
		</div>
	);
};

PostItem.propTypes = {
	post: PropTypes.object.isRequired,
	history: PropTypes.object.isRequired,
	postImg: PropTypes.func.isRequired,
	ShowThread: PropTypes.func.isRequired,
	ShowThread: PropTypes.func.isRequired,
	threadItem: PropTypes.bool.isRequired,
	postActionCreators: PropTypes.object.isRequired,
};

export default connect(null, { followToggle, getUserSummaryFollowData })(
	withRouter(PostItem)
);
