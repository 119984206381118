import React, { Fragment, useEffect, useState } from 'react';
import { Link, withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProfileTop from './ProfileTop';
import ProfileAbout from './ProfileAbout';
import Posts from '../posts/Posts';
import { getSubscriptions } from '../../actions/profile';
import ProfileCensorship from './ProfileCensorship';
import ProfileActive from './ProfileActive';
import { hasExistence, useScrollToElementById } from '../common/_utilities';
import _ from 'lodash';
import { REDACTED_ID } from '../common/_shared_constants';
import { getProfilePosts, setLoadMorePosts } from '../../actions/posts';

const Profile = ({
	auth,
	userProfile: { userProfile, loading },
	posts,
	match: { params },
	getProfilePosts,
	getSubscriptions,
	setLoadMorePosts,
}) => {
	const { loading: postsLoading } = posts;

	useEffect(() => {
		console.log('LINE2: 29  postsLoading', postsLoading);
		console.log('LINE2: 31  userProfile', userProfile);
		// if (postsLoading && userProfile) {
		// hasExistence(userProfile, 'id') &&
		// 	userProfile.username === username &&
		if (postsLoading)
			getProfilePosts(userProfile.id, {
				user_id: auth.isAuthenticated && auth.user.id,
			});
		// }
	}, [postsLoading]);

	useScrollToElementById(params.anchor, !postsLoading && params.anchor, [
		postsLoading,
	]);

	const [isOwner, setIsOwner] = useState(null);
	useEffect(() => {
		if (auth.user && userProfile) {
			auth.user.id !== userProfile.id && getSubscriptions(userProfile);
			setIsOwner(auth.isAuthenticated && auth.user._id === userProfile._id);
		}
	}, [auth, userProfile]);

	// routeParam is either username or id. I really don't like this method.
	const routeParam = Object.keys(params)[0];

	return hasExistence(userProfile, 'id') &&
		userProfile.id !== 'nonexistent' &&
		_.get(userProfile, 'profile', false) ? (
		<Fragment>
			{isOwner && (
				<span id='is-owner' className='head-btn'>
					<Link to='/edit-profile' className='btn btn-white'>
						<span>Edit Profile</span>
					</Link>
				</span>
			)}

			<div className='profile-grid '>
				<ProfileTop {...{ isOwner }} />
				<ProfileAbout {...{ isOwner }} />
				<ProfileCensorship {...{ isOwner }} />
				<ProfileActive {...{ isOwner }} />
			</div>

			{/* bug with /profile-id not sure how to reproduce, but complains about id */}
			<Posts
				posts={posts}
				getPostsAction={(id => query => getProfilePosts(id, query))(userProfile.id)}
				setLoadMore={setLoadMorePosts}
				emptyMessage={`${userProfile.name} has no posts yet.`}
			/>
		</Fragment>
	) : auth.isAuthenticated && auth.user.id === userProfile.id ? (
		<Redirect to='/home' />
	) : (
		<div className='m-2 p-2' style={{ marginBottom: 'auto', color: 'grey' }}>
			{userProfile.id == REDACTED_ID ? (
				<p>Profile has been removed</p>
			) : userProfile.id !== 'nonexistent' ? (
				<p>{userProfile.name} does not have a profile yet.</p>
			) : (
				<p>
					Account {routeParam === 'username' ? `@${params[routeParam]}` : ''} doesn't
					exist
				</p>
			)}
		</div>
	);
};

Profile.propTypes = {
	auth: PropTypes.object.isRequired,
	userProfile: PropTypes.object.isRequired,
	getSubscriptions: PropTypes.func.isRequired,
	getProfilePosts: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, userProfile, posts }) => ({
	auth,
	userProfile,
	posts,
});

export default connect(mapStateToProps, {
	getProfilePosts,
	getSubscriptions,
	setLoadMorePosts,
})(withRouter(Profile));

{
	/* <Link to='/profiles' className='btn btn-light'>
            Profiles
          </Link> */
}
