import axios from 'axios';
import { setAlert } from './alert';

import {
	UPDATE_NOTIFICATIONS,
	NOTIFICATION_ERROR,
	GET_NOTIFICATIONS,
	POLL_NOTIFICATIONS,
	SET_LOAD_MORE_NOTIFICATIONS,
} from './types';

export const getNotifications = query => async dispatch => {
	try {
		query.skipIndex && dispatch(setLoadMoreNotifications());
		console.log('LINE2: 15  query.skipIndex', query.skipIndex);
		const res = await axios.put('/api/notifications', query);
		dispatch({
			type: GET_NOTIFICATIONS,
			payload: res.data,
			skipIndex: query.skipIndex,
		});
	} catch (error) {
		console.log('OUTPUT: error', error);
		dispatch({
			type: NOTIFICATION_ERROR,
			payload: {
				msg: error.response.statusText,
				status: error.response.status,
			},
		});
	}
};

export const pollNotifications = (
	{ firstIndexDate } = { firstIndexDate: 0 },
	socketData
) => async dispatch => {
	// console.log('OUTPUT: firstIndexDate', firstIndexDate);
	try {
		const res = await axios.put('/api/notifications/poll', { firstIndexDate });

		dispatch({
			type: POLL_NOTIFICATIONS,
			payload: res.data,
		});

		console.log('LINE2: 91 _socketData ', socketData);
		dispatch(
			setAlert({
				msg: socketData.msg,
				alertType: 'success',
				postUrl: socketData.postUrl,
			})
		);
	} catch (error) {
		console.log('OUTPUT: error', error);
		dispatch({
			type: NOTIFICATION_ERROR,
			payload: {
				msg: error.response.statusText,
				status: error.response.status,
			},
		});
	}
};

export const deactivateNotifications = () => async dispatch => {
	try {
		const res = await axios.put('/api/notifications/deactivate');
		console.log('LINE2: 68  res ', res);
		dispatch({
			type: UPDATE_NOTIFICATIONS,
		});
	} catch (error) {
		console.log('LINE2: 73  error', error);
		dispatch({
			type: NOTIFICATION_ERROR,
			payload: {
				msg: error.response.statusText,
				status: error.response.status,
			},
		});
	}
};

export const setLoadMoreNotifications = () => dispatch => {
	dispatch({ type: SET_LOAD_MORE_NOTIFICATIONS });
};
