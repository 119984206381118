import axios from 'axios';
import { setAlert } from './alert';
import util from 'util';

import {
	CLEAR_HOME_POSTS,
	GET_HOME_POSTS,
	HOME_POSTS_ERROR,
	// TODO
	HOME_POSTS_COMMENTS,
	SET_SCROLL_Y_HOME,
	SET_LOAD_MORE_HOME,
	PREPEND_HOME_POSTS,
	CLEAR_PREPEND_HOME_ALERT,
} from './types';

export const getHomePosts = query => async dispatch => {
	try {
		const res = await axios.put('/api/posts/home', query);

		dispatch({
			type: GET_HOME_POSTS,
			payload: res.data,
		});
	} catch (error) {
		posts_error(error, dispatch);
	}
};

export const clearPrependHomeAlert = () => dispatch =>
	dispatch({
		type: CLEAR_PREPEND_HOME_ALERT,
	});

export const prependNewHomePosts = query => async dispatch => {
	try {
		const res = await axios.put('/api/posts/home/prepend-new', query);

		console.log('LINE2: 42  res ', res);
		console.log('LINE2: 45  query', query);

		dispatch({
			// type: query.sortBy > 0 ? GET_HOME_POSTS : PREPEND_HOME_POSTS,
			type: PREPEND_HOME_POSTS,
			payload: res.data,
		});
	} catch (error) {
		posts_error(error, dispatch);
	}
};

export const clearHomePosts = () => async dispatch =>
	dispatch({ type: CLEAR_HOME_POSTS });

export const setLoadMoreHome = index => dispatch => {
	dispatch({ type: SET_LOAD_MORE_HOME, payload: index });
};

export const setScrollYHome = scrollY => dispatch => {
	dispatch({ type: SET_SCROLL_Y_HOME, payload: scrollY });
};

const posts_error = (error, dispatch) => {
	console.log('OUTPUT: posts_error -> error', error);
	const errors = error.response.data.errors;
	dispatch({
		type: HOME_POSTS_ERROR,
		payload: {
			msg: error.response.statusText,
			status: error.response.status,
		},
	});
	if (errors) {
		errors.forEach(({ msg }) => dispatch(setAlert({ msg, alertType: 'danger' })));
		return 400;
	}
};
