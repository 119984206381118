import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
	getCensorEvents,
	clearPrependCensorAlert,
	prependNewCensorPosts,
	setLoadMoreCensor,
	setScrollYCensor,
	getTopPlatformsCensor,
	clearCensorPosts,
	setCensorQuery,
} from '../../actions/censorPosts';
import { _handleReminder, _rmReminder } from '../common/_reminder';
import { queryStringToObject } from '../common/_utilities';
import PrependAlert from '../common/PrependAlert';
import Posts from '../posts/Posts';
import TopPlatforms from '../common/TopPlatforms';
import FilterDropdown from '../common-post/FilterDropdown';
import { SUBMISSION_DATE } from '../common/_shared_constants';
import { INTERVAL_UPDATE_TIME, SCROLL_ALERT_LIMIT } from '../common/_constants';

const CensorEvents = ({
	getCensorEvents,
	prependNewCensorPosts,
	clearPrependCensorAlert,
	getTopPlatformsCensor,
	censorPosts,
	setLoadMoreCensor,
	setScrollYCensor,
	clearCensorPosts,
	setCensorQuery,
	location,
}) => {
	const { loading, topPlatforms, query: prevQuery } = censorPosts;
	const [query, setQuery] = useState({
		...prevQuery,
		...queryStringToObject(location.search),
	});

	useEffect(() => {
		if (loading) {
			getCensorEvents(query);
			getTopPlatformsCensor(query);
		} else if (query.src) {
			getCensorEvents(query);
			const { src, ...persist } = query;
			setQuery(persist);
		}
	}, [loading]);

	useEffect(() => {
		censorPosts.prependAlert &&
			censorPosts.scrollY < SCROLL_ALERT_LIMIT &&
			clearPrependCensorAlert();
	}, [censorPosts.scrollY]);

	useEffect(() => {
		if (query.sortBy !== 1) {
			const { posts } = censorPosts;
			const intervalId =
				posts.length &&
				setInterval(() => {
					console.log('LINE: 49 CensorPosts interval');
					prependNewCensorPosts({ firstIndexDate: posts[0].date, ...query });
				}, INTERVAL_UPDATE_TIME);
			return () => clearInterval(intervalId);
		}
	}, [censorPosts, query]);

	return (
		<Fragment>
			<TopPlatforms
				{...{
					topPlatforms,
					label: `Top censorers ${(() =>
						query[SUBMISSION_DATE.key]
							? SUBMISSION_DATE.options.filter(
									o => o.value === query[SUBMISSION_DATE.key]
							  )[0]
							: SUBMISSION_DATE.default)().method.toLowerCase()} `,
					clearPostsAction: clearCensorPosts,
					setQueryAction: setCensorQuery,
					query,
				}}
			/>
			<FilterDropdown
				{...{
					query,
					clearPostsAction: clearCensorPosts,
					setQueryAction: setCensorQuery,
					active: { submissionDate: true, postType: false, sortBy: true },
				}}
			/>
			{censorPosts.prependAlert && censorPosts.scrollY > SCROLL_ALERT_LIMIT && (
				<PrependAlert
					{...{
						msg: `New ${query.platform ? query.platform : ''} Post`,
						clear: clearPrependCensorAlert,
					}}
				/>
			)}
			<Posts
				getPostsAction={getCensorEvents}
				posts={censorPosts}
				setScrollY={setScrollYCensor}
				setLoadMore={setLoadMoreCensor}
				emptyMessage={`No Censorship Events`}
			/>
		</Fragment>
	);
};

CensorEvents.propTypes = {
	getCensorEvents: PropTypes.func.isRequired,
	prependNewCensorPosts: PropTypes.func.isRequired,
	clearPrependCensorAlert: PropTypes.func.isRequired,
	getTopPlatformsCensor: PropTypes.func.isRequired,
	profiles: PropTypes.object.isRequired,
	setScrollYCensor: PropTypes.func.isRequired,
	setLoadMoreCensor: PropTypes.func.isRequired,
};

const mapStateToProps = ({ censorPosts }) => ({
	censorPosts,
});

export default connect(mapStateToProps, {
	getCensorEvents,
	prependNewCensorPosts,
	clearPrependCensorAlert,
	getTopPlatformsCensor,
	setScrollYCensor,
	setLoadMoreCensor,
	clearCensorPosts,
	setCensorQuery,
})(withRouter(CensorEvents));
