import React, { Fragment } from 'react';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Moment from 'react-moment';
import { makeAwesome } from '../common/_utilities';
import { subCensorEmail, subCensorNotification } from '../../actions/profile';
import CheckboxGroup from '../common/CheckboxGroup';
import { setAlert } from '../../actions/alert';
import { _handleReminder, _rmReminder } from '../common/_reminder';

const ProfileCensorship = ({
	auth: { isAuthenticated },
	userProfile: {
		userProfile: { _id, censorEvents, firstName },
	},
	subscriptions: { subscriptions },
	subCensorEmail,
	subCensorNotification,
	setAlert,
	isOwner,
}) => {
	const history = useHistory();
	const ProfileEl = ({
		censorship,
		censorship: { platform, formOfCensor, text, date, _id },
	}) => (
		<div
			className='profile-el'
			onClick={e =>
				window.getSelection().toString().length === 0 &&
				history.push(`/posts/CensorEvent/${_id}`)
			}
			onMouseEnter={e =>
				text &&
				_handleReminder({
					e,
					_class: '_reminder_top_right',
					msg: `${text}`,
				})
			}
			onMouseLeave={_rmReminder}>
			<h3>{platform}</h3>
			<p>
				<span>{platform && makeAwesome(platform)} </span>
			</p>
			<p>
				<Moment fromNow>{date}</Moment>
			</p>
			<strong>{formOfCensor}</strong>
			{censorship.img && (
				<div className='img-cont'>
					<img
						className='br-5'
						src={`${censorship.img.path.replace('public', '')}`}
						alt='cen-img'
					/>
				</div>
			)}
		</div>
	);

	return (
		<div id='censorship' className='profile-cens profile-cont p-2  el-head'>
			<h2>Censorship Events</h2>
			<div
				className={`subscribe-cont `}
				onClick={e =>
					isOwner &&
					setAlert({ msg: 'You cannot sub to yourself', alertType: 'primary' })
				}>
				<small className='form-text'>Subscribe to updates</small>

				<CheckboxGroup
					name='censorship'
					className={`${!isAuthenticated || isOwner ? 'not-authenticated' : ''}`}
					onChange={e => {
						//e.preventDefault();
						const { value, name } = e.target;
						if (!isAuthenticated) history.push('/login');
						console.log('LINE: 61', 'not owner');
						value === 'Email'
							? subCensorEmail({
									_id,
									setTo: !subscriptions.censorship.email,
									firstName,
							  })
							: subCensorNotification({
									_id,
									setTo: !subscriptions.censorship.notification,
									firstName,
							  });
					}}
					options={[
						{
							value: 'Email',
							checked: subscriptions && subscriptions.censorship.email,
						},
						{
							value: 'Notification',
							checked: subscriptions && subscriptions.censorship.notification,
						},
					]}
				/>
				{/* <label htmlFor=''>Political Leanings</label> */}
			</div>
			{censorEvents.length ? (
				censorEvents.map(event => <ProfileEl key={event._id} censorship={event} />)
			) : (
				<h4>No Censorship Events</h4>
			)}
		</div>
	);
};

const mapStateToProps = ({ auth, userProfile, subscriptions }) => ({
	auth,
	userProfile,
	subscriptions,
});

ProfileCensorship.propTypes = {
	userProfile: PropTypes.object.isRequired,
	subCensorEmail: PropTypes.func.isRequired,
	subCensorNotification: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
	subCensorEmail,
	subCensorNotification,
	setAlert,
})(ProfileCensorship);
