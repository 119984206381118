import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProfileByUsername } from '../../actions/profile';
import Profile from './Profile';
import Spinner from '../layout/Spinner';
import { hasExistence } from '../common/_utilities';

const UsernameProfile = ({
	getProfileByUsername,
	auth,
	userProfile: { loading, userProfile },
}) => {
	const { username } = useParams();
	useEffect(() => {
		// This thing has been a real pain in the arse and I hate that I have to do all these checks
		// console.log('LINE2: 15  username ', username);
		// console.log('LINE2: 17  loading', loading);
		// console.log('LINE2: 21  userProfile', userProfile);
		if (
			loading ||
			(hasExistence(userProfile, 'username') && username !== userProfile.username)
		)
			getProfileByUsername(username, auth.user && auth.user.id);
	}, [username, userProfile]);

	return loading ? <Spinner /> : <Profile />;
};

UsernameProfile.propTypes = {
	auth: PropTypes.object.isRequired,
	userProfile: PropTypes.object.isRequired,
	getProfileByUsername: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, userProfile }) => ({
	auth,
	userProfile,
});

export default connect(mapStateToProps, {
	getProfileByUsername,
})(UsernameProfile);
