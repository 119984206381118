import React, { useState, useEffect, useRef, Fragment } from 'react';
import PropTypes from 'prop-types';
import { _handleReminder, _rmReminder } from '../common/_reminder.js';

const PostImg = ({ post, setModalData }) => {
	const { _id, img } = post;
	return (
		<div className='post-img'>
			{post.img && (
				<div className='img-cont'>
					<img
						src={`${img.path.replace('public', '')}`}
						alt='post-img'
						onClick={e => {
							e.stopPropagation();
							console.log('OUTPUT: PostImg -> e', e.target);
							const { src } = e.target;
							console.log('OUTPUT: PostImg -> src', src);
							setModalData({ _id, src });
						}}
					/>
				</div>
			)}
		</div>
	);
};

const ImgModal = ({ modalData, setModalData, additionalClasses = '' }) => {
	const { src, _id, text } = modalData;

	// I don't know why i originally had this but it is broken and not needed
	// const srcImg = src.substring(src.indexOf('censor-images'));
	// console.log('OUTPUT: ImgModal -> srcImg', srcImg);

	return (
		<div
			id={_id}
			className='modal'
			onClick={e => {
				e.stopPropagation();
				if (!e.target.isSameNode(e.currentTarget.querySelector('img')))
					setModalData({});
			}}>
			<span className='modal-close ' id={`${_id}-close`}>
				&times;
			</span>
			<img
				src={src}
				alt='modal-img'
				className={`br-5 modal-content ${additionalClasses} `}
			/>
			<div className='caption' id={`${_id}-caption`}>
				{text}
			</div>
		</div>
	);
};

PostImg.propTypes = {
	post: PropTypes.object.isRequired,
	setModalData: PropTypes.func.isRequired,
};

export { PostImg, ImgModal };
