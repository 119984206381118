import React, { useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getTrendingCensor } from '../../actions/common';
import { INTERVAL_UPDATE_TIME } from '../common/_constants';
import Spinner from '../layout/Spinner';

const TrendingCensor = ({ trending, getTrendingCensor }) => {
	//console.log('LINE2: 10  trending', trending);
	useEffect(() => {
		//console.log('LINE: 10', 'censor USEEFFECT ');
		//console.log('LINE2: 13  trending.loading', trending.loading);
		if (trending.censor.loading) {
			getTrendingCensor();

			const intervalId = setInterval(() => {
				//console.log('LINE: 14', 'updating trending censor');
				getTrendingCensor();
			}, INTERVAL_UPDATE_TIME);
			return () => clearInterval(intervalId);
		}
	}, []);

	return (
		<div className='trending-cont' id='trending-censor'>
			<h3 className='text-primary'>Trending Censorship</h3>
			{trending.censor.loading ? (
				<Spinner />
			) : (
				<div className='trending-items-cont'>
					{trending.censor.values.map((val, idx) => (
						<Link
							onClick={e => e.stopPropagation()}
							to={`/censor-events/q?${new URLSearchParams({
								platform: val.platform,
								src: 'cnsrshp_trend',
							})}`}>
							{idx + 1}. {val.platform}
						</Link>
					))}
				</div>
			)}
		</div>
	);
};

TrendingCensor.propTypes = {};

const mapStateToProps = ({ trending }) => ({ trending });

export default connect(mapStateToProps, { getTrendingCensor })(
	withRouter(TrendingCensor)
);
