import {
	GET_USER_PROFILE,
	GET_PROFILES,
	USER_PROFILE_ERROR,
	PROFILES_ERROR,
	UPDATE_SUBSCRIPTIONS,
	SUBSCRIPTIONS_ERROR,
	CLEAR_USER_PROFILE,
	UPDATE_USER_PROFILE_FOLLOW,
} from '../actions/types';
import util from 'util';
import { hasExistence } from '../components/common/_utilities';

const userProfileInitalState = { userProfile: null, loading: true, error: {} };
export function userProfile(state = userProfileInitalState, action) {
	const { type, payload } = action;
	switch (type) {
		case GET_USER_PROFILE:
			return {
				...state,
				userProfile: payload,
				loading: false,
			};

		case UPDATE_USER_PROFILE_FOLLOW:
			const { userProfile } = state;
			return !userProfile
				? state
				: {
						...state,
						userProfile: {
							...userProfile,
							follows: payload,
						},
				  };

		case USER_PROFILE_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
				userProfile: null,
			};

		case CLEAR_USER_PROFILE:
			return userProfileInitalState;

		default:
			return state;
		// break;
	}
}

export function profiles(
	state = {
		profiles: [],
		loading: true,
		error: {},
	},
	action
) {
	const { type, payload } = action;
	switch (type) {
		case GET_PROFILES:
			return {
				...state,
				profiles: payload,
				loading: false,
			};
		case PROFILES_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
				profiles: null,
			};
		default:
			return state;
	}
}

export function subscriptions(
	state = {
		subscriptions: null,
		loading: true,
		error: {},
	},
	action
) {
	const { type, payload } = action;
	switch (type) {
		case UPDATE_SUBSCRIPTIONS:
			return {
				...state,
				subscriptions: payload,
				loading: false,
			};

		case SUBSCRIPTIONS_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};

		default:
			return state;
	}
}
