import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import SearchBox from '../common/SearchBox';
import { logout } from '../../actions/auth';
import TrendingHashtags from './TrendingHashtags';
import TrendingCensor from './TrendingCensor';

const RightSide = ({}) => {
	return (
		<div id='right-side' className='side-container'>
			<div id='search-side'>
				<SearchBox />
				<div id='trending-scroll-wrapper'>
					<TrendingHashtags />
					<TrendingCensor />
				</div>
			</div>
		</div>
	);
};

RightSide.propTypes = {};

const mapStateToProps = state => ({});

export default connect(mapStateToProps, { logout })(withRouter(RightSide));
