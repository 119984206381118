import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

const Landing = ({ isAuthenticated }) => {
	console.log('OUTPUT: Landing -> isAuthenticated', isAuthenticated);
	// return isAuthenticated !== null && !isAuthenticated ? (
	return !isAuthenticated ? (
		<section
			className='landing main-component'
			style={{ position: 'fixed', top: '0' }}>
			<div className='dark-overlay'>
				<div className='landing-inner'>
					<h1 className='x-large'> CNSRSHP </h1>
					<p className='lead'>
						[ a modern day book burning ]{/* [REDACTED] */}
					</p>
					<div className='buttons'>
						<Link to='/register' className='btn btn-big btn-primary '>
							<span>SIGN UP</span>
						</Link>
						<Link to='/login' className='btn btn-big btn-light'>
							<span>LOG IN</span>
						</Link>
					</div>
				</div>
			</div>
		</section>
	) : (
		<Redirect to='/home' />
	);
};

Landing.propTypes = {
	isAuthenticated: PropTypes.bool,
};

const mapStateToProps = state => ({
	isAuthenticated: state.auth.isAuthenticated,
});

export default connect(mapStateToProps)(Landing);
