import React, { Fragment, useState, useEffect } from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createProfile } from '../../actions/authProfile';
import ProfileForm from './ProfileForm';
import { setAlert } from '../../actions/alert.js';

const CreateProfile = ({ auth: { loading, user } }) => {
	const pcStatusOptions = [
		'Education',
		'Social',
		'Professional',
		'Political',
		'Romantic',
		'Identity',
		'Other',
	].map(o => ({ checked: false, value: o }));

	const [formData, setFormData] = useState({
		username: user && user.username,
		pcStatus: pcStatusOptions,
		pcStatusOther: '',
		country: '',
		state: '',
		citizenship: '',
		age: 0,
		gender: '',
		genderCustom: '',
		bio: '',
		imgName: '',
		imgFile: '',
	});

	return !loading && user.profile ? (
		<Redirect to='/home' />
	) : (
		user && (
			<ProfileForm
				{...{
					formData,
					setFormData,
				}}
			/>
		)
	);
};

CreateProfile.propTypes = {
	createProfile: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	auth: state.auth,
});

export default connect(mapStateToProps, {
	createProfile,
	setAlert,
})(withRouter(CreateProfile));
