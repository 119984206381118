import React, { useEffect, useState, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import RadioGroup from './RadioGroup';

const TopPlatforms = ({
	history,
	location,
	topPlatforms,
	setQueryAction,
	clearPostsAction = () =>
		console.log('if this is called there is no need to clear'),
	label,
	query,
}) => {
	const [radioSelected, setRadioSelected] = useState();

	useEffect(() => {
		const selection = document.getElementById('radio-selection');
		const radiotags = document.getElementById('ks-radiotags');
		if (radiotags && selection && selection.offsetLeft > radiotags.offsetWidth) {
			radiotags.scrollLeft = selection.offsetLeft;
		}

		// check if overflow arrows should be used
		const radioCont = document.getElementById('radio-cont');
		if (radioCont && radioCont.offsetWidth < radiotags.scrollWidth) {
			if (!radiotags.scrollLeft) {
				const rightArrow = document.getElementById('right-arrow');
				rightArrow.style.visibility = 'visible';
				const leftArrow = document.getElementById('left-arrow');
				leftArrow.style.visibility = 'visible';
			}
		}
	}, [radioSelected]);

	return (
		!!topPlatforms.length && (
			<Fragment>
				<span className='text-block text-center-x lead'>{label}</span>
				<div id='radio-cont' className='radio-platforms header'>
					<div
						id='left-arrow'
						className='platform-arrow'
						onClick={e => {
							e.currentTarget.parentNode.querySelector(
								'#right-arrow'
							).style.visibility = 'visible';

							const radiotags = document.getElementById('ks-radiotags');

							radiotags.scrollTo({
								top: 0,
								left: radiotags.scrollLeft - 90,
								behavior: 'smooth',
							});

							if (!radiotags.scrollLeft) {
								e.currentTarget.style.visibility = 'hidden';
							}
						}}>
						<span>
							<i className='fas fa-arrow-left'></i>
						</span>
					</div>

					<RadioGroup
						name='topPlatforms'
						setRadioSelected={setRadioSelected}
						onMouseLeave={e => {
							document.getElementsByTagName('html')[0].style.overflowY = 'auto';
						}}
						onWheel={e => {
							document.getElementsByTagName('html')[0].style.overflowY = 'hidden';

							const currScroll = e.currentTarget.scrollLeft;

							if (e.deltaY > 0) {
								e.currentTarget.scrollTo({
									top: 0,
									left: currScroll + 90,
									behavior: 'smooth',
								});
							} else {
								e.currentTarget.scrollTo({
									top: 0,
									left: currScroll - 99,
									behavior: 'smooth',
								});
							}
						}}
						onClick={({ target: { value: platform } }) => {
							const { platform: prevPlatform, hashtags, ...persist } = query;

							const newQuery =
								prevPlatform === platform
									? { ...persist }
									: {
											...persist,
											platform,
									  };

							setQueryAction && setQueryAction(newQuery);
							clearPostsAction();

							const queryString = new URLSearchParams(newQuery);
							history.push(
								`${location.pathname}${!query ? '/query' : ''}?${queryString}#`
							);
						}}
						options={topPlatforms.map(tp => tp.platform)}
						value={query.platform}
					/>
					<div
						id='right-arrow'
						className='platform-arrow'
						onClick={e => {
							e.currentTarget.parentNode.querySelector(
								'#left-arrow'
							).style.visibility = 'visible';

							const radiotags = document.getElementById('ks-radiotags');
							const radioCont = e.currentTarget.parentNode;

							radiotags.scrollTo({
								top: 0,
								left: radiotags.scrollLeft + 90,
								behavior: 'smooth',
							});

							if (
								radiotags.scrollWidth - radiotags.scrollLeft <
								radioCont.offsetWidth
							) {
								e.currentTarget.style.visibility = 'hidden';
							}
						}}>
						<span>
							<i className='fas fa-arrow-right'></i>
						</span>
					</div>
				</div>
			</Fragment>
		)
	);
};

TopPlatforms.propTypes = {};

export default withRouter(TopPlatforms);
