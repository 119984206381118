import React, { Fragment, useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { demographicInfo } from '../../actions/authProfile';
import RadioGroup from '../common/RadioGroup';
import { setAlert } from '../../actions/alert.js';
import _parseInput from '../common/_parseInput';
import { _handleReminder, _rmReminder } from '../common/_reminder.js';
import CheckboxGroup from '../common/CheckboxGroup';
import { labelTransNotEmpty } from './_profileFormUtilities';
import LocationSelect from '../common/LocationSelect';
import { detectCharCodes } from '../common/_utilities';

import { checkAndTransLabels } from './_profileFormUtilities';

const DemographicInfo = ({
	auth: {
		user: {
			location: { country = '', state = '' },
		},
	},
	demographicInfo,
	history,
	setAlert,
}) => {
	const [validForm, setValidForm] = useState(false),
		[formData, setFormData] = useState({
			// sex: '',
			household: '',
			employment: '',
			education: '',
			political: [],
			ethnic: [],
			religious: [],
			country,
			state,
			citizenship: '',
		});

	const citizenshipOptions = [
		'Natural',
		'Naturalized',
		'Immigrant',
		'Undocumented',
		'Dual-citizen',
		'Other',
		'Rather not say',
	];

	useEffect(() => {
		// setValidForm(![...Object.keys(formData)].filter(fd => !fd.length).length);
		const isThisValid = [...Object.keys(formData)].filter(
			key => !formData[key].length
		);
		console.log('OUTPUT: DemographicInfo -> isThisValid', isThisValid);

		setValidForm(
			![...Object.keys(formData)].filter(key => !formData[key].length).length
		);
	}, [formData]);

	const householdOptions = [
		'Single, never married',
		'Married or domestic partnership',
		'Widowed',
		'Divorced',
		'Separated',
	];

	const employmentOptions = [
		'Employed',
		'Self-employed',
		'Unemployed',
		'A homemaker',
		'A student',
		'Military',
		'Retired',
		'Unable to work',
	];

	const educationOptions = [
		'Some high school, or less',
		'High school graduate or equivalent',
		'Some college credit, no degree',
		'Vocational training/Associates',
		'Bachelor’s degree',
		'Beyond bachelor’s',
	];

	const [checkboxInput, setCheckboxInput] = useState({
		politicalInput: '',
		ethnicInput: '',
		religiousInput: '',
	});

	const {
		household,
		employment,
		education,
		political,
		ethnic,
		religious,
		citizenship,
	} = formData;

	const makeObj = input => ({ value: input, checked: true });

	const _reminder_data = {
		_class: '_center-rem',
		msg: 'Comma separated',
	};

	const onChange = e => {
		const { value, name } = e.target;
		switch (name) {
			case 'political':
			case 'ethnic':
			case 'religious':
				const indexOldElement = formData[name].findIndex(cb => cb.value === value);
				setFormData({
					...formData,
					[name]: [
						...formData[name].slice(0, indexOldElement),
						...formData[name].slice(indexOldElement + 1),
					],
				});
				break;
			case 'politicalInput':
			case 'ethnicInput':
			case 'religiousInput':
				// I hate this
				const formFieldName = name.slice(0, name.length - 5);
				const parsedOutput = _parseInput(value, [...formData[formFieldName]], true);
				switch (parsedOutput) {
					case '_goodInput':
						setCheckboxInput({
							...checkboxInput,
							[name]: value,
						});

						if (value.length > 0) {
							if (!checkboxInput[name].length && value.length === 1) {
								const { [formFieldName]: cloneToPushTo } = formData;
								setFormData({
									...formData,
									[formFieldName]: [...cloneToPushTo, makeObj(value)],
								});
							} else if (value.length) {
								const { [formFieldName]: containsIndexToSync } = formData;
								setFormData({
									...formData,
									[formFieldName]: [...containsIndexToSync.slice(0, -1), makeObj(value)],
								});
							}
						}

						if (!value.length && checkboxInput[name].length) {
							const { [formFieldName]: containsIndexToPop } = formData;
							setFormData({
								...formData,
								[formFieldName]: containsIndexToPop.slice(0, -1),
							});
						}
						break;
					case '_invalid':
						setFormData({ ...formData, [name]: '' });
						setAlert({
							msg: 'Invalid input: only use normal characters with a space or hyphen.',
							alertType: 'primary',
						});
						break;
					case '_dups':
						setFormData({ ...formData, [name]: '' });
						setAlert({ msg: 'no duplicates', alertType: 'primary' });
						break;
					case '_limit':
						setFormData({ ...formData, [name]: '' });
						setAlert({ msg: 'limit reached', alertType: 'primary' });
						break;
					default:
						// we got a comma char
						setCheckboxInput({
							...checkboxInput,
							[name]: '',
						});
						setFormData({
							...formData,
							[formFieldName]: [
								...formData[formFieldName].slice(0, -1),
								makeObj(parsedOutput),
							],
						});
						break;
				}
				break;

			default:
				console.log('OUTPUT: DemographicInfo -> value', value);
				setFormData({ ...formData, [name]: value });
				break;
		}
	};

	console.log('LINE: 217 formData', formData);

	useEffect(() => {
		checkAndTransLabels({ formData });
	}, [formData]);

	return (
		<Fragment>
			<p className='m-2'>
				<em className=' form-text'>
					The collected data is only used to help understand the nature of online
					censorship.
				</em>
			</p>
			<small className='form-text m-1'>
				<span className='red'>*</span> Fill out all fields to the best of your
				ability.
			</small>
			<form
				className='form'
				onKeyPress={e => {
					if (detectCharCodes({ e, charCodes: 13 })) {
						const formGroups = [...document.querySelectorAll('.form-group')];
						formGroups.forEach((el, index, array) => {
							if (el.contains(e.target)) {
								if (index > 2) {
									e.preventDefault();
									e.target.value = `${e.target.value},`;
									onChange(e);
								}
								// if (index < array.length - 1) {
								//   e.preventDefault();
								//   array[index + 1].querySelector('input').focus();
								// }
							}
						});
					}
					// setValidForm(
					//   ![...e.currentTarget.querySelectorAll('input')].filter(
					//     f => !f.checkValidity()
					//   ).length
					// );
				}}
				onSubmit={e => {
					const {
						political: cBPolitical,
						religious: CBReligious,
						ethnic: cBEthnic,
					} = formData;

					const political = cBPolitical.map(({ value }) => value);
					const religious = CBReligious.map(({ value }) => value);
					const ethnic = cBEthnic.map(({ value }) => value);

					!e.preventDefault() &&
						demographicInfo({ ...formData, political, religious, ethnic }, history);
				}}>
				<div className='form-group'>
					<small className='form-text'>Household</small>
					<RadioGroup
						name='household'
						onChange={onChange}
						options={householdOptions}
						value={household}
						required='required'
					/>
				</div>

				<div className='form-group'>
					<small className='form-text'>Employment</small>
					<RadioGroup
						name='employment'
						onChange={onChange}
						options={employmentOptions}
						value={employment}
						required='required'
					/>
				</div>

				<div className='form-group'>
					<small className='form-text'>Education</small>
					<RadioGroup
						name='education'
						onChange={onChange}
						options={educationOptions}
						value={education}
						required='required'
					/>
				</div>

				<div className='form-group'>
					<CheckboxGroup
						name='political'
						onChange={onChange}
						options={political}
						className='m-0-1'
					/>
					<div className='use-trans-label ' style={{ margin: '2rem .2rem' }}>
						<input
							type='text'
							name='politicalInput'
							value={checkboxInput.politicalInput}
							onChange={onChange}
							onFocus={e => _handleReminder({ e, ..._reminder_data })}
							onBlur={e => {
								labelTransNotEmpty(e);
								_rmReminder(e);
							}}
						/>
						<label htmlFor=''>Political Leanings</label>
					</div>
				</div>
				<div className='form-group '>
					<CheckboxGroup
						name='ethnic'
						onChange={onChange}
						options={ethnic}
						className='m-0-1'
					/>
					<div className='use-trans-label' style={{ margin: '2rem .2rem' }}>
						<input
							type='text'
							name='ethnicInput'
							value={checkboxInput.ethnicInput}
							onChange={onChange}
							onFocus={e => _handleReminder({ e, ..._reminder_data })}
							onBlur={e => {
								labelTransNotEmpty(e);
								_rmReminder(e);
							}}
						/>
						<label htmlFor=''>Ethnic identities</label>
					</div>
				</div>

				<div className='form-group'>
					<CheckboxGroup
						name='religious'
						onChange={onChange}
						options={religious}
						className='m-0-1'
					/>
					<div className='use-trans-label' style={{ margin: '2rem .2rem' }}>
						<input
							type='text'
							name='religiousInput'
							value={checkboxInput.religiousInput}
							onChange={onChange}
							onFocus={e => _handleReminder({ e, ..._reminder_data })}
							onBlur={e => {
								labelTransNotEmpty(e);
								_rmReminder(e);
							}}
						/>
						<label htmlFor=''>Religion</label>
					</div>
				</div>

				<LocationSelect {...{ formData, setFormData }} />

				<div className=' m-1'>
					<small className='form-text mx-1'>Citizenship</small>
					<RadioGroup
						name='citizenship'
						onChange={onChange}
						options={citizenshipOptions}
						value={citizenship}
					/>
				</div>

				{/* <input type='submit' style={{ display: 'none' }} /> */}
				<input type='submit' id='submit-demography' style={{ display: 'none' }} />

				<button
					htmlFor='submit-demography'
					className={`submit-btn m-1 btn btn-big btn-${
						validForm ? 'primary' : 'white'
					}`}>
					<span>Submit</span>
				</button>
			</form>
		</Fragment>
	);
};

DemographicInfo.propTypes = {
	demographicInfo: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = state => ({
	auth: state.auth,
});
export default connect(mapStateToProps, { demographicInfo, setAlert })(
	withRouter(DemographicInfo)
);
