import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CheckboxGroup from '../common/CheckboxGroup';
import RadioGroup from '../common/RadioGroup';
import { _handleReminder, _rmReminder } from '../common/_reminder.js';
import {
	transTextareaFocus,
	transTextareaBlur,
	hasExistence,
} from '../common/_utilities';
import { labelTransNotEmpty, genOnChange } from './_profileFormUtilities';
import { crop } from '../common/_utilities';
import useTagDetection from '../common/hooks/useTagDetection';
import { ImgModal } from '../post/PostImg';
import { parsePicsUser } from '../common/_utilities';
import LocationSelect from '../common/LocationSelect';
import { setAlert } from '../../actions/alert.js';
import { createProfile } from '../../actions/authProfile';
import { checkAndTransLabels } from './_profileFormUtilities';
import { findByUsername } from '../../actions/posts';
import TagSuggestions from '../common/TagSuggestions';
import { detectCharCodes } from '../common/_utilities';

const ProfileForm = ({
	auth: { user },
	setFormData,
	formData,
	isEdit = false,
	setAlert,
	createProfile,
	findByUsername,
	history,
}) => {
	const [usernameStatusClass, setUsernameStatusClass] = useState(null);

	const [currProfilePic, setCurrProfilePic] = useState(parsePicsUser(user));

	const onChange = genOnChange({
		usernameStatusClass,
		setUsernameStatusClass,
		formData,
		setFormData,
		setAlert,
	});

	const onSubmit = e => {
		e.preventDefault();
		createProfile(
			{
				...formData,
				pcStatus: formData.pcStatus.filter(p => p.checked && p.value !== 'Other'),
			},
			history,
			true
		);
	};

	const [overSubmit, setOverSubmit] = useState(false);

	const {
		username,
		age,
		gender,
		genderCustom,
		bio,
		pcStatus,
		pcStatusOther,
	} = formData;

	const [modalData, setModalData] = useState({ src: '', _id: '', text: '' }),
		[validForm, setValidForm] = useState(false);

	useEffect(() => {
		setValidForm(
			![...document.querySelectorAll('input')].filter(f => !f.checkValidity())
				.length
		);
	}, [formData]);

	useEffect(() => {
		checkAndTransLabels({ formData });
	}, [formData]);

	const {
		handleOnKeyDown,
		handleOnKeyPress,
		handleOnKeyCapture,
		tagSuggestions,
		completeTag,
	} = useTagDetection({
		formData,
		setFormData,
		findByUsername,
		field: 'bio',
		id: 'bio',
	});

	return (
		// <Fragment>
		<div className=''>
			{isEdit && (
				<div className='head-btn'>
					<div className='btn btn-light ' onClick={() => history.goBack()}>
						<span>Go back</span>
					</div>
				</div>
			)}

			<small className='form-textm m-1'>
				<span className='red'>*</span> = required field
			</small>
			<form className='form ' onSubmit={e => onSubmit(e)}>
				<div className={`form-group status-indicator ${usernameStatusClass}`}>
					<div className='use-trans-label'>
						<input
							id='username'
							type='text'
							name='username'
							value={username}
							onChange={onChange}
							onBlur={labelTransNotEmpty}
							spellcheck='false'
							required
						/>
						{/* <label htmlFor='username' className={isEdit && 'hide'}> */}
						<label htmlFor='username'>
							<span className='red'>*</span> Username
						</label>
					</div>
				</div>

				<LocationSelect {...{ formData, setFormData, isEdit }} />

				<div className='form-group'>
					<small className='form-text mx-1'>
						<span className='red'>*</span> Gender
					</small>
					<RadioGroup
						name='gender'
						onChange={onChange}
						options={['Male', 'Female', 'Rather not say', 'Custom']}
						value={gender}
					/>
					{gender === 'Custom' && (
						<div className='use-trans-label m-2'>
							<input
								id='genderCustom'
								type='text'
								name='genderCustom'
								maxLength='21'
								value={genderCustom}
								onChange={onChange}
								onBlur={labelTransNotEmpty}
								required
							/>
							<label htmlFor='genderCustom'>
								<span className='red'>*</span> Custom Gender
							</label>
						</div>
					)}
				</div>

				<div className='form-group '>
					<small className='form-text m-1'>
						<span className='red'>*</span> Age ≈{' '}
						{age > 1 && (
							<em onChange={onChange} className='age-badge'>
								{age}
							</em>
						)}
					</small>
					<div className='slidecontainer'>
						<input
							type='range'
							min='1'
							max='100'
							value={age}
							className='slider'
							name='age'
							onChange={onChange}
							id='r'
							required
						/>
					</div>
				</div>

				<div className='form-group '>
					<small className='form-text m-0-5'>
						Areas of life which have been affected by some form of{' '}
						<em>
							<b>censorship</b>
						</em>
					</small>
					<CheckboxGroup name='pcStatus' onChange={onChange} options={pcStatus} />

					{Array.isArray(pcStatus) &&
						pcStatus.filter(e => e.value === 'Other' && e.checked).length > 0 && (
							<div className='m-2 use-trans-label'>
								<input
									id='pcStatusOther'
									type='text'
									name='pcStatusOther'
									value={pcStatusOther}
									onChange={onChange}
									autoFocus
									onFocus={e =>
										_handleReminder({
											e,
											_class: '_center-rem',
											msg: 'Comma separated',
										})
									}
									onBlur={e => {
										labelTransNotEmpty(e);
										_rmReminder(e);
									}}
									onKeyPress={e => {
										if (detectCharCodes({ e, charCodes: 13 })) {
											e.preventDefault();
											e.target.value = `${e.target.value},`;
											onChange(e);
										}
									}}
								/>
								<label htmlFor='pcStatusOther'>Other</label>
							</div>
						)}
				</div>

				<div className='form-group'>
					<div className='use-trans-label'>
						<textarea
							id='bio'
							// id='form-textarea'
							name='bio'
							value={bio}
							onChange={onChange}
							onFocus={transTextareaFocus}
							onKeyDown={handleOnKeyDown}
							onKeyUpCapture={handleOnKeyCapture}
							onKeyPress={handleOnKeyPress}
							onBlur={e => !labelTransNotEmpty(e) && transTextareaBlur(e, overSubmit)}
							maxLength='540'
							rows='3'
						/>
						{/* <label htmlFor='bio' className={isEdit && 'hide'}> */}
						{/* <label htmlFor='bio'>Bio</label> */}
						{/* <label className='label-not-empty' htmlFor='form-textarea'> */}
						<label htmlFor='bio'>
							<span>Bio</span>
						</label>
					</div>

					<TagSuggestions
						{...{
							tagSuggestions,
							completeTag,
							positioningClass: 'ts-add-censor-event',
						}}
					/>

					<small className='form-text mx-1'>
						Any additional info to include in your profile
					</small>
				</div>
				<div className='form-group'>
					<input
						type='file'
						id='profilePic'
						className='invisible'
						// btn btn-light'
						onChange={e => {
							if (e.target.files.length > 0) {
								let file = e.target.files[0] || e.dataTransfer.files[0];
								let reader = new FileReader();
								reader.onloadend = () => {
									const readerResult = reader.result;
									crop(readerResult, 1, 300, 'pic-preview', formData, setFormData);
								};
								reader.readAsDataURL(file);
							}
						}}
					/>

					<div className='center-btn-label'>
						<div>
							<small className='form-text'>
								{typeof currProfilePic === 'string' &&
								!currProfilePic.includes('ui-avatar')
									? 'Edit Profile Pic'
									: 'Change Default Pic'}
							</small>
							<label for='profilePic' style={{ fontSize: '1.5rem', margin: '1rem ' }}>
								<span>
									<i className='far fa-image' />
								</span>
							</label>
						</div>
					</div>
					{!!modalData._id && (
						<ImgModal {...{ modalData, setModalData, additionalClasses: 'br-50' }} />
					)}
					{/* on pic input, img is changed in crop by element id */}
					<div className='form-img-preview'>
						<div className='img-cont'>
							<img
								src={currProfilePic}
								className='br-50'
								id='pic-preview'
								alt='img-preview'
								onClick={e => {
									setModalData({
										src: e.target.src,
										_id: 1,
										text: formData.info,
									});
								}}
							/>
						</div>
						{((typeof currProfilePic === 'string' &&
							!currProfilePic.includes('ui-avatar')) ||
							hasExistence(formData, 'imgFile')) && (
							<span
								className='remove-preview'
								onClick={() => {
									const imgEl = document.getElementById('pic-preview');
									const { imgFile, ...persist } = formData;
									if (imgEl.src.includes('ppic')) {
										setCurrProfilePic(user.avatar);
										setFormData({
											...persist,
											removePpic: true,
										});
									} else {
										imgEl.src = currProfilePic;
										setFormData({ ...persist });
									}
								}}>
								<i className='fas fa-times' />
							</span>
						)}
					</div>
				</div>
				<input
					type='submit'
					// className='invisible'
					style={{ display: 'none' }}
					id='submit-profile'
				/>
				<button
					htmlFor='submit-profile'
					className={`submit-btn m-1  btn btn-big btn-${
						validForm ? 'primary' : 'white'
					}`}
					onMouseEnter={() => setOverSubmit(true)}
					onMouseLeave={() => setOverSubmit(false)}>
					<span>Submit</span>
				</button>
			</form>
		</div>
	);
};

ProfileForm.propTypes = {
	usernameStatusClass: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired,
	onSubmit: PropTypes.func.isRequired,
	setFormData: PropTypes.func.isRequired,
	formData: PropTypes.object.isRequired,
	populateState: PropTypes.func.isRequired,
	generatedRegions: PropTypes.array.isRequired,
	currProfilePic: PropTypes.string.isRequired,
};

const mapStateToProps = state => ({
	auth: state.auth,
});

export default connect(mapStateToProps, {
	setAlert,
	createProfile,
	findByUsername,
})(withRouter(ProfileForm));
