import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import copy from 'copy-to-clipboard';
import PropTypes from 'prop-types';
import { _handleReminder, _rmReminder } from '../common/_reminder';
import { labelTransNotEmpty } from '../profile-forms/_profileFormUtilities';
import { EMAIL_REGEX } from '../common/_constants';
import { editEmail } from '../../actions/auth';
import axios from 'axios';
import { setAlert } from '../../actions/alert';

const DashEditEmailToken = ({
	auth: {
		user: { email },
		profile,
	},
	editEmail,
	setAlert,
}) => {
	const history = useHistory();

	const [formData, setFormData] = useState({ token: '' });
	const [validForm, setValidForm] = useState(false);

	const onChange = ({ target: { name, value } }) => {
		console.log('LINE2: 27  name ', name);
		setFormData({ ...formData, [name]: value });
	};

	return (
		<form
			key='token-edit-email-form'
			className='form'
			onSubmit={e => {
				e.preventDefault();
				editEmail(formData, history);
			}}
			onKeyUp={e =>
				setValidForm(
					![...e.currentTarget.querySelectorAll('input')].filter(f =>
						f.type === 'email' ? !EMAIL_REGEX.test(f.value) : !f.checkValidity()
					).length
				)
			}>
			<div className='form-group'>
				<small className='form-text m-1'>
					Check your updated email and enter the token.
				</small>
				<div className='use-trans-label'>
					<input
						id='edit-email-token'
						type='text'
						name='token'
						value={formData.token}
						onChange={onChange}
						onBlur={labelTransNotEmpty}
						spellcheck='false'
						required
					/>
					<label htmlFor='edit-email-token'>
						<span>token</span>
					</label>
				</div>
				<input
					type='submit'
					id='submit-edit-email-2'
					style={{ display: 'none' }}
				/>
				<button
					htmlFor='submit-edit-email-2'
					className={`btn my-2 btn-${validForm ? 'primary' : 'white'}`}
					style={{ marginLeft: '75%' }}>
					<span>Change email</span>
				</button>
			</div>
		</form>
	);
};

DashEditEmailToken.prototypes = {
	auth: PropTypes.object.isRequired,
	editEmail: PropTypes.func.isRequired,
	setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => ({
	auth,
});

export default connect(mapStateToProps, { editEmail, setAlert })(
	DashEditEmailToken
);
