import React, { Fragment, useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';

import Moment from 'react-moment';
import * as moment from 'moment';
import _ from 'lodash';

import qs from 'qs';

// .social-input {
//   display: flex;

//   i {
//     padding: 0.5rem;
//     width: 4rem;

//     &.fa-twitter {
//       color: #38a1f3;
//     }

//     &.fa-facebook {
//       color: #3b5998;
//     }

//     &.fa-instagram {
//       color: #3f729b;
//     }

//     &.fa-youtube {
//       color: #c4302b;
//     }

//     &.fa-linkedin {
//       color: #0077b5;
//     }
//   }
// }

// note: need to add more platforms
export const makeAwesome = function (platform) {
	switch (platform.toLowerCase()) {
		case 'twitter':
			return <i className='fab icons fa-twitter fa-2x' />;
			break;
		case 'youtube':
			return <i className='fab icons fa-youtube fa-2x' />;
			break;
		case 'linkedin':
			return <i className='fab icons fa-linkedin fa-2x' />;
			break;
		case 'facebook':
			return <i className='fab icons fa-facebook fa-2x' />;
			break;
		case 'instagram':
			return <i className='fab icons fa-instagram fa-2x' />;
			break;
		default:
			return <i className='far icons fa-smile fa-2x' />;
			break;
	}
};

export const crop = function (
	url,
	aspectRatio,
	targetWH,
	imgElId,
	formData,
	setFormData
) {
	const inputImage = new Image(targetWH, targetWH);

	inputImage.onload = () => {
		// let's store the width and height of our image
		const inputWidth = inputImage.naturalWidth;
		const inputHeight = inputImage.naturalHeight;

		// get the aspect ratio of the input image
		const inputImageAspectRatio = inputWidth / inputHeight;

		// if it's bigger than our target aspect ratio
		let outputWidth = inputWidth;
		let outputHeight = inputHeight;
		if (inputImageAspectRatio > aspectRatio) {
			outputWidth = inputHeight * aspectRatio;
		} else if (inputImageAspectRatio < aspectRatio) {
			outputHeight = inputWidth / aspectRatio;
		}

		// calculate the position to draw the image at
		const outputX = (outputWidth - inputWidth) * 0.5;
		const outputY = (outputHeight - inputHeight) * 0.5;

		// create a canvas that will present the output image
		const outputImage = document.createElement('canvas');

		// set it to the same size as the image
		outputImage.width = outputWidth;
		outputImage.height = outputHeight;

		// draw our image at position 0, 0 on the canvas
		const ctx = outputImage.getContext('2d');
		ctx.drawImage(inputImage, outputX, outputY);
		// resolve(outputImage);
		//

		outputImage.toBlob(blob => {
			setFormData({
				...formData,
				imgFile: new File([blob], 'profile-pic.png', {
					type: 'image/png',
				}),
			});
		});
		document.getElementById(imgElId).src = outputImage.toDataURL();
	};

	// start loading our image
	inputImage.src = url;
};

export const Clock = () => {
	const [time, setTime] = useState(Date());
	useEffect(() => {
		const timerId = setInterval(() => {
			setTime(Date());
		}, 60000);
		return () => {
			clearInterval(timerId);
		};
	}, [setTime]);
	return (
		<div className='bg-primary'>
			<Moment format='dddd LT'>{time}</Moment>
		</div>
	);
};

// Post/CommentForm
const initalHeight = 40,
	focusedHeight = 120;
export const transTextareaFocus = e => {
	if (e.target.offsetHeight === initalHeight)
		e.target.style.height = `${focusedHeight}px`;
};

export const transTextareaBlur = (e, overSubmit) => {
	e.target.offsetHeight === focusedHeight &&
		!overSubmit &&
		(e.target.style.height = `${initalHeight}px`);
};

export const handleCloseDropdown = (window.onclick = e => {
	// e.preventDefault();
	// TODO this fixes reload on trending click when filter dropdown is open, but  breaks login submit btn
	!e.target.classList.contains('drop-option') && e.stopPropagation();

	[...document.getElementsByClassName('slide-down')].forEach(dd =>
		dd.classList.remove('slide-down')
	);
});

export const toggleClass = (el, className) => {
	if (el.classList.contains(className)) el.classList.remove(className);
	else el.classList.add(className);
};

export const toggleDropdownById = id => {
	const dropdown = document.getElementById(id);
	toggleClass(dropdown, 'slide-down');
};

export const dropdownHandler = e => {
	e.stopPropagation();
	const ddParent = e.currentTarget;
	[...document.getElementsByClassName('slide-down')].forEach(
		dd => !ddParent.isSameNode(dd) && dd.classList.remove('slide-down')
	);
	toggleClass(ddParent, 'slide-down');
	window.addEventListener('click', handleCloseDropdown, {
		once: true,
		capture: true,
	});
};

export const scrollToElementById = (id, expand = false) => {
	const toScrollTo = document.getElementById(id);

	if (toScrollTo) {
		const { top } = toScrollTo.getBoundingClientRect();

		const { offsetHeight: headerOffset } = document.getElementsByClassName(
			'header'
		)[0];

		const target = Math.abs(top - headerOffset);

		const container = document.getElementById('container');

		const { clientHeight: c_clientHeight } = container;

		if (c_clientHeight < target + window.screen.height && expand) {
			container.style.height = `${target + window.screen.height}px`;
		}

		toScrollTo.scrollIntoView();

		window.scrollBy({
			top: -(headerOffset + 19),
			behavior: 'smooth',
		});
	}
};

export const useScrollToElementById = (id, condition = true, did = []) => {
	console.log('common/_utilities.js: 216  condition ', condition);
	useEffect(() => {
		if (condition) scrollToElementById(id, true);
		return () => (document.getElementById('container').style.height = 'auto');
	}, did);
};

// pass auth parameter
// check for thum_path should be able to be removed as everyone should have a thumb, but for dev right now they do not
export const parsePicsUser = ({ avatar, profilePic }, thumb) => {
	const picPathParse = profilePic
		? thumb && profilePic.thumb_path
			? profilePic.thumb_path.replace('public', '')
			: profilePic.path.replace('public', '')
		: false;
	return picPathParse || avatar;
};

moment.updateLocale('en', {
	relativeTime: {
		future: 'in %s',
		past: '%s ago',
		// s: 'a few seconds',
		s: 'just now',
		// ss: '%d seconds',
		ss: 'just now',
		m: 'a minute',
		mm: '%d minutes',
		h: 'an hour',
		hh: '%dh',
		d: 'a day',
		dd: '%dd',
		M: 'a month',
		MM: '%d months',
		y: 'a year',
		yy: '%d years',
	},
});

export const UpdatedMoment = moment;

export const hashtagify = (text, history) => {
	if (!text) return;

	const words = text.split(/(\s)/);

	const replaceHash = word => {
		if (typeof word === 'string') {
			const regexHash = /(?<before>.*)(?<hash>#[a-z0-9_]+)(?<after>.*)/gi;
			const hashMatchArr = [...word.matchAll(regexHash)];

			if (hashMatchArr.length) {
				const { groups } = hashMatchArr[0];

				return (
					<Fragment>
						{groups.before}
						<Link
							onClick={e => e.stopPropagation()}
							to={`/hashtag/${groups.hash.slice(1)}`}>
							{groups.hash}
						</Link>
						{groups.after}
					</Fragment>
				);
			} else return word;
		} else return word;
	};

	const replaceTag = word => {
		if (typeof word === 'string') {
			const regexTag = /(?<before>.*)(?<tag>@[a-z0-9_]{5,15})(?<after>.*)/gi;
			const tagMatchArr = [...word.matchAll(regexTag)];

			if (tagMatchArr.length) {
				const { groups } = tagMatchArr[0];

				return (
					<Fragment>
						{groups.before}
						<Link
							onClick={e => e.stopPropagation()}
							to={`/profile/${groups.tag.slice(1)}`}>
							{groups.tag}
						</Link>
						{groups.after}
					</Fragment>
				);
			} else return word;
		} else return word;
	};

	const replaceLink = word => {
		if (typeof word === 'string') {
			const regexLink = /(?<protocol>http[s?]?:\/\/)?(?:w{3}\.)?(?<link>[-a-z0-9@:%._\+~#=]{1,256}\.[a-z0-9()]{1,6}\b([-a-z0-9()@:%_\+.~#?&//=]*))/gi;

			const linkMatchArr = [...word.matchAll(regexLink)];

			if (linkMatchArr.length) {
				const { groups } = linkMatchArr[0];
				return (
					<a
						href={!groups.protocol ? `http://${groups.link}` : linkMatchArr[0][0]}
						target='_blank'
						rel='noopener noreferrer'>
						{groups.link}
					</a>
				);
			} else return word;
		} else return word;
	};

	const hashReplaced = words.map(w => replaceHash(w));
	const tagsReplaced = hashReplaced.map(w => replaceTag(w));
	const linksReplaced = tagsReplaced.map(w => replaceLink(w));
	return linksReplaced;
};

export const ShowThread = ({ _id, postType, user }, history) => (
	<div
		className='thread-item-cont show-thread post'
		onClick={() =>
			history.push(
				`/posts/${
					postType === 'BasePost' ? 'Post' : postType
				}/${_id}/posterior-thread-cont`
			)
		}>
		<div className='pic-thread'>
			<div className='thread-connector top-connector' />
			<div className='profile-pic-cont img-cont pe-none'>
				<img
					className='show-thread-pic br-50'
					src={parsePicsUser(user, 'thumb')}
					alt='avatar'
				/>
			</div>
		</div>
		<div className='thread-post '>
			<a href='#!' onClick={e => e.preventDefault()}>
				Show thread
			</a>
		</div>
	</div>
);

export const queryStringToObject = queryString => {
	// const params = new URLSearchParams(queryString);

	const params = qs.parse(queryString, { ignoreQueryPrefix: true });

	// console.log('common/_utilities.js: 384  params ', params);

	return objStrToNum(params);
	// return params;
};

// export const buildPlatformQuerySting({location})=>{
//   const url = new URL(location);

//   const { skipIndex, ...persistFilter } = queryStringToObject(url.search);
// new URLSearchParams({...persistentQuery,sortBy: })
// }

export const firstName = name =>
	name
		.split(' ')
		.map(n => `${n[0].toUpperCase()}${n.slice(1)}`)
		.filter((name, index) => index === 0)[0];

//works but not being used on front-end
// export const parseHashtags = text =>
//   text
//     .split(' ')
//     .filter(tag => tag.match(/^(#)[a-z0-9]{0,33}$/i))
//     .map(tag => tag.substring(1));

// export const EmptyPost = () => (
//   <div className='post-cont'>
//     <span>No data.</span>
//   </div>
// );

export const hasExistence = (obj, property) =>
	_.omitBy(obj, _.isNil).hasOwnProperty(property);

export const hasNestedExistence = (obj, properties_arr) =>
	properties_arr.filter((prop, i, arr) => {
		const prevObj = !!i && _.omitBy(obj, _.isNil)[arr[i - 1]];

		return !i
			? _.omitBy(obj, _.isNil).hasOwnProperty(prop)
			: prevObj && prevObj.hasOwnProperty(prop);
	}).length === properties_arr.length;

// export const genKey = function () {
// 	// Math.random should be unique because of its seeding algorithm.
// 	// Convert it to base 36 (numbers + letters), and grab the first 9 characters
// 	// after the decimal.
// 	return '_' + Math.random().toString(36).substr(2, 9);
// };

export const detectCharCodes = ({ e, charCodes }) => {
	const charCode = e.which || e.keyCode;
	console.log('LINE2: 440  charCode ', charCode);
	if (Array.isArray(charCodes)) {
		return charCodes.includes(charCode);
	} else {
		return charCode === charCodes;
	}
};

export const postActionsAuthWrapper = ({
	postActionCreators,
	auth,
	history,
}) => {
	const actionKeys = Object.keys(postActionCreators);
	const wrappedPostActions = {};
	actionKeys.forEach(key => {
		wrappedPostActions[key] = args =>
			auth.isAuthenticated
				? postActionCreators[key](args)
				: history.push('/login');
	});
	return wrappedPostActions;
};

export const objStrToNum = myObj => {
	const keys = Object.keys(myObj);
	// console.log('common/_utilities.js: 473  keys ', keys);
	return keys.length
		? Object.assign(
				...keys.map(key =>
					typeof myObj[key] === 'object'
						? { [key]: objStrToNum(myObj[key]) }
						: isNaN(myObj[key])
						? { [key]: myObj[key] }
						: { [key]: Number(myObj[key]) }
				)
		  )
		: {};
};

export const parseQuerySelection = ({ CONSTANT_OBJ, query }) => {
	const currSelection = CONSTANT_OBJ.options.filter(
		({ value }) => value === query[CONSTANT_OBJ.key]
	);

	const { value } = currSelection.length
		? currSelection[0]
		: CONSTANT_OBJ.default;

	return value;
};
