import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
// import Moment from 'react-moment';
import { connect } from 'react-redux';
import { deleteActiveAcct } from '../../actions/authProfile';
import util from 'util';

const DashActive = ({
	auth: { user },
	deleteActiveAcct,
	setHeader,
	history,
}) => {
	const { activeAccts } = user;
	const [showUnlist, setShowUnlist] = useState({ initial: false });
	setHeader(<h1 className='text-primary'>Your Active Accounts</h1>);

	// useEffect(() => {
	//   console.log(`active_useEff: ${util.inspect(showUnlist)}`);
	// }, [showUnlist]);

	const ActiveAccts = activeAccts.map((act, index) => {
		return (
			<tr
				key={act.id}
				onClick={e => {
					history.push(`/profile-id/${user._id}/${act._id}`);
				}}
				className={showUnlist[act.id] ? 'highlight-del' : ''}>
				<td>{act.platform}</td>
				<td className='hide-md'>{act.handle}</td>
				<td className='dash-delete-date'>
					{showUnlist[act.id] && (
						<button
							onClick={e => {
								e.stopPropagation();
								window.confirm('Are you sure? This CANNOT be undone!') &&
									deleteActiveAcct(act.id);
							}}
							className='btn btn-danger  delete-btn '>
							<span>
								<i className='fas fa-times' /> Unlist
							</span>
						</button>
					)}
					{!showUnlist[act.id] && (
						<a href={`${act.url}`} target='_blank' rel='noopener noreferrer'>
							{act.platform}
						</a>
					)}
				</td>
				<td
					className='dash-delete-ellipsis'
					onClick={e => {
						e.stopPropagation();
						setShowUnlist({
							...showUnlist,
							[act.id]: !showUnlist[act.id],
						});
					}}>
					<div>
						{/* <i className='fas fa-caret-left' /> */}
						<i className='fas fa-ellipsis-v' />
					</div>
				</td>
			</tr>
		);
	});

	return (
		<div className='dash-table-cont'>
			<h2 className='mx-2'>Active Accounts</h2>
			<table className='table'>
				<thead>
					{ActiveAccts.length ? (
						<tr>
							<th>Platform</th>
							<th className='hide-md'>Handle</th>
							<th>Link</th>
							<th>
								<i className='fas fa-times' />
							</th>
						</tr>
					) : (
						<tr>
							<th>No active accounts</th>
						</tr>
					)}
				</thead>
				<tbody>{ActiveAccts}</tbody>
			</table>
		</div>
	);
};

DashActive.propTypes = {
	auth: PropTypes.object.isRequired,
	deleteActiveAcct: PropTypes.func.isRequired,
	setHeader: PropTypes.func,
};

const mapStateToProps = ({ auth }) => ({
	auth,
});

export default connect(mapStateToProps, { deleteActiveAcct })(DashActive);
// remove http(s) for display
// const displayUrl = act.url.replace(/^\/\/|^.*?:\/\//, '');

{
	/* <a href={`${act.url}`}>{displayUrl}</a>{' '} */
}
{
	/* >${act.url}.replace(/^\/\/|^.*?:\/\//, '')</a>{' '} */
}
{
	/* <a href={`https://www.${act.url}`}>{act.url}</a>{' '} */
}
