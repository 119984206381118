import React, { Fragment } from 'react';
import { NavLink, withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout } from '../../actions/auth';
import { deactivateNotifications } from '../../actions/notifications';
import { _handleReminder, _rmReminder } from '../common/_reminder.js';
import { parsePicsUser } from '../common/_utilities';
import { SUBMISSION_DATE, DATE_SORT } from '../common/_shared_constants';

const NavLinks = ({
	auth: { isAuthenticated, user },
	history,
	notifications: { activeNotifications },
	censorPosts: { query: censorQuery },
	explorePosts: { query: exploreQuery },
}) => {
	const AuthLinks = () => {
		// there is a bug here when logged out

		const { profile, avatar } = isAuthenticated && user;

		return (
			<ul>
				<li
					onMouseEnter={e => _handleReminder({ e, _class: 'my-3', msg: 'Home' })}
					onMouseLeave={_rmReminder}>
					<NavLink to='/home' activeClassName='current-route'>
						<i className='fas fa-home' />
						<span className='hide-lg'>Home</span>
					</NavLink>
				</li>

				<li
					onMouseEnter={e =>
						_handleReminder({ e, _class: '_index-rem', msg: 'Censorship' })
					}
					onMouseLeave={_rmReminder}>
					{/* <NavLink to={`/censor-eve{bjnts`} activeClassName='current-route'> */}
					<NavLink
						to={`/censor-events/query?${new URLSearchParams(
							Object.keys(censorQuery).length
								? censorQuery
								: {
										[SUBMISSION_DATE.key]: SUBMISSION_DATE.default.value,
								  }
						)}`}
						activeClassName='current-route'>
						<i className='fas fa-meh-blank' />
						<span className='hide-lg'>Censorship</span>
					</NavLink>
				</li>

				{/* //works but needs work */}
				{/* <li
        onMouseEnter={e => _handleReminder({e, _class:'my-3', msg:'Users'})}
        onMouseLeave={_rmReminder}>
        <NavLink to='/profiles'>
          <i className='fas fa-users'></i>
        </NavLink>
      </li> */}
				<li
					onMouseEnter={e => _handleReminder({ e, _class: 'my-3', msg: 'Explore' })}
					onMouseLeave={_rmReminder}>
					<NavLink
						// to={`/explore`}
						// TODO:
						// now that there are more filters, I'm not sure this is the best idea
						to={`/explore/query?${new URLSearchParams(
							Object.keys(exploreQuery).length
								? exploreQuery
								: {
										[DATE_SORT.key]: DATE_SORT.default.value,
								  }
						)}`}
						activeClassName='current-route'>
						<i className='fas fa-globe' />
						<span className='hide-lg'>Explore</span>
					</NavLink>
				</li>

				<li
					onMouseEnter={e =>
						_handleReminder({ e, _class: '_index-rem', msg: 'Notifications' })
					}
					onMouseLeave={_rmReminder}>
					<NavLink
						to={`/notifications`}
						activeClassName='current-route'
						className='badge-parent'
						id='nav-badge-parent'>
						{!!activeNotifications && (
							<div className='notification-badge'>
								{activeNotifications > 9 ? '9+' : activeNotifications}
							</div>
						)}
						<i className='fas fa-bell' />{' '}
						<span className='hide-lg'>Notifications</span>
					</NavLink>
				</li>

				<li
					onMouseEnter={e => _handleReminder({ e, _class: 'my-3', msg: 'Search' })}
					onMouseLeave={_rmReminder}
					className='show-md'>
					<NavLink to='/search' activeClassName='current-route'>
						<i className='fas fa-search' />
						<span className='hide-lg'>Search</span>
					</NavLink>
				</li>

				<li
					onMouseEnter={e => _handleReminder({ e, _class: 'my-1', msg: 'about' })}
					onMouseLeave={_rmReminder}
					className='hide-md nav-about'>
					<NavLink to={`/about`} activeClassName='current-route'>
						<i className='fas fa-info-circle' />
						<span className='hide-lg'>About</span>
					</NavLink>
				</li>

				{profile && (
					<li
						onMouseEnter={e => _handleReminder({ e, _class: 'my-1', msg: user.name })}
						onMouseLeave={_rmReminder}
						className='hide-sm'>
						<NavLink to={`/profile/${user.username}`} activeClassName='current-route'>
							<img
								className='br-50'
								src={parsePicsUser(user, 'thumb')}
								// alt messes up navbar if pic is not there especially on mobile
								// {/* alt='profile-pic' */}
							/>
							<span className='hide-lg'>Profile</span>
						</NavLink>
					</li>
				)}
			</ul>
		);
	};

	const GuestLinks = () => (
		<ul>
			{/* <li>
        <NavLink to='/profiles'>
          <i className='fas fa-users'/>
        </NavLink>
      </li> */}
			<li className='hide-sm'>
				<NavLink to='/search' activeClassName='current-route'>
					<i className='fas fa-search' />
				</NavLink>
			</li>
			<li>
				<NavLink to='/censor-events' activeClassName='current-route'>
					<i className='fas fa-th-list' />
				</NavLink>
			</li>
			<li className='hide-md'>
				<NavLink to='/about' activeClassName='current-route'>
					<i className='fas fa-info-circle' />
				</NavLink>
			</li>
			<li>
				<NavLink to='/register' activeClassName='current-route'>
					SIGN UP
				</NavLink>
			</li>
			<li>
				<NavLink
					to={{
						pathname: '/login',
						state: { prevPath: history.location.pathname },
					}}
					activeClassName='current-route'>
					LOG IN
				</NavLink>
			</li>
		</ul>
	);
	return (
		<Fragment>
			{isAuthenticated && user ? <AuthLinks /> : <GuestLinks />}
		</Fragment>
	);
};

NavLinks.propTypes = {
	logout: PropTypes.func.isRequired,
	auth: PropTypes.object.isRequired,
	profile: PropTypes.object.isRequired,
	notifications: PropTypes.object.isRequired,
};

const mapStateToProps = state => {
	const { auth, profile, notifications, censorPosts, explorePosts } = state;
	return {
		auth,
		profile,
		notifications,
		censorPosts,
		explorePosts,
	};
};

export default connect(mapStateToProps, {
	logout,
	deactivateNotifications,
})(withRouter(NavLinks));
