import axios from 'axios';
import { setAlert } from './alert';
import util from 'util';

import {
	SET_COMMON_DATA,
	SET_TRENDING_HASHTAGS,
	SET_TRENDING_CENSOR,
	COMMON_ERROR,
} from './types';

export const setCommonData = data => async dispatch => {
	dispatch({ type: SET_COMMON_DATA, payload: data });
};

export const getTrendingHashtags = () => async dispatch => {
	try {
		const res = await axios.get('/api/common/trending-hashtags');

		dispatch({
			type: SET_TRENDING_HASHTAGS,
			payload: res.data,
		});
	} catch (err) {
		common_error(err, dispatch);
	}
};

export const getTrendingCensor = () => async dispatch => {
	try {
		const res = await axios.get('/api/common/trending-censor');

		dispatch({
			type: SET_TRENDING_CENSOR,
			payload: res.data,
		});
	} catch (err) {
		common_error(err, dispatch);
	}
};

const common_error = (error, dispatch) => {
	console.error('LINE2: 38  error', error);
	dispatch({
		type: COMMON_ERROR,
		payload: {
			msg: error.response.statusText,
			status: error.response.status,
		},
	});
};
