import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getProfileById } from '../../actions/profile';
import Profile from './Profile';
import Spinner from '../layout/Spinner';

const UsernameProfile = ({
	auth,
	userProfile: { loading },
	getProfileById,
}) => {
	const { userProfileId } = useParams();
	useEffect(() => {
		if (loading) getProfileById(userProfileId, auth.user && auth.user.id);
	}, [loading]);

	return loading ? <Spinner /> : <Profile />;
};

UsernameProfile.propTypes = {
	auth: PropTypes.object.isRequired,
	userProfile: PropTypes.object.isRequired,
	getProfileById: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, userProfile }) => ({
	auth,
	userProfile,
});

export default connect(mapStateToProps, {
	getProfileById,
})(UsernameProfile);
