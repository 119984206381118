import axios from 'axios';
import { setAlert } from './alert';
import util from 'util';

import {
	CLEAR_EXPLORE_POSTS,
	GET_EXPLORE_POSTS,
	SET_LOAD_MORE_EXPLORE,
	SET_SCROLL_Y_EXPLORE,
	SET_EXPLORE_QUERY,
	PREPEND_EXPLORE_POSTS,
	CLEAR_PREPEND_EXPLORE_ALERT,
	EXPLORE_POSTS_ERROR,
} from './types';

export const getExplorePosts = query => async dispatch => {
	console.log('LINE2: 19  query ', query);
	try {
		const res = await axios.put('/api/posts', query);

		dispatch({
			type: GET_EXPLORE_POSTS,
			payload: res.data,
		});
	} catch (error) {
		posts_error(error, dispatch);
	}
};

export const clearPrependExploreAlert = () => dispatch =>
	dispatch({
		type: CLEAR_PREPEND_EXPLORE_ALERT,
	});

export const prependNewExplorePosts = query => async dispatch => {
	try {
		const res = await axios.put('/api/posts/prepend-new', query);
		console.log('LINE2: 42  res ', res);

		console.log('LINE2: 45  query', query);
		dispatch({
			type: query.sortBy > 0 ? GET_EXPLORE_POSTS : PREPEND_EXPLORE_POSTS,
			payload: res.data,
		});
	} catch (error) {
		posts_error(error, dispatch);
	}
};

export const clearExplorePosts = () => async dispatch =>
	dispatch({ type: CLEAR_EXPLORE_POSTS });

export const setLoadMoreExplore = index => dispatch => {
	dispatch({ type: SET_LOAD_MORE_EXPLORE  });
};

export const setScrollYExplore = scrollY => dispatch => {
	// console.log('LINE2: 176  scrollY ', scrollY);
	dispatch({ type: SET_SCROLL_Y_EXPLORE, payload: scrollY });
};

export const setExploreQuery = query => dispatch => {
	try {
		dispatch({
			type: SET_EXPLORE_QUERY,
			payload: query,
		});
	} catch (error) {
		posts_error(error, dispatch);
	}
};

const posts_error = (error, dispatch) => {
	console.log('OUTPUT: posts_error -> error', error);
	const errors = error.response.data.errors;
	dispatch({
		type: EXPLORE_POSTS_ERROR,
		payload: {
			msg: error.response.statusText,
			status: error.response.status,
		},
	});
	if (errors) {
		errors.forEach(({ msg }) => dispatch(setAlert({ msg, alertType: 'danger' })));
		return 400;
	}
};
