import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ProfileForm from './ProfileForm';

const EditProfile = ({ auth: { user } }) => {
	const { profile } = user;
	const [formData, setFormData] = useState({});

	useEffect(() => {
		if (profile && user) {
			const {
				profile: { bio, pcStatus },
				identity: { gender, age },
				location: { country, state, citizenship },
			} = user;

			setFormData({
				age,
				gender: gender.value,
				// gender.custom is being set to 'undefined' as a string somewhere (prob JSON.stringify).. track this down.
				genderCustom:
					!!gender.custom && gender.custom !== 'undefined' ? gender.custom : '',
				// genderCustom: !!gender.custom ? gender.custom : '',
				username: user && user.username,
				pcStatus: [...pcStatus, { value: 'Other', checked: false }],
				country,
				state: state ? state : '',
				citizenship: citizenship ? citizenship : '',
				bio,
			});
		}
	}, []);

	return (
		user && (
			<ProfileForm
				{...{
					formData,
					setFormData,
					isEdit: true,
				}}
			/>
		)
	);
};

EditProfile.propTypes = {
	profile: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	profile: state.profile,
	auth: state.auth,
});

export default connect(mapStateToProps, {})(withRouter(EditProfile));
