import React, { Fragment, useEffect } from 'react';
import {
	Route,
	Switch,
	withRouter,
	useRouteMatch,
	useLocation,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DashCensorship from './DashCensorship';
import DashActive from './DashActive';
import { deactivateNotifications } from '../../actions/notifications';
import { _handleReminder, _rmReminder } from '../common/_reminder';

const DashYourData = ({
	auth: {
		user: { profile },
	},
	setHeader,
	history,
}) => {
	const { path, url } = useRouteMatch();

	const location = useLocation();

	// this maintains the location.state within dash routes and is used by header to go directly back to route before dash
	const dashHistory = {
		...history,
		push: (historyState => path => history.push(path, historyState))(
			location.state
		),
	};

	setHeader(<h1 className='text-primary'>Your Data</h1>);

	return (
		<Fragment>
			<div id='dash-cont' style={{ marginBottom: 'auto' }}>
				<ul className='selection'>
					<Route exact path={path}>
						{profile && (
							<Fragment>
								<li onClick={() => dashHistory.push(`${url}/censor-events`)}>
									<span>Censorship Events</span>
								</li>
								<li onClick={() => dashHistory.push(`${url}/active-accounts`)}>
									<span>Active Accounts</span>
								</li>
							</Fragment>
						)}
					</Route>
				</ul>
				<Switch>
					<Route path={`${path}/censor-events`}>
						<DashCensorship {...{ history: dashHistory, setHeader }} />
					</Route>
					<Route path={`${path}/active-accounts`}>
						<DashActive {...{ history: dashHistory, setHeader }} />
					</Route>
				</Switch>
			</div>
		</Fragment>
	);
};

DashYourData.propTypes = {
	auth: PropTypes.object.isRequired,
	setHeader: PropTypes.func,
};

const mapStateToProps = state => ({
	auth: state.auth,
});

export default connect(mapStateToProps, {
	deactivateNotifications,
})(withRouter(DashYourData));
