import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
	makeAwesome,
	scrollToElementById,
	parsePicsUser,
} from '../common/_utilities';
import { clearUserProfile, followToggle } from '../../actions/profile';
import { connect } from 'react-redux';
import { _rmReminder, _handleReminder } from '../common/_reminder';
import copy from 'copy-to-clipboard';
import { ImgModal } from '../post/PostImg';
import { clearPosts, clearProfilePostsCount } from '../../actions/posts';

const ProfileTop = ({
	userProfile: { userProfile },
	posts: { postsCount },
	isOwner,
	followToggle,
	clearPosts,
	clearUserProfile,
	clearProfilePostsCount,
}) => {
	const {
		_id,
		name,
		username,
		// isFollowing,
		// followerCount,
		follows: { isFollowing, followerCount },
		location: { country, state },
		censorEvents,
		activeAccts,
	} = userProfile;

	const [modalData, setModalData] = useState({ src: '', _id: '', text: '' });

	useEffect(() => {
		return () => {
			// console.log('LINE: 45 clearPosts ');
			// console.log('LINE2: 48  userProfile', userProfile);
			clearPosts();
			clearUserProfile();
			clearProfilePostsCount();
		};
	}, []);

	return (
		<div className='profile-top '>
			{!isOwner && isOwner !== null && (
				// split into own component to prevent rerenders (will this work?)
				<div
					className={`btn btn-${isFollowing ? 'white' : 'primary'}`}
					id='follow'
					onClick={() => {
						followToggle({ user_id: _id, isFollowing, followerCount });
					}}>
					<span>{isFollowing ? 'Following' : 'Follow'}</span>
				</div>
			)}
			{!!modalData._id && (
				<ImgModal {...{ modalData, setModalData, additionalClasses: 'br-50' }} />
			)}
			<div className='img-cont ' id='top-profile-pic'>
				<img
					className='br-50 my-1'
					src={parsePicsUser(userProfile)}
					alt='profile-pic'
					onClick={e => {
						setModalData({ src: e.target.src, text: name, _id });
					}}
				/>
				<div
					className=''
					onMouseEnter={e => {
						_handleReminder({ e, msg: 'permalink', _class: 'mx-2' });
						setTimeout(function () {
							_rmReminder();
						}, 1000);
					}}>
					<span
						className='text-link '
						onClick={e => {
							_rmReminder();
							_handleReminder({ e, msg: 'copied', _class: 'mx-2' });
							setTimeout(function () {
								_rmReminder();
							}, 1000);
							copy(`${window.location.host}/profile-id/${userProfile._id}`);
						}}>
						<i className='fas fa-link' />
					</span>
				</div>
			</div>
			<h3 className='form-text'>@{username}</h3>
			<h2 className='large'>{name}</h2>
			<span className='lead'>
				{state && <span>{state}, </span>}
				<b>{country && <span>{country}</span>}</b>
			</span>

			<span>{followerCount} Followers</span>
			<div id='pro-top-count-cont' className='buttons'>
				<span
					className='primary count'
					onClick={() => scrollToElementById('censorship')}>
					{censorEvents.length} Censor Event
					{censorEvents.length !== 1 && 's'}
				</span>

				<span
					className='primary count'
					onClick={() => scrollToElementById('active-accounts')}>
					{activeAccts.length} Account
					{activeAccts.length !== 1 && 's'}
				</span>
			</div>

			<span className='count' onClick={() => scrollToElementById('posts-cont')}>
				{postsCount} Posts
			</span>

			<div
				id='primary-cont'
				// className='buttons'
			>
				<div className=''>
					{activeAccts.map(
						act =>
							act.primary && (
								<a
									key={act._id}
									href={act.url}
									target='_blank'
									rel='noopener noreferrer'>
									{act.platform && makeAwesome(act.platform)}
									<p>{act.platform}</p>
								</a>
							)
					)}
				</div>
				{activeAccts.reduce((acc, act) => acc + (act.primary === true ? 1 : 0), 0) >
					0 && <small className='form-text'>Primary Accounts</small>}
			</div>
		</div>
	);
};

const mapStateToProps = ({ userProfile, posts }) => ({
	userProfile,
	posts,
});

ProfileTop.propTypes = {
	userProfile: PropTypes.object.isRequired,
	followToggle: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, {
	followToggle,
	clearPosts,
	clearUserProfile,
	clearProfilePostsCount,
})(ProfileTop);
