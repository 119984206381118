import React, { useEffect, useState, Fragment } from 'react';
import { withRouter, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { parseQuerySelection } from '../common/_utilities';
import DropdownOptions from '../common/DropdownOptions';
import {
	SUBMISSION_DATE,
	POST_TYPE,
	DATE_SORT,
} from '../common/_shared_constants';
import qs from 'qs';

const FilterDropdown = ({
	query,
	clearPostsAction,
	setQueryAction,
	active,
	match,
	history,
	location,
}) => {
	const [selectionVals, setSelectionVals] = useState({});

	useEffect(() => {
		// debugger;
		setSelectionVals({
			[SUBMISSION_DATE.key]: parseQuerySelection({
				CONSTANT_OBJ: SUBMISSION_DATE,
				query,
			}),
			[POST_TYPE.key]: parseQuerySelection({ CONSTANT_OBJ: POST_TYPE, query }),
			[DATE_SORT.key]: parseQuerySelection({ CONSTANT_OBJ: DATE_SORT, query }),
		});
	}, [query]);

	return (
		<DropdownOptions
			{...{
				columns: [
					(shouldRender =>
						shouldRender && {
							label: 'SUBMISSION DATE',
							onClick: ({ e, value }) => {
								e.stopPropagation();

								const { hashtags, submissionDate, ...persist } = query;
								const newQuery = {
									...persist,
									...{
										[SUBMISSION_DATE.key]: value,
									},
								};
								const queryString = new URLSearchParams(newQuery);

								clearPostsAction && clearPostsAction();
								setQueryAction && setQueryAction(newQuery);

								history.push(
									`${location.pathname}${!match.params.query ? '/q' : ''}?${queryString}`
								);
							},
							options: SUBMISSION_DATE.options,
							selectionVal: selectionVals[SUBMISSION_DATE.key],
						})(active[SUBMISSION_DATE.key]),
					(shouldRender =>
						shouldRender && {
							label: 'POST TYPE',
							onClick: ({ e, value }) => {
								e.stopPropagation();

								const { hashtags, postType, ...persist } = query;

								const newQuery = {
									...persist,
									...(value !== 'BasePost' && { postType: value }),
								};

								const queryString = new URLSearchParams(newQuery);

								clearPostsAction && clearPostsAction();
								setQueryAction && setQueryAction(newQuery);

								history.push(
									`${location.pathname}${!match.params.query ? '/q' : ''}?${queryString}`
								);
							},
							options: POST_TYPE.options,
							selectionVal: selectionVals[POST_TYPE.key],
						})(active[POST_TYPE.key]),
					(shouldRender =>
						shouldRender && {
							label: DATE_SORT.label,
							onClick: ({ e, value }) => {
								e.stopPropagation();

								const { hashtags, sortBy, ...persist } = query;

								console.log('common-post/FilterDropdown.js: 94  persist', persist);

								// 								const newQuery = {
								// 									...persist,
								// 									// ...{ [DATE_SORT.key]: value },
								// 									...{
								// 										sortBy: {
								// 											[DATE_SORT.key]: value,
								// 											// foo: 'bar',
								// 										},
								// 									},
								// 								};

								const newQuery = {
									...persist,
									// ...{ [DATE_SORT.key]: value },
									[DATE_SORT.key]: value,
								};

								// const queryString = new URLSearchParams(newQuery);

								console.log('common-post/FilterDropdown.js: 104  newQuery', newQuery);

								const queryString = qs.stringify(newQuery);

								console.log(
									'common-post/FilterDropdown.js: 116  selectionVals',
									selectionVals
								);
								console.log('common-post/FilterDropdown.js: 120  value ', value);

								console.log(
									'common-post/FilterDropdown.js: 105  queryString ',
									queryString
								);

								clearPostsAction && clearPostsAction();
								setQueryAction && setQueryAction(newQuery);

								history.push(
									`${location.pathname}${!match.params.query ? '/q' : ''}?${queryString}`
								);
							},
							options: DATE_SORT.options,
							selectionVal: selectionVals[DATE_SORT.key],
						})(active[DATE_SORT.key]),
				],
			}}
		/>
	);
};

FilterDropdown.propTypes = {
	posts: PropTypes.object.isRequired,
	getHashtagPosts: PropTypes.func.isRequired,
	setLoadMorePosts: PropTypes.func.isRequired,
	match: PropTypes.object.isRequired,
	history: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth, posts }) => ({
	auth,
	posts,
});

export default withRouter(FilterDropdown);
