// const SCHEMA_OPTIONS = {
// 	discriminatorKey: 'postType',
// };
var LOAD_LIMIT = 13; // const LOAD_LIMIT = 5;
// automate this. Check if redacted username exists, if not create it and export the id

var REDACTED_ID = '5f7d3d241906d79acdc59e1c';
var IMG_UPLOAD_SIZE_LIMIT = 2040750;
var DATE_SORT = {
  key: 'date',
  options: [{
    method: 'Date added (oldest)',
    value: 1 //ascending

  }, {
    method: 'Date added (newest)',
    value: -1 //descending

  }],
  default: {
    method: 'Date added (newest)',
    value: -1 //descending

  }
};
var SUBMISSION_DATE = {
  key: 'submissionDate',
  options: [{
    method: 'All time',
    value: 0
  }, {
    method: 'Last hour',
    value: '1h'
  }, {
    method: 'Today',
    value: '1d'
  }, {
    method: 'This week',
    value: '7d'
  }, {
    method: 'This month',
    value: '30d'
  }, {
    method: 'This year',
    value: '1y'
  },,],
  default: {
    method: 'All time',
    value: 0
  }
}; // it would be awesome to add QuotedPosts to this

var POST_TYPE = {
  key: 'postType',
  options: [{
    method: 'All',
    value: 'BasePost'
  }, {
    method: 'Censor events',
    value: 'CensorEvent'
  }, {
    method: 'Posts',
    value: 'Post'
  }, {
    method: 'Comments',
    value: 'Comment'
  }],
  default: {
    method: 'All',
    value: 'BasePost'
  }
};
module.exports = {
  LOAD_LIMIT: LOAD_LIMIT,
  REDACTED_ID: REDACTED_ID,
  // SCHEMA_OPTIONS,
  IMG_UPLOAD_SIZE_LIMIT: IMG_UPLOAD_SIZE_LIMIT,
  SUBMISSION_DATE: SUBMISSION_DATE,
  DATE_SORT: DATE_SORT,
  POST_TYPE: POST_TYPE
};