import React from 'react';
import PropTypes from 'prop-types';
import uuid from 'uuid';

const CheckboxGroup = ({ name, onChange, options, className }) => {
	//debugger;
	const inputOptions =
		Array.isArray(options) &&
		options.map(option => {
			const otherStyle = option.value.toLowerCase() === 'other' && option.checked;

			const unique = uuid.v4();

			return (
				<li key={unique}>
					<input
						type='checkbox'
						id={unique}
						name={name}
						value={option.value}
						onChange={onChange}
						checked={option.checked}
					/>
					{otherStyle ? (
						<label
							style={
								otherStyle && {
									borderStyle: 'dotted',
								}
							}
							htmlFor={unique}>
							{option.value}
						</label>
					) : (
						<label htmlFor={unique}>{option.value}</label>
					)}
				</li>
			);
		});

	return (
		<ul className={`ks-cboxtags ${className ? className : ''}`}>
			{inputOptions}
		</ul>
	);
};

CheckboxGroup.propTypes = {
	onChange: PropTypes.func.isRequired,
	options: PropTypes.array.isRequired,
	name: PropTypes.string.isRequired,
};

export default CheckboxGroup;
