import {
	UPDATE_NOTIFICATIONS,
	GET_NOTIFICATIONS,
	NOTIFICATION_ERROR,
	POLL_NOTIFICATIONS,
	SET_LOAD_MORE_NOTIFICATIONS,
} from '../actions/types';
import { KEEP_LIMIT, PULL_LIMIT } from '../components/common/_constants';
import { LOAD_LIMIT } from '../components/common/_shared_constants';

import { _set_load_more } from './_posts_common';

const initialState = {
	notifications: [],
	activeNotifications: 0,
	loading: true,
	skipIndex: 0,
	firstIndexDate: 0,
	noMore: false,
	loadMore: false,
	error: {},
};

export default function (state = initialState, action) {
	const { type, payload } = action;

	switch (type) {
		case UPDATE_NOTIFICATIONS:
			return {
				...state,
				activeNotifications: 0,
				loading: false,
			};
		case POLL_NOTIFICATIONS:
			// console.log('OUTPUT: payload', payload);
			return {
				...state,
				notifications: [...payload, ...state.notifications],
				activeNotifications:
					payload.filter(n => n.active).length + state.activeNotifications,
				loading: false,
			};

		case GET_NOTIFICATIONS:
			const total = state.notifications.length + payload.length;
			const toCut = total > KEEP_LIMIT ? PULL_LIMIT : 0;
			const updated = [
				...state.notifications.slice(toCut),
				...payload.notifications,
			];
			return {
				...state,
				notifications: updated,
				activeNotifications: payload.activeNotifications,
				skipIndex: state.skipIndex + LOAD_LIMIT,
				firstIndexDate: updated.length && updated[0].date,
				noMore: payload.notifications.length < LOAD_LIMIT,
				loadMore: false,
				loading: false,
			};

		case SET_LOAD_MORE_NOTIFICATIONS:
			return { ...state, loadMore: true };

		case NOTIFICATION_ERROR:
			return {
				...state,
				error: payload,
				loading: false,
			};

		default:
			return state;
	}
}
