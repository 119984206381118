import React, { Fragment as F, useState } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

const PostContent = ({
	post: { text = '', name, user, img },
	postImg,
	history,
}) => {
	const { _id, avatar, username } = user;

	return (
		<div className='post-content'>
			<div className='text-cont'>
				<b
					className='name text-link'
					onClick={e =>
						!e.stopPropagation() && history.push(`/profile/${username}`)
					}>
					{name}
				</b>
				<span>{text}</span>
			</div>

			{img && postImg}
		</div>
	);
};

PostContent.propTypes = {
	// comment: PropTypes.object.isRequired,
};

export default withRouter(PostContent);
