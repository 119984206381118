import React, { useEffect, useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { scrollToElementById, hashtagify } from '../common/_utilities';
import PostItem from './PostItem';
import { PostImg, ImgModal } from './PostImg';
import { LOAD_LIMIT } from '../common/_shared_constants';
import useDetectBottom from '../common/hooks/useDetectBottom';

import { appendComments, setLoadMoreComments } from '../../actions/post';

const Comments = ({
	post: { post, loading } = null,
	comments,
	posteriorThread,
	appendComments,
	setLoadMoreComments: setLoadMore,
	history,
	match,
}) => {
	const { loadMore, noMore } = comments;
	const { anchor } = match.params;

	scrollToElementById(anchor && anchor !== 'form-textarea');

	useDetectBottom({
		thingLoading: comments,
		getAction: (prevQuery => query => appendComments({ ...prevQuery, ...query }))(
			{
				parent: post._id,
			}
		),
		setLoadMore,
	});

	// 	const [loadMore, setLoadMore] = useState(null);
	// 	useEffect(() => {
	// 		if (loadMore) {
	// 			console.log('LINE: 49', 'loadMore');
	// 			setLastIndex(skipIndex + LOAD_LIMIT);
	// 			appendComments(post._id, {
	// 				firstIndexDate: comments[0].date, // _posts_comments should do this
	// 				skipIndex,
	// 			});
	// 		}
	// 	}, [loadMore]);

	// 	useEffect(() => {
	// 		// if (comments && comments.length && !noMore) {
	// 		if (comments.posts.length && !noMore) {
	// 			const detectBottom = () => {
	// 				if (
	// 					window.innerHeight + window.scrollY + 100 >=
	// 					document.body.offsetHeight
	// 				) {
	// 					setLoadMore(true);
	// 					window.removeEventListener('scroll', detectBottom);
	// 				}
	// 			};
	// 			window.addEventListener('scroll', detectBottom);
	// 			return () => {
	// 				window.removeEventListener('scroll', detectBottom);

	// 				setLoadMore(false);
	// 			};
	// 		}
	// 	}, [comments]);

	const [modalData, setModalData] = useState({ _id: '', src: {}, text: '' });

	return loading || post === null ? (
		<Spinner />
	) : (
		<Fragment>
			<div id='posterior-thread-cont' className=''>
				{!!posteriorThread.posts.length &&
					posteriorThread.posts.map((pt, i) => (
						<PostItem
							threadItem={true}
							key={pt._id}
							post={{
								...pt,
								text: hashtagify(pt.text, history),
							}}
							postImg={<PostImg {...{ post: pt, setModalData }} />}
						/>
					))}
			</div>
			<div id='comments-cont'>
				{modalData._id && <ImgModal {...{ modalData, setModalData }} />}
				{comments.posts.length ? (
					comments.posts.map((comment, i) => {
						return (
							<PostItem
								i={i}
								key={comment._id}
								isInComments={true}
								post={{
									...comment,
									text: hashtagify(comment.text, history),
									setModalData,
								}}
								postImg={<PostImg {...{ post: comment, setModalData }} />}
							/>
						);
					})
				) : (
					<small className='form-text empty'>No comments yet.</small>
				)}
				{loadMore && (
					<div className='posts-spinner'>
						<Spinner />
					</div>
				)}
				{/* {noMore && <small className='form-text end'> End </small>} */}
			</div>
		</Fragment>
	);
};

Comments.propTypes = {
	post: PropTypes.object.isRequired,
	appendComments: PropTypes.func.isRequired,
};

const mapStateToProps = ({ post, comments, posteriorThread }) => ({
	post,
	comments,
	posteriorThread,
});

export default connect(mapStateToProps, {
	appendComments,
	setLoadMoreComments,
})(withRouter(Comments));
