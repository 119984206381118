import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { makeAwesome } from '../common/_utilities';
import CheckboxGroup from '../common/CheckboxGroup';
import { subActiveEmail, subActiveNotification } from '../../actions/profile';
import { setAlert } from '../../actions/alert';
import { PRIMARY_COLOR } from '../common/_constants';
import { _handleReminder, _rmReminder } from '../common/_reminder';

const ProfileEl = ({
	activeAcct: { _id, name, platform, handle, url, info },
}) => {
	const [withProtocol, setWithProtocol] = useState(null);

	const { anchor } = useParams();

	useEffect(() => {
		// TODO:
		// A+ for doing this, but maybe look into using;
		// https://www.npmjs.com/package/normalize-url
		const httpRegex = RegExp('^(http|https)://');
		const hasProtocol = httpRegex.test(url);
		setWithProtocol(hasProtocol ? url : `http://${url}`);
	}, []);

	return (
		<div
			className='profile-el'
			style={
				anchor === _id
					? {
							border: `2px solid ${PRIMARY_COLOR}`,
					  }
					: {}
			}
			onMouseEnter={e =>
				info &&
				_handleReminder({
					e,
					_class: '_reminder_top_left',
					msg: `${info ? info : ''}`,
				})
			}
			onMouseLeave={_rmReminder}>
			<a
				key={_id}
				id={_id}
				// href={`http\://${url}`}
				href={withProtocol}
				target='_blank'
				rel='noopener noreferrer'>
				<h3>{platform}</h3>
				<p>
					<span>{platform && makeAwesome(platform)} </span>
				</p>
				{handle ? <p>@{handle}</p> : <p>{name}</p>}
			</a>
		</div>
	);
};

const ProfileActive = ({
	auth: { isAuthenticated },
	userProfile: {
		userProfile: { _id, activeAccts, firstName },
	},
	subscriptions: { subscriptions },
	isOwner,
	setAlert,
	subActiveNotification,
	subActiveEmail,
}) => {
	const history = useHistory();
	return (
		<div id='active-accounts' className='profile-act profile-cont p-2 el-head'>
			<h2 className=' '>Active Accounts</h2>
			<div
				className={`subscribe-cont `}
				onClick={e => {
					!isAuthenticated && history.push('/login');
					isOwner &&
						setAlert({
							msg: 'You cannot sub to yourself',
							alertType: 'primary',
						});
				}}>
				<small className='form-text'>Subscribe to updates</small>

				<CheckboxGroup
					name='active-acct'
					className={`${!isAuthenticated || isOwner ? 'not-authenticated' : ''}`}
					onChange={e => {
						const { value, name } = e.target;
						value === 'Email'
							? subActiveEmail({
									_id,
									setTo: !subscriptions.activeAcct.email,
									firstName,
							  })
							: subActiveNotification({
									_id,
									setTo: !subscriptions.activeAcct.notification,
									firstName,
							  });
					}}
					options={[
						{
							value: 'Email',
							checked: subscriptions && subscriptions.activeAcct.email,
						},
						{
							value: 'Notification',
							checked: subscriptions && subscriptions.activeAcct.notification,
						},
					]}
				/>
				{/* <label htmlFor=''>Political Leanings</label> */}
			</div>{' '}
			{activeAccts.length > 0 ? (
				activeAccts.map(act => <ProfileEl key={act._id} activeAcct={act} />)
			) : (
				<h4>No active accounts</h4>
			)}
		</div>
	);
};

ProfileActive.propTypes = {
	auth: PropTypes.func.isRequired,
	userProfile: PropTypes.object.isRequired,
};

const mapStateToProps = ({ auth, userProfile, subscriptions }) => ({
	auth,
	userProfile,
	subscriptions,
});

export default connect(mapStateToProps, {
	setAlert,
	subActiveEmail,
	subActiveNotification,
})(ProfileActive);
