import React, { Fragment, useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { _handleReminder, _rmReminder } from '../common/_reminder';
import { labelTransNotEmpty } from '../profile-forms/_profileFormUtilities';
import { EMAIL_REGEX } from '../common/_constants';
import axios from 'axios';
import { setAlert } from '../../actions/alert';

const DashEditEmail = ({
	auth: {
		user: { email },
	},
	setAlert,
}) => {
	const history = useHistory();

	const [formData, setFormData] = useState({ email: '' });
	const [validForm, setValidForm] = useState(false);

	const onChange = ({ target: { name, value } }) => {
		setFormData({ ...formData, [name]: value });
	};

	return (
		<Fragment>
			<span className='p-2'>
				<i className='fas fa-wrench'></i>
				&nbsp; Edit email
			</span>

			<form
				key='edit-email-form'
				className='form'
				onSubmit={async e => {
					e.preventDefault();
					const {
						status,
						data: { msg },
					} = await axios.put('/api/auth/edit-email-request', formData);
					status === 200
						? (() => {
								setAlert({ msg, alertType: 'success', timeout: 10000 });
								history.push('/dashboard/edit-email/token');
						  })()
						: setAlert({ msg, alertType: 'danger' });
				}}
				onKeyUp={e =>
					setValidForm(
						![...e.currentTarget.querySelectorAll('input')].filter(f =>
							f.type === 'email'
								? !EMAIL_REGEX.test(f.value)
								: !f.checkValidity()
						).length
					)
				}>
				<div className='form-group'>
					<div className='use-trans-label'>
						<input
							id='edit-email'
							type='email'
							name='email'
							value={formData.email}
							onChange={onChange}
							onBlur={labelTransNotEmpty}
							spellcheck='false'
							required
						/>
						<label htmlFor='edit-email'>
							<span>{email}</span>
						</label>
					</div>
					<input
						type='submit'
						id='submit-edit-email-1'
						style={{ display: 'none' }}
					/>
					<button
						htmlFor='submit-edit-email-1'
						className={`btn my-2 btn-${validForm ? 'primary' : 'white'}`}
						style={{ marginLeft: '75%' }}>
						<span>Change email</span>
					</button>
				</div>
			</form>
		</Fragment>
	);
};

DashEditEmail.prototypes = {
	auth: PropTypes.object.isRequired,
	setAlert: PropTypes.func.isRequired,
};

const mapStateToProps = ({ auth }) => ({
	auth,
});

export default connect(mapStateToProps, { setAlert })(DashEditEmail);
