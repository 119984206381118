import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { logout } from '../../actions/auth';
import { _handleReminder, _rmReminder } from '../common/_reminder';
import { hasNestedExistence } from '../common/_utilities';

const DashActions = ({ auth: { user, profile }, setHeader }) => {
	setHeader(<h1 className='text-primary'>Actions</h1>);

	return (
		<Fragment>
			<div id='home-btns' className='buttons'>
				{/* I could nest another switch here */}
				{profile !== null && (
					<Fragment>
						<Link to={`/profile/${user.username}`} className='btn  btn-white '>
							<span>
								<i className='fas fa-user' />
								&nbsp; Profile
							</span>
						</Link>

						<Link to='/edit-profile' className='btn  btn-white '>
							<span>
								<i className='fas fa-user-edit ' />
								&nbsp; Edit Profile
							</span>
						</Link>

						{/* {!user.demography.id && ( */}
						{!hasNestedExistence(user, ['demography', 'id']) && (
							<Link to='/demography' className='btn  btn-white '>
								<span>
									<i className='fas fa-chart-pie ' />
									&nbsp; Demography
								</span>
							</Link>
						)}

						<Link to='/add-censorship' className='btn  btn-primary '>
							<span>
								<i className='fas fa-user-alt-slash' />
								&nbsp; Submit Censorship Event
							</span>
						</Link>

						<Link to='/add-active-acct' className='btn  btn-primary '>
							<span>
								<i className='fas fa-user-alt' />
								&nbsp; Add Active Account
							</span>
						</Link>
					</Fragment>
				)}
			</div>
		</Fragment>
	);
};

DashActions.propTypes = {
	setHeader: PropTypes.func,
};

const mapStateToProps = ({ auth }) => ({
	auth,
});

export default connect(mapStateToProps, { logout })(DashActions);
