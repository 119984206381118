import React, { useState, useMemo, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import NavLinks from './NavLinks';
import { useScrollPosition } from '@n8tb1t/use-scroll-position';
import SearchBox from '../common/SearchBox';
import { logout } from '../../actions/auth';
import { CNSR_VERSION } from '../common/_constants';

const GuestNav = ({ auth: { user, loading }, logout, history }) => {
	useEffect(() => {
		if (!loading && !user) {
		}
	}, [loading, user]);

	const [navScrollStyle, setNavScrollStyle] = useState('');
	useScrollPosition(
		({ prevPos, currPos }) => {
			setNavScrollStyle(
				currPos.y < prevPos.y ? 'guest-nav-scroll' : 'nav-shadow'
			);
		},
		[navScrollStyle],
		false,
		false,
		300
	);

	return useMemo(() => (
		<nav className={` ${navScrollStyle} `} id='guest-nav'>
			<div className='brand-name '>
				<Link to='/' className='site-name'>
					<i className='far fa-meh-blank' />
					&nbsp;
					<span className=''>CNSRSHP</span>&nbsp;
					<span style={{ fontSize: '.8rem' }} className='hide-md'>
						{CNSR_VERSION}
					</span>
				</Link>
			</div>
			{!loading && <NavLinks />}
		</nav>
	));
};

GuestNav.propTypes = {
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { logout })(withRouter(GuestNav));
