import React, { Fragment, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { dropdownHandler, parsePicsUser, detectCharCodes } from './_utilities';

const LiSuggestion = ({ suggestion, completeTag }) => {
	return (
		<li
			className='li-suggestion drop-option'
			onClick={e => {
				completeTag(
					document.getElementById('form-textarea'),
					suggestion.username
				);
			}}
			tabIndex='-1'
			onKeyDown={e => {
				e.preventDefault();
				const downKey = detectCharCodes({ e, charCodes: 40 });
				if (downKey) {
					const next = e.target.nextSibling;
					next && e.target.nextSibling.focus();
				} else if (detectCharCodes({ e, charCodes: 38 })) {
					// up arrow
					const previous = e.target.previousSibling;
					previous && e.target.previousSibling.focus();
				} else if (detectCharCodes({ e, charCodes: [9, 13] })) {
					// tab or enter
					e.target.click();
				} else {
					const postFormTextarea = document.getElementById('form-textarea');
					postFormTextarea.focus();
				}
			}}>
			{/* <div className='notif-img-cont hide-md'> */}
			<div className='notif-img-cont '>
				<img
					src={parsePicsUser(suggestion, 'thumb')}
					alt=''
					className='br-50 '
				/>
			</div>
			<div>
				<b>{suggestion.name} </b>
				<small>@{suggestion.username}</small>
			</div>
		</li>
	);
};

const TagSuggestions = ({ tagSuggestions, completeTag, positioningClass }) => {
	useEffect(() => {
		tagSuggestions.length &&
			(() => {
				const dropdown = document.getElementById('tag-suggestions');
				if (tagSuggestions.length) dropdown.classList.add('slide-down');
				else dropdown.classList.remove('slide-down');
			})();
	}, [tagSuggestions]);

	return (
		tagSuggestions && (
			<div id='suggestions-cont'>
				<div
					className={`drop-parent ${positioningClass}`}
					id='tag-suggestions'
					onClick={e => {
						e.stopPropagation();
						dropdownHandler(e);
					}}>
					<ul className='selection '>
						{tagSuggestions.map(suggestion => (
							<LiSuggestion {...{ suggestion, completeTag }} />
						))}
					</ul>
				</div>
			</div>
		)
	);
};

TagSuggestions.propTypes = {
	tagSuggestions: PropTypes.array.isRequired,
	positioningClass: PropTypes.string.isRequired,
};

export default TagSuggestions;
