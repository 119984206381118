import {
	GET_CENSOR_POSTS,
	CENSOR_POSTS_ERROR,
	ADD_CENSOR_POST,
	CLEAR_CENSOR_POSTS,
	GET_TOP_PLATFORMS_CENSOR,
	SET_SCROLL_Y_CENSOR,
	SET_LOAD_MORE_CENSOR,
	SET_CENSOR_QUERY,
	PREPEND_CENSOR_POSTS,
	CLEAR_PREPEND_CENSOR_ALERT,
} from '../actions/types';

import { INITIAL_POSTS_STATE } from '../components/common/_constants';

import {
	_posts_common_actions_switch,
	_append_posts,
	_set_scroll_y,
	_set_query,
	_clear_posts,
	_posts_error,
	_add_post,
	_get_top_platforms,
	_set_load_more,
	_clear_prepend_alert,
	_prepend_posts,
} from './_posts_common';

export const censorPosts = (state = INITIAL_POSTS_STATE, action) => {
	const { type, payload } = action;
	switch (type) {
		case CLEAR_PREPEND_CENSOR_ALERT:
			return _clear_prepend_alert({ state });

		case PREPEND_CENSOR_POSTS:
			return _prepend_posts({ state, payload });

		case GET_CENSOR_POSTS:
			return _append_posts({ state, payload });

		case CLEAR_CENSOR_POSTS:
			return _clear_posts(INITIAL_POSTS_STATE);

		case ADD_CENSOR_POST:
			return _add_post({ state, payload });

		case GET_TOP_PLATFORMS_CENSOR:
			return _get_top_platforms({ state, payload });

		case SET_LOAD_MORE_CENSOR:
			return _set_load_more({ state, payload });

		case SET_SCROLL_Y_CENSOR:
			return _set_scroll_y({ state, payload });

		case SET_CENSOR_QUERY:
			return _set_query({ state, payload });

		case CENSOR_POSTS_ERROR:
			return _posts_error({ state, payload });
	}

	return _posts_common_actions_switch({ type, state, payload });
};
