import React, { useEffect, useState, Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import util from 'util';
import { connect } from 'react-redux';
import Moment from 'react-moment';
import { followToggle } from '../../actions/profile';
import { hasExistence, parsePicsUser } from '../common/_utilities';
import axios from 'axios';

// Not sure what I'm going to do with this, but right now it's displaying profile info and ordered by everyone with a censorEvent by date profile was created

const UserItem = ({ auth, user, followToggle, history }) => {
	const {
		_id,
		name,
		bio = '',
		username,
		// location: { country, state },
		isFollowing: initialFollow,
	} = user;

	const [isOwner, setIsOwner] = useState(null),
		[isFollowing, setIsFollowing] = useState(initialFollow);

	useEffect(() => {
		if (user) {
			setIsOwner(auth.isAuthenticated && auth.user._id === _id);
		}
	}, [auth, user]);

	return (
		<div className='post-cont'>
			<div className='post profile'>
				<div className='thread-item-cont'>
					<div className='pic-thread'>
						<div className='profile-pic-cont img-cont'>
							<img
								src={parsePicsUser(user, 'thumb')}
								alt=''
								className='br-50'
							/>
						</div>
					</div>
					<div className='thread-post'>
						<div className='post-content'>
							<div className='name-cont'>
								<b
									className='text-link'
									onClick={e =>
										!e.stopPropagation() && history.push(`/profile/${username}`)
									}>
									{name}
								</b>
								<div className='lite-txt'>@{username}</div>
							</div>
							<div className='text-cont'>{bio}</div>
						</div>
					</div>
				</div>
				{!isOwner && (
					<div
						className={`btn btn-${isFollowing ? 'white' : 'primary'}`}
						// id='follow'
						onClick={async e => {
							if (auth.isAuthenticated) {
								setIsFollowing(await followToggle({ _id, isFollowing }));
							} else history.push('/login');
						}}>
						<span>{isFollowing ? 'Following' : 'Follow'}</span>
					</div>
				)}
			</div>
		</div>
	);
};

UserItem.propTypes = {
	user: PropTypes.object.isRequired,
	auth: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
	auth: state.auth,
});

export default connect(mapStateToProps, { followToggle })(withRouter(UserItem));
