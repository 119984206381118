import React, { Fragment as Frag, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import Spinner from '../layout/Spinner';
import { getPost } from '../../actions/post';
import Comments from './Comments';
import { hasExistence, useScrollToElementById } from '../common/_utilities';
import Thread from './Thread.js';
import MainInfo from '../main-post/MainInfo';
import MainContent from './MainContent.js';
import PostActions from '../post/PostActions';

const Post = ({
	auth: { loading: authLoading },
	getPost,
	post: { post, loading },
	match,
}) => {
	const { postType, id } = match.params;

	console.log('post/MainPost.js: 21  id ', id);

	useEffect(() => {
		!authLoading && getPost({ _id: id, postType });
	}, [id]);

	useScrollToElementById('main-post', null, [post]);

	// there was a bug here: postType undefined (may have fixed it)
	// return loading || post === null || !postType || post.postType !== postType ? (
	// console.log('OUTPUT: post', post);

	return loading ? (
		<Spinner />
	) : !hasExistence(post, '_id') ? (
		<small className='form-text empty'>{postType} does not exist</small>
	) : (
		<Frag>
			<div id='main-cont'>
				<Thread />
				<div id='main-post'>
					<MainContent />
					<MainInfo />
					<PostActions {...{ post, mainId: 'main-actions' }} />
				</div>
			</div>
			<Comments />
		</Frag>
	);
};

Post.propTypes = {
	auth: PropTypes.object.isRequired,
	getPost: PropTypes.func.isRequired,
	post: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired,
};

const mapStateToProps = ({ auth, post }) => ({
	auth,
	post,
});

export default connect(mapStateToProps, { getPost })(withRouter(Post));
